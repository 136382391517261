<template>
  <div>
    <!-- Put the SVG to show remix visualisation here!
    Based on https://bl.ocks.org/mbostock/1138500
    or this: https://bl.ocks.org/shimizu/e6209de87cdddde38dadbb746feaf3a3 -->
    <!-- <button @click="drawChart">draw chart</button> -->
    <div class="row">
      <div class="col-xs-12 col-lg-8" id="graphParent" style="min-height:500px;">
        <!-- <h3>View a Remix</h3> -->
        <svg class="img-fluid" style="background-color: #00000033;" id="graph" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
          <!-- <circle r=5 cx=100 cy=100 fill="#555"/> -->
        </svg>
      </div>
      <div class="col-lg-4 p-3">


        <div v-if="selectedNode != -1 ">

          <div v-if="visualData.nodes[selectedNode].type == 'remix'">
            <p class="text-muted">Click on the remix to remix it!</p>
            <nuxt-link :to="{name: 'r-rid-gid', params: {rid:visualData.nodes[selectedNode].id, gid:visualData.nodes[selectedNode].galleryID}}"><img style="max-width:100%;height:auto;" class="img-fluid" :src="visualData.nodes[selectedNode].thumbnailURL"></nuxt-link>

            <!-- Remix details -->
            <div class="mt-4 mb-2 d-flex align-items-center">

              <cc-licence licence="by-sa" :name="this.appSettings.title" :remix="remixes[visualData.nodes[selectedNode].remixID].creator.name"></cc-licence>

            </div>

            <!-- Delete -->
            <div><button @click="removeThisRemix" class="btn btn-primary btn-sm" v-if="$store.getters.isAdmin"><font-awesome-icon  size="lg" :icon="['fas', 'trash-alt']" /></button></div>


          </div>
          <div v-else>
            <!-- TODO: this only works for public remixes - not for Galleries -->
            <p class="text-muted">Click on the template to remix it!</p>
            <nuxt-link :to="{name:'a-app-id' , params: { app: visualData.nodes[selectedNode].templateID }}"><img style="max-width:100%;height:auto;" class="img-fluid" :src="visualData.nodes[selectedNode].thumbnailURL"></nuxt-link>
          </div>
        </div>
        <div v-else>

        <p class="text-muted">Select a node in the graph to view a remix.</p>
        </div>
      </div>
    </div>


 </div>

</template>

<script>

import * as d3 from 'd3'
//import { storageRef, modelCollectionRef, remixCollectionRef } from '~/plugins/firebase.js'
import { dbFunctions } from '~/components/mixins/dbFunctions.js'
export default {
  name: 'RemixSnapshotsVisual',
  components: {

  },
  inject: ['appThings','appRemix','appSettings'],
  mixins: [dbFunctions],
  props: {
    limit: {
      type: Number,
      default: 10
    },
    filterProperty: {
      type: String,
      default: ""
    },
    filterOperator: {
      type: String,
      default: ""
    },
    filterValue: {
      type: String,
      default: ""
    }

  },

  data () {
    return {
      remixes: [],

      visualData: {
        nodes: [
          //{id:"first",r:25},
          //{label:"second",r:10},
        ],
        links: [],
      },
      dataReady: false,
      width: 0,
      height: 0,
      chartWidth: 0,
      chartHeight: 0,
      margin: {},
      svg: "",
      chartLayer: "",
      simulation: "",
      link: "",
      node: "",
      selectedNode: -1,

      nodeLookup: {},

    }
  },

  methods: {
    removeThisRemix(){
      // make a note of the id
      var binnedRemix = this.visualData.nodes[this.selectedNode].id;

      // unselect the remix
      this.selectedNode = "";

      // remove the remix from Firebase
      this.removeRemix(binnedRemix,this.visualData.nodes[this.selectedNode].galleryID);
    },
    setSize() {
        this.width = document.querySelector("#graphParent").clientWidth
        console.log('Client Dimensions:' + document.querySelector("#graphParent").clientWidth, document.querySelector("#graphParent").clientHeight)
        this.height = document.querySelector("#graphParent").clientHeight

        this.margin = {top:0, left:0, bottom:0, right:0 }


        this.chartWidth = this.width - (this.margin.left+this.margin.right)
        this.chartHeight = this.height - (this.margin.top+this.margin.bottom)

        this.svg.attr("width", this.width).attr("height", this.height)

        this.chartLayer
            .attr("width", this.chartWidth)
            .attr("height", this.chartHeight)
            .attr("transform", "translate("+[this.margin.left, this.margin.top]+")")


    },

    ticked() {
      // console.log('ticked')
        this.link
            .attr("x1", function(d) { return d.source.x; })
            .attr("y1", function(d) { return d.source.y; })
            .attr("x2", function(d) { return d.target.x; })
            .attr("y2", function(d) { return d.target.y; });

        this.node
            //.attr("cx", function(d) { return d.x; })
            //.attr("cy", function(d) { return d.y; });
            .attr("transform", function(d) { return "translate(" + d.x + " " + d.y + ")"; })
            //.attr("cx", function(d) { return (d.x) ? d.x : 100 ; })
            //.attr("cy", function(d) { return (d.y) ? d.y : 100 ; });


    },

    drawChart() {
      console.log('drawchart')

      // remove previous nodes and lines
      this.chartLayer.selectAll(".nodes").remove();
      this.chartLayer.selectAll(".links").remove();

      this.chartLayer.append("rect")
          .attr("fill", "#00000000")
          .attr("width", "100%")
          .attr("height", "100%")


      // add the master app templates to the data
      var vm = this;
      this.chartLayer.call(d3.zoom().on("zoom", function () {
         vm.chartLayer.attr("transform", d3.event.transform)
       }))

        var simulation = d3.forceSimulation()
            //.force("link", d3.forceLink().id(function(d) { return d.index }))
            .force("link", d3.forceLink().id(function(d, i) { return i; }).distance(7).strength(1))
            .force("collide",d3.forceCollide( function(d){ return (d.type=="master")? 30 : (d.r + 2) }).iterations(16) )
            //.force("collide",d3.forceCollide( 20).iterations(16) )
            .force("charge", d3.forceManyBody().strength(-40))
            .force("center", d3.forceCenter(this.chartWidth / 2, this.chartHeight / 2))
            .force("y", d3.forceY(0))
            .force("x", d3.forceX(0))




        this.link = this.chartLayer.append("g")
            .attr("class", "links")
            .selectAll("line")
            .data(this.visualData.links)
            .enter()
            .append("line")
            .attr("stroke", "#666")



        this.node = this.chartLayer.append("g")
            .attr("class", "nodes")
            .selectAll("circle")
            //.data(vm.remixes)
            .data(this.visualData.nodes)
            .enter().append("g")
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));

        // filter the master nodes
        var masterNodes = this.node.filter(function(d,i){
              return (d.type == "master");
            })

          /*
        masterNodes.append('rect')
              .attr("width", 50)
              .attr("height", 20)


        masterNodes.append('text')
              .text(function(d) { return d.id})



        masterNodes.append('circle')
                      .attr("r", 50)
                      .attr("opacity", 0.5)

                      */

        masterNodes.append('image')
          .attr("xlink:href", function(d){  return d.thumbnailURL })
          .attr("width", 50)
          .attr("height", 50)
          .attr("x",-25)
          .attr("y",-25)
          .attr("class","master")
          .attr("id", function(d){  return "node" + d.nodeID })




        // filter the remix nodes
        var remixNodes = this.node.filter(function(d,i){
              return (d.type == "remix");
            })

        remixNodes.append('circle')
              .attr("r", function(d){  return d.r })
              .attr("stroke", "white")
              .attr("id", function(d){  return "node" + d.nodeID })
              .attr("class","remix")

        //console.log(this.node);

        simulation
            .nodes(this.visualData.nodes)
            //.nodes(vm.remixes)
            .on("tick", this.ticked);

        simulation.force("link")
            .links(this.visualData.links);

        var vm = this;

        function dragstarted(d) {
            if (!d3.event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
            // trigger the selected part!
            console.log(d)

            // deselect old Node
            vm.chartLayer.select("#node"+vm.selectedNode).classed("remix-highlight",false).attr("r",5);

            // select newly clicked Node
            vm.selectedNode = d.index
            vm.chartLayer.select("#node"+d.nodeID).classed("remix-highlight",true).attr("r",10);


            //console.log("#node"+d.nodeID,s);
            //console.log(vm.selectedNode)

        }

        function dragged(d) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        function dragended(d) {
            if (!d3.event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }



    },



    createNodesAndLinks (val){
      console.log('createnodesandlinks',val)

      //reset the visualData Arrays
      this.visualData.nodes = [];
      this.visualData.links = [];



      // add the template nodes first
      //console.log(this.$globalvars.templates);
      for (var templateID in this.$globalvars.templates){

        if (this.$globalvars.templates[templateID].status == "live"){

          // check if the remixes are being filtered by templateID. If so, only include the filtered templateID
          if ((this.filterProperty == "") || ((this.filterProperty == "templateID") && (this.filterValue == templateID))){
            // add to the lookup table
            var currentPosition = this.visualData.nodes.length;
            this.nodeLookup[templateID] = currentPosition;


            //console.log("pushing node",this.$globalvars.templates[j].title)
            this.visualData.nodes.push({
              id: templateID,
              nodeID: currentPosition,
              r: 30,
              type:"master",
              thumbnailURL: this.$globalvars.templates[templateID].image,
              galleryID: "",
              //routeName: this.$globalvars.templates[templateID].routeName
            })

          }

        }

      }

      // loop through remix documents from oldest to youngest
      for(var i = val.length-1; i>=0  ; i--) {
        // add the itemID to the node lookup object
        // ID -> array index

        // store the current position the new node will be pushed to in the array
        var currentPosition = this.visualData.nodes.length;
        this.nodeLookup[val[i].id] = currentPosition;
        // add the node to the nodes array
        this.visualData.nodes.push({
          id: val[i].id,
          nodeID: currentPosition,
          remixID: i,
          r: 5,
          type:"remix",
          thumbnailURL: val[i].thumbnail,
          galleryID: val[i].galleryID,
          //routeName: val[i].routeName
        })
        //console.log("pushing node", currentPosition, val[i].id,  this.visualData.nodes)

        // Does this node have a parent?
        if (val[i].parentRemixID){

          // Does the parentRemix still exist?
          if (this.nodeLookup.hasOwnProperty(val[i].parentRemixID)){
            // Parent exists, add a link to its parent
            // in the form {source:"1", target:"3"},
            this.visualData.links.push({
              source:currentPosition,
              target:this.nodeLookup[val[i].parentRemixID]
            })
            //console.log("pushing link source", currentPosition,"target",  this.nodeLookup[val[i].parentRemixID])
            // increase the size of the parent
            //this.visualData.nodes[this.nodeLookup[val[i].parentRemixID]].r = this.visualData.nodes[this.nodeLookup[val[i].parentRemixID]].r + 10;
            //console.log("successful looked up:",val[i].parentRemixID,"which =",this.nodeLookup[val[i].parentRemixID])
          }else{
            // parent no longer exists - floating node - connect it visually to the master
            this.visualData.links.push({
              source:currentPosition,
              target:this.nodeLookup[val[i].templateID]
            })
            //console.log("failed looked up:",val[i].parentRemixID,"which =",this.nodeLookup[val[i].parentRemixID])
          }
        }else{
          // First Generation Remix

          // add link to Master, by looking up the Template name
          this.visualData.links.push({
            source:currentPosition,
            target:this.nodeLookup[val[i].templateID]
          })
          //console.log("pushing link source", currentPosition,"target",  this.nodeLookup[val[i].parentRemixID])
        }

      }
    }

  },

  created () {

  },

  mounted () {
    if (this.filterProperty != ""){
      this.$bind('remixes', this.$fire.firestore.collection(this.$globalvars.remixCollection).where(this.filterProperty, this.filterOperator, this.filterValue).limit(this.limit).orderBy("created", "desc"))
        .then((doc) => {
          this.dataReady = true
        })
        .catch((error) => {
          console.log('error in loading: ', error)
        })
    } else {
      this.$bind('remixes', this.$fire.firestore.collection(this.$globalvars.remixCollection).limit(this.limit).orderBy("created", "desc"))
        .then((doc) => {
          this.dataReady = true
        })
        .catch((error) => {
          console.log('error in loading: ', error)
        })
    }

    this.svg = d3.select("#graph");
    this.chartLayer = this.svg.append("g").classed("chartLayer", true);

    this.setSize();
    this.drawChart();

    //console.log(this.$globalvars.templates)




  },


  // *** Need a computed property on remixes to show the node links between parent and child
  watch: {
    remixes: {
      handler: function (val, oldVal) {
        // Return the object that changed
        // var changed = val.filter( function( p, idx ) {
        //   return Object.keys(p).some( function( prop ) {
        //     return p[prop] !== oldVal[idx][prop];
        //   })
        // })
        //console.log(val)
        //console.log(oldVal)
        // Log it
        //console.log('changed:')
        this.createNodesAndLinks(val)
        this.drawChart()

      },
      deep: true
    }
  },



}



</script>

<style>


.master:hover{
  cursor:pointer;
}

.remix {
  fill: #00ff00;

}

.remix:hover {
  fill: #ff0000;
  cursor:pointer;
}

.remix-highlight {
  stroke-width: 3px;
  stroke: #ff0000;
  fill: #ff0000;
}

/*
@media (min-width: 576px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 768px) {
    .card-columns {
        column-count: 3;
    }
}

@media (min-width: 992px) {
    .card-columns {
        column-count: 4;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        column-count: 10;
    }
}
*/
</style>
