<template>

    <div class="row  align-items-center">
      <!-- Save image Modal Component -->
      <!-- <b-modal id="smileyfeedback" title="Gimme some 'Moji..." ref="smileyfeedback" body-bg-variant="dark"  header-bg-variant="dark" footer-bg-variant="dark" centered @ok="sendFeedback"> -->
        <div class="m-4 col-xs-12 col-xl-4" style="text-align:center">
          <svg width="100%" style="vertical-align:top;max-width:250px" id="Smiley" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-10 -80 520 680" @mousedown.prevent="startMove" @touchstart.prevent="startMove">
            <!-- arrows -->
            <g transform="translate(0 -72)" fill="#16a8e2">
              <polygon points="267.3,30.9 249.5,0 231.7,30.9 245.9,30.9 245.9,67.3 253.1,67.3 253.1,30.9 "/>
              <polygon points="267.3,614.6 253.1,614.6 253.1,578.2 245.9,578.2 245.9,614.6 231.7,614.6 249.5,645.5 "/>
              <polygon points="210.3,609.4 196.1,609.4 196.1,573 188.9,573 188.9,609.4 174.7,609.4 192.5,640.3 "/>
              <polygon points="322.3,609.4 308.1,609.4 308.1,573 300.9,573 300.9,609.4 286.7,609.4 304.5,640.3 "/>
              <polygon points="209.9,35.2 192,4.4 174.2,35.2 188.5,35.2 188.5,71.7 195.6,71.7 195.6,35.2 "/>
              <polygon points="321.9,35.2 304,4.4 286.2,35.2 300.5,35.2 300.5,71.7 307.6,71.7 307.6,35.2 "/>
            </g>
            <circle class="draggable" cx="250" cy="250" r="250" :fill="emotionColor.toHexString()" style="stroke:#ccc; stroke-width:20" />
            <path :d="'M75,200 ' + ' C90' + ',' + (200 + indicator/5) + ' 110' + ',' + (200 + indicator/5) + ' 125,200'"
              style="stroke-linecap:round; stroke-width:20; stroke:#000; fill:none;" />
            <path :d="'M375,200 ' + ' C390' + ',' + (200 + indicator/5) + ' 410' + ',' + (200 + indicator/5) + ' 425,200'"
              style="stroke-linecap:round; stroke-width:20; stroke:#000; fill:none;" />
            <path id="0" class="draggable" :d="mouthPath" style="stroke-linecap:round; stroke-width:20; stroke:#000; fill:none;"  />





          <!-- <g id="rendered_text" transform="translate(-10 -15)">
          	<g id="You_no_like" style="fill:#fff;">
          		<path d="M60.5,9.1c2,7.1,4.6,14.1,6.7,21.2c0.5,1.6,1,3.4,1.3,5.1l0,0.1c0.1,0.9,0.4,1.5,1,2.2c3.7,4.1,7.2,8.2,10.8,12.4
          			c0.6,0.7,1.3,1.5,1.9,2.3c0.6,0.8,0.5,2.1-0.2,2.9c-0.8,0.9-2.1,0.7-2.8,0.3c-0.8-0.5-1.3-1.1-1.8-1.6c-3.5-4.1-7-8.2-10.5-12.2
          			c-0.5-0.6-0.9-0.8-1.7-1.1l0-0.1c-1.8-0.7-3.4-1.7-4.9-2.6c-4.3-2.4-8.6-4.8-12.9-7.1c-2.3-1.2-4.7-2.2-7-3.2
          			c-0.4-0.2-0.6-0.2-1-0.3c-1.1-0.2-2.1-1.4-1.9-2.5c0.1-1,1.1-1.9,2.2-2c1-0.2,2,0.3,2.6,0.6c3.1,1.2,6.4,2.7,9.4,4.4
          			c4,2.3,8.1,4.5,12.2,6.7c-0.3-0.8-0.4-1.7-0.7-2.5c-2-5.9-3.4-11.8-5.7-17.5c-0.6-1.4-1-3.1-1.2-4.6l-0.1,0
          			c-0.2-1.4,0.6-2.9,1.8-3C59.1,7,60.1,8,60.5,9.1z"/>
          		<path d="M115.3,6.6c2,3,3.4,6.5,3.8,10.2c0.2,1.1,0.4,2.3,0.5,3.6c0.4,3-0.2,6.1-2.7,8.4c-1.2,1.2-2.4,2.4-4,3.4
          			c-1.4,0.8-3.1,1.4-4.7,1.4l0.1,0c-0.6,0.1-1,0-1.5,0.1l0.1,0c-2.7,0.7-5.2-0.1-7.4-1.4c-1.5-0.8-2.7-2.1-3.9-3.2
          			c-4.8-4.3-8.4-9.5-11.8-14.8c-1.1-1.8-2.3-3.6-3.2-5.5c-0.9-1.8-1.3-3.7-2-5.4c-0.6-1.7-0.8-3.5-0.9-5.2l0-0.1
          			c-0.1-3.1,1.6-5.5,3.3-7.4c2-2.2,4.5-3.9,6.9-5.3c0.2-0.1,0.5-0.3,0.9-0.4c4.5-1.2,9.1-0.6,12.4,3c1.6,1.8,3.3,3.6,4.9,5.5
          			c2.5,3.1,4.9,6.3,6.7,9.2L115.3,6.6z M108.2,3.7c-1.6-2.4-3.4-4.6-5.2-6.9c-0.3-0.4-1-0.7-1.7-1.4l-3.1-3.4
          			c-2.5-2.9-6.1-3.4-9.2-1.4c-0.5,0.3-0.8,0.5-1,0.7C86-7.4,84.2-6.2,83-4.3c-0.5,0.8-0.7,1.4-0.7,2.4c-0.1,2.5,1,4.8,1.8,7.4
          			c0.5,1.8,1.6,3.2,2.5,4.9c2.1,3.8,4.7,7.2,7.4,10.6c1.3,1.6,2.9,3,4.4,4.6c1.1,1.2,2.3,2,3.5,2.7c1.6,0.9,3,1.3,4.6,0.9
          			c0.2,0,0.5-0.2,0.9-0.1c2.1,0,3.6-1,5.2-2.4c0.4-0.4,0.9-1,1.4-1.5c1-0.9,1.3-1.8,1.2-3.1c-0.2-3.7-0.8-7.1-2.3-10.4
          			c-1.2-2.6-2.8-4.9-4.4-7.4L108.2,3.7z"/>
          		<path d="M117.6-29.2c0.3,0.6,0.6,1.1,0.8,1.6c0.8,1.7,1.6,3.5,2.3,5.3c0.9,2.2,2.2,4.2,3.5,6.3c1,1.7,1.9,3.6,2.6,5.3
          			c1.9,3.9,3.6,7.9,5.5,11.8c1.1,2.1,2.2,4.2,3.6,6.2c1.1,1.6,2.9,2.3,4.9,2.2l0.1,0c0.8,0,1.9-0.2,2.9-0.1c2.3,0.3,4-0.8,5.7-2.4
          			c0-0.1,0.2-0.3,0.4-0.5c1.8-1.8,1.7-4.1,0.8-6.5c-1.7-4.6-4-9.1-5.9-13.8c-1.4-3.6-2.7-7.2-4.1-10.8c-1-2.3-2.1-4.5-3.2-6.9
          			l0.9,1.8l-3.3-7.2c-0.4-1,0.2-2.2,1-2.6c0.9-0.4,2.2-0.3,2.9,0.6c0.2,0.3,0.4,0.7,0.5,0.9c1.6,3.6,3.6,7.2,5.1,11.1
          			c1.2,3.1,2.3,6.1,3.5,9c1.3,3.1,2.5,6.1,3.8,9.1c0.8,1.9,1.7,3.7,2.6,5.6c1.1,2.3,1.4,5,1.5,7.5c0,1.4-0.6,2.7-1.3,3.7
          			c-0.6,0.9-1.3,1.8-2.1,2.7l-0.1,0c-2.4,2.3-5.4,3.8-9.1,3.4l0-0.1c-0.6-0.1-1.2-0.1-1.9,0c-3.6,0.4-6.7-1-8.9-3.7
          			c-1.8-2.2-3-4.7-4.1-6.9c-1.5-3-2.8-6.1-4.1-9.1c-1.6-3.5-3.3-7-5.3-10.2c-1.3-2.1-2.2-4.4-3.2-6.5c-0.7-1.5-1.2-3-2-4.3
          			c-0.6-1.1-0.3-2.5,0.7-3.1C115.4-30.5,116.8-30.1,117.6-29.2z"/>
          		<path d="M231.8-29.5L231.8-29.5c0.3,2.5,0.4,5.1,0.5,7.6c0.1,2.5,0.7,4.8,1.2,7.3c0.2,0.6,0.3,1.2,0.4,1.9
          			c0.2,1.1-0.6,2.2-1.6,2.6c-1,0.5-2.4,0.3-3.1-0.7c-0.6-0.7-1-1.5-1.2-2.2c-0.5-1.3-1.1-2.3-2.1-3.1c-1.5-1.4-2.1-3.3-2.7-4.8
          			c-0.4-0.6-0.5-1-0.7-1.4c-2.2-2.6-3.7-5.8-5.5-8.5c-3.6-5.3-6.5-11.2-9.4-16.8l2.9,20.7c0.7,4.8,1.2,9.7,1.9,14.4
          			c0.1,0.5,0.2,1.3,0,2.1c-0.1,1-1,2-2.1,2c-1.1,0-2.2-1.1-2.3-2.2c-0.2-3.1-0.8-6.3-1.2-9.5c-1-8.4-2.3-16.7-3.4-24.9
          			c-0.4-3.1-0.9-6.1-1.4-9.2c-0.1-0.7-0.5-2.3,1.3-3.1c1.2-0.6,2.7-0.1,3.5,0.7c1.4,1.4,2.7,3.1,3.6,4.8c2,3.6,3.9,7.4,5.9,11
          			c2.6,4.5,5.3,8.9,8.2,13.3c1.1,1.7,2.3,3.5,3.3,5.5c-0.1-1.9-0.2-3.8-0.3-5.7c-0.1-2.9-0.5-5.8-1.1-8.7c-0.7-3.3-1.1-6.5-1.5-9.8
          			c-0.3-3.8-0.9-7.6-1.5-11.5c-0.1-0.4-0.1-0.9-0.1-1.4c0.1-0.9,0.9-1.8,1.9-2c0.9-0.1,2.1,0.5,2.4,1.5c0.1,0.5,0.2,0.9,0.3,1.3
          			c0.7,5.6,1.4,11.1,2.2,16.7C230.5-37.7,231.5-33.6,231.8-29.5z"/>
          		<path d="M276.3-31.1c0,3.6-0.8,7.3-2.5,10.6c-0.5,1-0.9,2.1-1.5,3.2c-1.3,2.8-3.6,5-6.9,5.5c-1.6,0.3-3.3,0.7-5.2,0.6
          			c-1.6-0.1-3.3-0.5-4.7-1.4l0.1,0c-0.5-0.3-0.9-0.6-1.3-0.7l0.1,0c-2.6-0.9-4.3-3-5.4-5.3c-0.8-1.5-1.1-3.3-1.5-4.8
          			c-1.6-6.2-1.7-12.6-1.5-18.9c0.1-2.1,0.1-4.3,0.4-6.4c0.2-2,0.9-3.8,1.3-5.6c0.5-1.8,1.3-3.3,2.1-4.8l0-0.1
          			c1.7-2.6,4.3-3.7,6.9-4.3c2.9-0.7,5.9-0.7,8.7-0.5c0.3,0,0.6,0,1,0.2c4.4,1.5,7.9,4.6,8.7,9.4c0.4,2.4,0.7,4.8,1,7.3
          			c0.4,4,0.6,8,0.4,11.4L276.3-31.1z M272.1-37.5c0-2.9-0.2-5.7-0.5-8.6c0-0.5-0.5-1.2-0.6-2.1l-0.7-4.6c-0.5-3.8-3.2-6.2-6.9-6.3
          			c-0.5,0-1-0.1-1.2,0c-2.4,0.2-4.6,0.1-6.6,1.1c-0.8,0.4-1.4,0.8-1.9,1.6c-1.5,2.1-1.8,4.5-2.6,7.2c-0.6,1.8-0.5,3.5-0.6,5.5
          			c-0.3,4.3-0.1,8.6,0.3,12.9c0.2,2,0.8,4.1,1.1,6.3c0.2,1.6,0.8,2.9,1.4,4.2c0.9,1.6,1.7,2.7,3.3,3.3c0.1,0.1,0.5,0.1,0.8,0.4
          			c1.8,1.2,3.5,1.1,5.6,0.8c0.5-0.1,1.3-0.4,2-0.4c1.3-0.2,2.1-0.8,2.8-1.9c1.9-3.2,3.2-6.3,3.8-9.9c0.5-2.9,0.4-5.6,0.5-8.6
          			L272.1-37.5z"/>
          		<path d="M348.6,3.6c-4.6-1.1-9.2-2.1-13.7-4.1c-2-0.9-4-1.4-6.2-2.1c-0.6-0.2-1.4-0.3-2.1-1c-0.7-1.3-0.1-2.3,0.1-2.9
          			c0.8-2.6,1.5-5.3,2.6-7.9c0.7-1.8,1.2-3.5,1.4-5.4c0.4-3.6,1.6-6.9,2.6-10.2l5-16.8c0.4-1.6,0.8-3,0.9-4.6
          			c0.1-1.3,1.3-2.3,2.4-2.3c1.2,0.1,2,1.3,2,2.6c-0.2,1.9-0.6,3.9-1.2,5.8l-5.8,19.6c-0.6,2.1-1,4.2-1.3,6.3
          			c-0.4,2.8-1.2,5.6-2.3,8.1l0,0.1c-0.6,1.4-1.1,2.9-1.5,4.5c3.3,1,6.2,2.2,9.3,3.3c3.4,1.1,7.1,1.5,10.6,3.1
          			C352,2.4,351,4.1,348.6,3.6z"/>
          		<path d="M401.8-30.6c0.2,0.1,0.5,0.2,0.7,0.5c0.6,0.7,0.7,1.8,0.4,2.6c-0.5,0.8-1.6,1.4-2.5,1.1c-0.5-0.2-1-0.4-1.4-0.6l-0.1,0
          			c-0.9-0.5-1.8-0.8-2.7-1.1c-0.1,0.2-0.2,0.5-0.3,0.9c-1.1,3.3-2.7,6.4-4.2,9.4c-1.2,2.2-2.3,4.6-3.3,6.9
          			c-2.1,4.6-4.6,8.9-6.8,13.3c-1.3,2.6-2.7,5.1-3.7,7.7l-0.1,0.2l4.4,2c0.3,0.1,0.7,0.3,1.2,0.5c1,0.5,1.4,1.8,1.1,2.7
          			c-0.4,0.9-1.5,1.5-2.5,1.3c-0.8-0.1-1.7-0.3-2.4-0.7c-3.7-1.8-7.8-3.1-11.7-5.1c-0.2-0.1-0.6-0.3-1.1-0.6
          			c-0.9-0.6-1.1-1.8-0.9-2.5c0.3-1,1.5-1.6,2.3-1.5c0.7,0.1,1.2,0.3,1.6,0.5c1.4,0.6,2.6,1.3,3.8,1.7c0.2-0.3,0.3-0.6,0.5-1
          			c0.7-1.9,1.7-3.7,2.6-5.5c3.4-6.4,6.7-12.9,9.7-19.5c1.4-3.1,3.1-6,4.4-8.9c0.5-1.1,0.8-2.3,1.3-3.7l-4.6-2.1
          			c-0.4-0.2-0.9-0.4-1.3-0.8c-0.8-0.7-0.7-2-0.3-2.7c0.5-0.7,1.5-1.2,2.4-0.9c0.4,0.1,0.8,0.3,1,0.3c2.8,1.2,5.5,2.4,8.2,3.5
          			C398.7-32.2,400.4-31.8,401.8-30.6z"/>
          		<path d="M420,39.9c-1.2,0-2.2-1.2-2.3-2.4c-0.1-2.9-0.6-5.8-1.2-8.6c-0.4-2-0.5-4-0.7-6c-0.2-2.1-0.6-4.4-0.9-6.5
          			c-0.4-2.5-0.5-5-0.2-7.5l0,0.1c0-0.5,0.1-1.1,0-1.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0.1-0.2,0.3-0.4,0.5c-2.3,3.3-4,6.9-6.4,10.3
          			l-5.6,7.5c-0.7,0.9-2.2,1.2-3,0.6c-0.8-0.5-1.2-1.9-0.8-2.9l0-0.1c0.3-0.5,0.7-1,0.9-1.4c2.4-3,4.7-6,6.6-9.2
          			c2.9-4.9,6.1-9.3,8.9-14c2.9-4.9,5.8-9.8,8.9-14.5c0.2-0.3,0.5-0.7,0.8-1.1l0,0.1c0.7-0.8,1.9-1.2,2.9-0.6
          			c0.9,0.6,1.2,1.8,0.8,2.7c-0.2,0.6-0.5,0.9-0.7,1.2c-1.9,3.1-4,6.1-5.8,9.1c-1.5,2.5-2.9,5.1-4.5,7.6c0.8,0,1.7,0,2.7,0
          			c7.2-0.4,13.6-3,20-6.4c0.8-0.4,1.5-0.8,2.3-1.3c0.4-0.3,1.1-0.5,1.7-0.6l-0.1,0c1.1-0.2,2.4,0.5,2.6,1.6s-0.4,2.2-1.2,2.5
          			c-5.2,3-10.7,5.5-16.5,7.3c-2.9,0.9-6,1.2-9,1.5c-0.1,0.4-0.1,0.7-0.2,1.2c-0.1,2.3-0.1,4.6,0.4,6.8c0.5,3.3,0.9,6.5,1.3,9.7
          			c0.4,3.2,1,6.3,1.6,9.5c0.1,0.9,0.1,1.8,0,2.8C422.2,39,421.1,40,420,39.9z"/>
          		<path d="M465,15.8c-1.8,2.6-3.7,5.3-6.1,7.6c-2.2,2.2-4.4,4.5-6.8,6.8c1.1,1,2.1,1.9,3.3,2.8c1.8,1.3,3.7,2.6,5.5,4.2l0.6,0.5
          			c0.9,0.9,1,2.4,0.3,3.2c-0.7,0.8-2.4,1-3.3,0l0.1,0c-1.5-1.4-3.2-2.5-4.9-3.7c-1.7-1.2-3.1-2.5-4.6-3.8l-0.2,0.3
          			c-2.6,3.2-5.4,6.1-8.3,9c-1.5,1.5-3.1,2.8-4.5,4.4l-0.4,0.5c1.5,0.9,2.6,2,3.8,2.9l0.1,0c3.2,2.8,6.4,5.5,9.6,8.3
          			c0.3,0.2,0.7,0.5,1.1,1.1c0.4,0.7,0.5,1.9-0.2,2.6c-0.6,0.7-1.9,0.9-2.6,0.5c-0.4-0.2-0.9-0.6-1.1-0.8c-3.8-3.2-7.6-6.5-11.3-9.7
          			c-1-0.9-2.4-1.5-3.6-2.8s-1.5-2.6-0.2-4c1.9-2.1,3.8-4.1,5.8-5.9c1.7-1.5,3.2-3,4.6-4.6c4.5-5.3,9.5-10.2,14.3-15
          			c2.2-2.2,3.9-4.7,5.8-7.2c0.5-0.7,0.9-1.2,1.3-1.7c0.4-0.4,1.2-1.1,2.5-0.8c0.8,0.7,2,1.3,3.1,2.4c1.8,1.7,4,2.9,6,4.7
          			c2.2,2,4.5,3.9,7,5.4c0.4,0.3,1,0.8,1.2,1.6c0.2,0.7,0,1.7-0.6,2.2c-0.7,0.7-1.8,0.7-2.5,0.4c-0.6-0.3-1.2-0.7-1.8-1.2
          			c-1.5-1.2-3.2-2.3-4.8-3.7C470.6,20,467.6,18.2,465,15.8z"/>
          	</g> -->
          	<!-- <g id="You_like" style="fill:#fff;">
          		<path d="M138,517c-6,4.4-11.6,9.3-17.5,13.7c-1.3,1.1-2.8,2.1-4.3,3l-0.1,0c-0.8,0.4-1.2,0.9-1.7,1.7c-2.6,4.9-5.1,9.7-7.7,14.5
          			c-0.5,0.8-0.9,1.7-1.5,2.6c-0.5,0.9-1.8,1.2-2.8,0.8c-1.1-0.4-1.4-1.7-1.3-2.5c0.2-0.9,0.6-1.6,0.9-2.2c2.5-4.7,5.2-9.4,7.7-14.2
          			c0.4-0.7,0.5-1.1,0.4-1.9l0-0.1c0-1.9,0.4-3.7,0.7-5.5c0.7-4.9,1.5-9.7,2.1-14.6c0.3-2.5,0.4-5.1,0.6-7.7c0.1-0.4,0-0.6-0.1-1.1
          			c-0.2-1.1,0.6-2.4,1.7-2.7c0.9-0.3,2.2,0.4,2.6,1.3c0.5,0.9,0.4,1.9,0.4,2.6c0,3.4-0.2,6.9-0.8,10.3c-0.7,4.6-1.3,9.2-1.9,13.8
          			c0.7-0.6,1.5-1,2.1-1.6c4.8-3.9,9.8-7.4,14.4-11.5c1.1-1.1,2.5-2.1,3.9-2.7l-0.1,0c1.2-0.7,2.9-0.5,3.4,0.7
          			C139.4,514.9,138.9,516.2,138,517z"/>
          		<path d="M166.7,559.2c-1.2,3.4-3.1,6.6-5.8,9.2c-0.8,0.8-1.6,1.7-2.5,2.5c-2.2,2.2-5.1,3.5-8.3,3c-1.6-0.2-3.4-0.4-5.1-1.1
          			c-1.5-0.6-3-1.6-4-2.9l0.1,0c-0.4-0.4-0.6-0.8-1-1.1l0.1,0c-2.1-1.7-3.1-4.2-3.4-6.8c-0.3-1.7,0-3.5,0.2-5
          			c0.5-6.4,2.6-12.5,4.8-18.3c0.8-2,1.5-4,2.5-5.9c0.9-1.8,2.1-3.3,3.1-4.8c1.1-1.5,2.3-2.7,3.6-3.8l0-0.1c2.4-1.9,5.3-2,7.9-1.8
          			c2.9,0.3,5.8,1.3,8.3,2.3c0.3,0.1,0.6,0.2,0.9,0.5c3.7,2.9,5.9,6.9,5.1,11.7c-0.4,2.4-0.9,4.8-1.4,7.2c-0.9,3.9-2.1,7.7-3.3,10.9
          			L166.7,559.2z M164.8,551.8c0.9-2.7,1.7-5.5,2.4-8.3c0.2-0.4,0-1.2,0.1-2.2l0.9-4.5c0.8-3.7-0.9-6.9-4.4-8.2
          			c-0.5-0.2-0.9-0.4-1.1-0.4c-2.3-0.6-4.3-1.4-6.6-1.2c-0.9,0.1-1.6,0.3-2.4,0.9c-2.1,1.5-3.2,3.7-4.8,5.9c-1.1,1.5-1.6,3.2-2.4,5
          			c-1.7,3.9-2.9,8.1-4,12.2c-0.5,2-0.6,4.1-1,6.3c-0.3,1.6-0.2,3,0,4.5c0.3,1.8,0.7,3.2,2.1,4.2c0.1,0.1,0.5,0.3,0.6,0.7
          			c1.3,1.7,2.9,2.2,5,2.6c0.6,0.1,1.4,0.1,2,0.2c1.3,0.3,2.2-0.1,3.2-0.9c2.8-2.4,5.1-4.9,6.9-8.1c1.4-2.5,2.2-5.2,3.3-7.9
          			L164.8,551.8z"/>
          		<path d="M191.8,534c-0.1,0.7-0.2,1.2-0.3,1.7c-0.4,1.9-0.8,3.7-1.3,5.6c-0.6,2.3-0.8,4.7-1,7.2c-0.2,2-0.6,4-1,5.8
          			c-0.8,4.3-1.8,8.5-2.6,12.7c-0.4,2.3-0.7,4.7-0.8,7.1c-0.1,1.9,1,3.6,2.6,4.7l0.1,0c0.7,0.5,1.6,1,2.4,1.7
          			c1.7,1.6,3.7,1.8,5.9,1.5c0.1-0.1,0.3-0.1,0.7-0.1c2.5-0.4,3.8-2.2,4.6-4.7c1.4-4.7,2.2-9.7,3.4-14.6c1-3.8,2.1-7.4,3.1-11.1
          			c0.6-2.4,1-4.9,1.6-7.5l-0.4,2l1.6-7.7c0.3-1.1,1.5-1.6,2.3-1.5c0.9,0.2,1.9,1,2,2.2c0,0.3-0.1,0.7-0.1,1
          			c-0.8,3.9-1.4,7.9-2.5,11.9c-0.9,3.2-1.8,6.2-2.5,9.3c-0.8,3.2-1.6,6.4-2.4,9.5c-0.4,2-0.8,4-1.2,6.1c-0.5,2.5-1.9,4.8-3.3,6.9
          			c-0.9,1.1-2.1,1.8-3.2,2.2c-1,0.3-2.1,0.7-3.3,0.9l-0.1,0c-3.3,0.4-6.6-0.2-9.3-2.7l0-0.1c-0.5-0.4-0.9-0.7-1.5-1.1
          			c-3.1-1.8-4.8-4.8-5-8.3c-0.2-2.8,0.4-5.5,0.8-8c0.6-3.4,1.4-6.5,2.1-9.8c0.8-3.8,1.5-7.5,1.8-11.4c0.2-2.5,0.8-4.8,1.3-7.2
          			c0.3-1.6,0.8-3.1,1-4.6c0.2-1.3,1.2-2.2,2.4-2.1C190.9,531.7,191.8,532.9,191.8,534z"/>
          		<path d="M295.5,591.8c-4.7,0.5-9.4,1.3-14.2,1c-2.2-0.1-4.3,0.1-6.5,0.3c-0.6,0-1.4,0.2-2.3-0.2c-1.1-0.9-0.9-2.1-0.9-2.7
          			c-0.1-2.7-0.5-5.5-0.3-8.3c0.1-1.9-0.1-3.7-0.6-5.5c-0.9-3.5-1-7-1.2-10.5l-1.2-17.4c-0.2-1.7-0.3-3.1-0.7-4.7
          			c-0.4-1.3,0.4-2.6,1.5-3c1.1-0.3,2.4,0.5,2.8,1.8c0.5,1.9,0.8,3.8,1,5.9l1.5,20.4c0.2,2.2,0.5,4.3,1,6.4c0.6,2.8,0.8,5.7,0.7,8.4
          			l0,0.1c-0.1,1.6,0,3.1,0.2,4.8c3.4-0.2,6.6-0.1,9.8-0.2c3.5-0.1,7.2-1.1,11-0.9C298.3,589.5,298,591.4,295.5,591.8z"/>
          		<path d="M325.9,533.5c0.3-0.1,0.5-0.1,0.9-0.1c0.9,0.2,1.7,1,1.9,1.9c0.1,0.9-0.5,2-1.4,2.4c-0.5,0.2-1,0.3-1.4,0.4l-0.1,0
          			c-1,0.1-1.9,0.4-2.8,0.8c0,0.2,0.2,0.5,0.3,0.9c1.1,3.3,1.7,6.7,2.3,10.1c0.4,2.5,0.9,5,1.6,7.5c1.1,4.9,1.7,9.9,2.6,14.7
          			c0.6,2.9,0.9,5.7,1.7,8.3l0,0.2l4.7-1c0.3-0.1,0.7-0.2,1.3-0.3c1.1-0.2,2.2,0.6,2.5,1.5c0.2,0.9-0.3,2.2-1.2,2.6
          			c-0.7,0.4-1.5,0.7-2.4,0.9c-4.1,0.8-8.1,2.2-12.4,3c-0.2,0-0.6,0.1-1.2,0.2c-1.1,0.1-1.9-0.8-2.2-1.5c-0.4-1,0.2-2.1,1-2.6
          			c0.6-0.3,1.2-0.5,1.6-0.6c1.5-0.3,2.8-0.5,4-0.9c0-0.3-0.1-0.7-0.2-1.1c-0.6-2-0.9-4-1.2-5.9c-1.1-7.2-2.4-14.3-4-21.4
          			c-0.8-3.3-1.2-6.6-1.9-9.8c-0.3-1.2-0.7-2.4-1.2-3.8l-4.9,1.1c-0.4,0.1-0.9,0.2-1.6,0.1c-1.1-0.1-1.8-1.1-1.9-2
          			c0-0.8,0.4-1.8,1.3-2.2c0.4-0.2,0.8-0.2,1-0.3c3-0.7,5.8-1.4,8.7-2.1C322.6,534.1,324.2,533.4,325.9,533.5z"/>
          		<path d="M388.7,567.3c-0.7,0.9-2.3,1.1-3.3,0.4c-2.4-1.6-5-2.9-7.7-4.2c-1.8-0.8-3.5-2-5.2-3c-1.8-1.1-3.8-2.1-5.8-3.1
          			c-2.2-1.1-4.3-2.6-6.1-4.3l0,0.1c-0.4-0.4-0.8-0.7-1.3-1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.3,0.2,0.7c1.3,3.8,3.2,7.4,4.5,11.3
          			l2.7,9c0.3,1.1-0.3,2.4-1.3,2.8c-0.9,0.3-2.2-0.2-2.8-1.1l0-0.1c-0.3-0.6-0.4-1.1-0.6-1.5c-1-3.7-2-7.3-3.5-10.8
          			c-2.2-5.2-3.8-10.5-6-15.5c-2.2-5.2-4.4-10.5-6.3-15.8c-0.1-0.4-0.3-0.8-0.4-1.3l0,0.1c-0.2-1,0.2-2.2,1.3-2.6
          			c1-0.4,2.2,0.1,2.7,1c0.3,0.5,0.4,1,0.6,1.3c1.3,3.4,2.5,6.8,3.9,10.1c1.1,2.7,2.4,5.3,3.4,8.2c0.5-0.6,1-1.3,1.6-2.1
          			c3.9-6,5.7-12.7,6.8-19.9c0.1-0.8,0.2-1.7,0.3-2.6c0-0.5,0.2-1.2,0.5-1.7l0,0.1c0.5-1,1.8-1.6,2.8-1.2s1.5,1.6,1.3,2.5
          			c-0.7,6-1.9,11.9-4,17.6c-1,2.8-2.6,5.5-4.2,8.1c0.2,0.3,0.5,0.5,0.8,0.9c1.8,1.4,3.6,2.8,5.7,3.7c3,1.5,5.8,3.1,8.6,4.7
          			c2.8,1.6,5.7,3,8.6,4.4c0.8,0.4,1.5,1,2.2,1.6C389.3,564.9,389.4,566.4,388.7,567.3z"/>
          		<path d="M389.7,514.8c1.8,2.6,3.6,5.3,5,8.4c1.3,2.8,2.7,5.7,4,8.7c1.3-0.7,2.5-1.3,3.7-2.1c1.8-1.2,3.7-2.6,5.8-3.7l0.7-0.4
          			c1.1-0.5,2.6-0.1,3.1,0.9c0.5,1,0.1,2.6-1.1,3.1l0.1,0c-1.8,0.9-3.4,2.1-5.2,3.3c-1.7,1.2-3.5,2-5.2,3l0.2,0.3
          			c2.1,3.5,3.8,7.2,5.5,10.9c0.9,2,1.6,3.9,2.6,5.7l0.3,0.5c1.3-1.1,2.8-1.7,4.1-2.5l0.1,0c3.7-2,7.4-4.1,11.1-6.2
          			c0.3-0.2,0.7-0.5,1.4-0.6c0.8-0.1,1.9,0.2,2.4,1.1c0.5,0.8,0.2,2.1-0.4,2.6c-0.4,0.3-0.8,0.6-1.1,0.8c-4.3,2.4-8.7,4.9-13,7.2
          			c-1.2,0.6-2.3,1.7-3.8,2.5c-1.6,0.8-2.9,0.5-3.8-1.2c-1.4-2.5-2.5-5-3.5-7.5c-0.8-2.1-1.7-4.1-2.8-5.9c-3.4-6.1-6.3-12.4-9.1-18.6
          			c-1.3-2.8-3-5.3-4.7-7.9c-0.4-0.7-0.8-1.3-1.2-1.8c-0.3-0.5-0.7-1.5,0.1-2.6c0.9-0.5,1.9-1.4,3.3-2.1c2.2-1,4.1-2.8,6.5-4.1
          			c2.6-1.4,5.2-2.9,7.5-4.7c0.4-0.3,1.1-0.7,1.9-0.5c0.7,0.1,1.6,0.6,1.9,1.3c0.4,0.9,0.1,2-0.5,2.5c-0.5,0.4-1.1,0.9-1.8,1.3
          			c-1.6,1-3.3,2.2-5.2,3.2C395.5,511,392.8,513.2,389.7,514.8z"/>
          	</g>
          </g> -->






          



          </svg>
        </div>
        <div class="col">
          <div v-if="!blnFeedbackSent">
            <!-- <p class="text-muted">These wonderful words will be sent directly to the makers of the Remixer Machine:</p> -->
            <b-form-textarea maxlength="250" required v-model="feedbackText" :state="feedbackTextState" class="form-control mt-2 mb-2 bg-dark text-white" id="feedbackText" rows="3" placeholder="<A sentence or two will do!>" ref="yourfeedback" aria-describedby="feedbackText-feedback"></b-form-textarea>
            <b-form-invalid-feedback id="feedbackText-feedback" class="mb-2" :state="feedbackTextState">
              Please enter a message.
            </b-form-invalid-feedback>
            
            <b-form-input type="email" v-model="feedbackEmail" :state="feedbackEmailState" class="form-control bg-dark text-white" id="feedbackEmail" placeholder="<Your Email>" ref="youremail" aria-describedby="feedbackEmail-feedback" />
            <b-form-invalid-feedback id="feedbackEmail-feedback" :state="feedbackEmailState">
              Please enter a valid email address
            </b-form-invalid-feedback>
            <button class="btn btn-primary mt-2" @click="submitForm">Send</button>
          </div>
          <div v-else>
            <b-alert variant="success" show >Thank you! :)</b-alert>
          </div>
        </div>
      <!-- </b-modal> -->

      <!-- <div v-b-modal.smileyfeedback>
        <svg width="100%" style="vertical-align:top;" id="Smiley" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-10 -80 520 680">

          <circle  cx="250" cy="250" r="250" :fill="emotionColor.toHexString()" style="stroke:#ccc; stroke-width:20"/>
          <path :d="'M75,200 ' + ' C90' + ',' + (200 + indicator/5) + ' 110' + ',' + (200 + indicator/5) + ' 125,200'"
            style="stroke-linecap:round; stroke-width:20; stroke:#000; fill:none;" />
          <path :d="'M375,200 ' + ' C390' + ',' + (200 + indicator/5) + ' 410' + ',' + (200 + indicator/5) + ' 425,200'"
            style="stroke-linecap:round; stroke-width:20; stroke:#000; fill:none;" />
          <path id="0" :d="mouthPath" style="stroke-linecap:round; stroke-width:20; stroke:#000; fill:none;" />

        </svg>
      </div> -->
    </div>



</template>

<script>
import tinycolor from 'tinycolor2'
//import bModal from 'bootstrap-vue/es/components/modal/modal'
//import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
export default {
  name: 'Smiley',
  components: {
    //'b-modal': bModal,
  },
  directives: {
    //'b-modal': bModalDirective
  },
  props: {
    iftttTriggerURL: {
      type: String,
      default: 'https://maker.ifttt.com/trigger/remixer_feedback/with/key/cjltWXNMf3QbMlAzSFDu9O'
    }
  },
  data () {
    return {
      feedbackSubmitted: false,
      feedbackText: '',
      //feedbackTextState: null,
      feedbackEmail: '',
      //feedbackEmailState: null,
      blnFeedbackSent: false,
      indicator: 0,
      minIndicator: -100,
      maxIndicator: 100,
      arc: {
        start: {
          x:100,
          y:350,
          cx:150,
          cy:250,
        },
        end: {
          x:400,
          y:350,
          cx:350,
          cy:250,
        }
      }
    }
  },
  methods: {
    // showFeedbackModal() {
    //
    //   //file upload modal appears
    //   this.$refs.smileyfeedback.show()
    //
    //   console.log(this.$refs.youremail.value)
    //
    // },
    validEmail (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    submitForm (){
      this.feedbackSubmitted = true;
      if (this.feedbackTextState == true && this.feedbackEmailState == true){
        this.sendFeedback()
      }
    },

    sendFeedback () {
      // if (this.feedbackText.length == 0){
      //   this.feedbackTextState = false; 
      //   return
      // }
      // if (this.feedbackEmail.length == 0){
      //   this.feedbackEmailState = false; 
      //   return
      // }
      // if (!this.validEmail(feedbackEmail)){
      //   this.feedbackEmailState = false; 
      //   return
      // }

      // package up the model + indicator
      // value1 = indicator value
      // value2 = feedback form
      // value3 = route (and some data?)
      //+ encodeURIComponent($( "#the_form" ).serialize())
      console.log(navigator.userAgent);
      var iftttURL = this.iftttTriggerURL
      + "?value1=" + this.$refs.youremail.value
      + "&value2=" + encodeURIComponent('Smiley:' + Math.floor(this.indicator) + '<br>' + this.$refs.yourfeedback.value)
      + "&value3=" + encodeURIComponent(navigator.userAgent);

      //console.log(iftttURL);
      //{ "from": "{{FromName}} <{{FromAddress}}>", "received_at": "{{ReceivedAt}}", "subject": "{{Subject}}", "body": "<<<{{BodyPlain}}>>>" }

      try{
        var oReq = new XMLHttpRequest();
        oReq.open("get", iftttURL);
        oReq.withCredentials = true;
        // BUG - always throws a CORS error
        //oReq.setRequestHeader("Content-Type", "application/json");
        // oReq.onerror = function(){
        //   console.log("error sending feedback")
        // };
        // oReq.onload = function(){
        //   console.log("Success!")
        //   this.blnFeedbackSent = true;
        // };


        oReq.send();
      } catch (e) {
        // CORS error
      }

      this.blnFeedbackSent = true;

    },
    startMove(evt) {
      //console.log('hello')
      const touch = (evt.type === "touchstart")
      if (!touch && evt.button !== 0) return;
      const events = touch ? {
        move: "touchmove",
        stop: "touchend"
      } : {
        move: "mousemove",
        stop: "mouseup"
      }
      const elem = evt.currentTarget.closest("svg")
      //console.log('elem',elem)
      const point = elem.createSVGPoint()

      const transform = elem.getScreenCTM().inverse()
      //console.log('transform', transform)
      const circlePos = evt.currentTarget
      //console.log('circlePos', circlePos)
      const getPos = touch ? getTouchPos : getMousePos

      getPos(evt,point)

      var moving = true
      var newPt = point.matrixTransform(transform)

      // calculate the offset between the click and the elements stored position
      //const offsetX = newPt.x - this.things[circlePos.id].x;
      const offsetY = newPt.y - this.indicator;

      const updateFn = () => {

        this.$emit('updateFn')
        if (moving) requestAnimationFrame(updateFn)

        // Map the screen pixels back to svg coords
        newPt = point.matrixTransform(transform)

        //const differenceX = newPt.x - this.things[circlePos.id].x;
        const differenceY = newPt.y - this.indicator;

        //this.things[circlePos.id].x = newPt.x - offsetX;

        this.indicator = newPt.y - offsetY;



      }

      const moveFn = (evt) => {
        //this.$emit('moveFn')
        getPos(evt, point)
      }

      const stopFn = (evt) => {
        //this.$emit('stopFn')
        moving = false
        elem.removeEventListener(events.move, moveFn)
        elem.removeEventListener(events.stop, stopFn)
        //this.showFeedbackModal()
      }

      requestAnimationFrame(updateFn)
      moveFn(evt)

      elem.addEventListener(events.move, moveFn)
      elem.addEventListener(events.stop, stopFn)
    }
  },
  computed: {
    mouthPath: function(){
      return "M" + this.arc.start.x + "," + this.arc.start.y + " C" + this.arc.start.cx + "," + (this.indicator+350) + " " +
      this.arc.end.cx + "," + (this.indicator+350) + " " + this.arc.end.x + "," + this.arc.end.y;
    },
    emotionColor: function(){
      var theAmount = this.indicator
      if (theAmount > 0){
        return tinycolor.mix("white", "#16a8e2", theAmount )
      }else{
        return tinycolor.mix("white", "#de4500", (-1*theAmount) )
      }
    },
    feedbackTextState (){
      if (this.feedbackSubmitted){
        return this.feedbackText.length > 0
      } else {
        return null
      }
    },
    feedbackEmailState (){
      if (this.feedbackSubmitted){
        return (this.validEmail(this.feedbackEmail))
      } else {
        return null
      }
    }

  },
  watch: {
    indicator: function(val){
      if (val > this.maxIndicator) {
        this.indicator = this.maxIndicator;
      }else if (val < this.minIndicator) {
        this.indicator = this.minIndicator;
      }
    }
  },
  mounted: function () {
    //this.faceColor = tinycolor("red");
  }
}

function getMousePos(mouseEvent, point) {
	point.x = (mouseEvent.clientX)
	point.y = (mouseEvent.clientY)
}

function getTouchPos(touchEvent, point) {
	point.x = (touchEvent.touches[0].clientX)
	point.y = (touchEvent.touches[0].clientY)
}

</script>

<style>
.draggable {
  cursor: pointer;
}
</style>
