<template>
  <div class="d-flex flex-row align-items-center text-muted small">
    <!-- CC Licence -->
    <div><font-awesome-icon style="font-size:2em"  :icon="['fab', 'creative-commons']" /></div>
    <div><font-awesome-icon style="font-size:2em"  :icon="['fab', 'creative-commons-sa']" /></div>
    <div class="ml-2">
      {{name}}
      <span >
        by <a href="https://visualthinkery.com">@visualthinkery</a>
        is licensed
        <span style="white-space: nowrap;">under <a :href="ccLink">CC-{{licence}}</a></span>.
        <span v-if="remix !=''">Remix by {{remix}}.</span>
      </span>

    </div>
  </div>
</template>



<script>

/*
TODO


*/

export default {
  name: 'CC-Licence',
  components: {

  },
  props: {
    name: {type: String},
    licence: {type: String},
    remix: {type: String, default: ''},
  },
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {
    ccLink: function () {
      return 'https://creativecommons.org/licenses/' + this.licence + '/4.0/'
    }
  },
  created: function(){

  },
  mounted: function(){

  }
}

</script>

<style>

</style>
