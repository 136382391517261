import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b511052 = () => interopDefault(import('../pages/galleries.vue' /* webpackChunkName: "pages/galleries" */))
const _5364add2 = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _db649e80 = () => interopDefault(import('../pages/members.vue' /* webpackChunkName: "pages/members" */))
const _495aa950 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _28d4ce5e = () => interopDefault(import('../pages/remixes.vue' /* webpackChunkName: "pages/remixes" */))
const _56743f3f = () => interopDefault(import('../pages/remixes-visual.vue' /* webpackChunkName: "pages/remixes-visual" */))
const _e7b804f2 = () => interopDefault(import('../pages/test-embed.vue' /* webpackChunkName: "pages/test-embed" */))
const _7b39f4df = () => interopDefault(import('../pages/g/c/_code.vue' /* webpackChunkName: "pages/g/c/_code" */))
const _0d587558 = () => interopDefault(import('../pages/g/_gid.vue' /* webpackChunkName: "pages/g/_gid" */))
const _5ff1aaa4 = () => interopDefault(import('../pages/a/_app/_id.vue' /* webpackChunkName: "pages/a/_app/_id" */))
const _348387ee = () => interopDefault(import('../pages/r/_rid/_gid.vue' /* webpackChunkName: "pages/r/_rid/_gid" */))
const _7c7ca3b9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/galleries",
    component: _7b511052,
    name: "galleries"
  }, {
    path: "/info",
    component: _5364add2,
    name: "info"
  }, {
    path: "/members",
    component: _db649e80,
    name: "members"
  }, {
    path: "/profile",
    component: _495aa950,
    name: "profile"
  }, {
    path: "/remixes",
    component: _28d4ce5e,
    name: "remixes"
  }, {
    path: "/remixes-visual",
    component: _56743f3f,
    name: "remixes-visual"
  }, {
    path: "/test-embed",
    component: _e7b804f2,
    name: "test-embed"
  }, {
    path: "/g/c/:code?",
    component: _7b39f4df,
    name: "g-c-code"
  }, {
    path: "/g/:gid?",
    component: _0d587558,
    name: "g-gid"
  }, {
    path: "/a/:app?/:id?",
    component: _5ff1aaa4,
    name: "a-app-id"
  }, {
    path: "/r/:rid?/:gid?",
    component: _348387ee,
    name: "r-rid-gid"
  }, {
    path: "/",
    component: _7c7ca3b9,
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
