<template>

    <div v-if="remixes.length > 0">

      <!-- using flex row -->
      <div v-if="flexRow" class="d-flex  align-items-center flex-wrap justify-content-between" style="">

        <div  v-for="(remix, idx) in remixes" :key="idx" class="" style="text-align:center">
          <div v-if="$globalvars.templates[remix.templateID].status =='live'">
            <nuxt-link :to="{name: 'r-rid-gid', params: {rid:remix.id, gid:remix.galleryID}}"><img style="max-height:120px" class="img-fluid mb-2 mt-3" :src="(remix.updated) ? remix.thumbnail + '&u=' + remix.updated.seconds : remix.thumbnail"></nuxt-link><br/>
            <div class="d-flex align-items-center justify-content-center" style="line-height:1em;">
              <!-- <div class="small mr-2">
                <nuxt-link :to="{name: remix.routeName, params: {view:'remix_min',id:remix.id}}">{{$globalvars.templates[remix.templateID].title}}</nuxt-link>
              </div> -->
              <div class="" style="font-size: 0.6rem;">
                <font-awesome-icon  :icon="['fab', 'creative-commons']" />
              </div>
              <div class="mr-1" style="font-size: 0.6rem;">
                <font-awesome-icon  :icon="['fab', 'creative-commons-sa']" />
              </div>
              <div class="mr-2" style="font-size: 0.6rem; word-break: break-all;">
                {{remix.creator.name}}
              </div>
              <!-- <div class="ml-auto">
                <button @click="removeRemix(remix.id)" class="btn btn-primary btn-sm" v-if="($store.getters.isAdmin || ($store.getters.isLoggedIn && ($store.getters.userID == remix.creator.id)))"><font-awesome-icon  size="lg" :icon="['fas', 'trash-alt']" /></button>
              </div> -->
            </div>
          </div>
        </div>

      </div>

      <!-- <div v-else class="card-columns" style="align-self:center">

        <div style="display:inline-block" class="" v-for="(remix, idx) in remixes" :key="idx">
          <nuxt-link :to="{name: remix.routeName, params: {view:'remix_min',id:remix.id}}"><img style="max-width:100%;height:auto;" class="img-fluid mb-2 mt-3" :src="remix.thumbnail"></nuxt-link><br/>
          <div class="d-flex align-items-center justify-content-center" style="line-height:1em;">
            <div class="small mr-2" style="font-size: 0.7rem;">
              <nuxt-link :to="{name: remix.routeName, params: {view:'remix_min',id:remix.id}}">{{$globalvars.templates[remix.templateID].title}}</nuxt-link> by {{remix.creator.name}} CC-BY-SA
            </div>

            <div class="">
              <a @click="removeRemix(remix.id)" class="btn btn-link" v-if="($store.getters.isAdmin || ($store.getters.isLoggedIn && ($store.getters.userID == remix.creator.id)))"><font-awesome-icon  size="sm" :icon="['fas', 'trash-alt']" /></a>
            </div>
          </div>
        </div>

      </div> -->

      <div v-else class="remix-grid" style="align-self:center">
        <!-- style="text-align:center" -->
        <div  :style="{ 'grid-column': 'span ' + $globalvars.templates[remix.templateID].colSpan, 'grid-row': 'span ' + $globalvars.templates[remix.templateID].rowSpan }" v-for="(remix, idx) in remixes" :key="idx" v-if="$globalvars.templates[remix.templateID].status =='live'">
          <nuxt-link :to="{name: 'r-rid-gid', params: { rid:remix.id, gid:remix.galleryID }}">
            <!-- <picture>
              <source :srcset="getSrcSetURL(remix.id, (remix.updated)? remix.updated : '0', 'WEBP')" type="image/webp"> 
              <source :srcset="getSrcSetURL(remix.id, (remix.updated)? remix.updated : '0', 'JPG')" type="image/jpeg"> 
              <img style="width:100%" class="hanging-pic" 
                :title="$globalvars.templates[remix.templateID].title + ' by ' + remix.creator.name + ' CC-BY-SA'" 
                :src="getImageURL(remix.id, (remix.updated)? remix.updated : '0', 'JPG', '250x250')">
            </picture> -->
            <remix-thumb :id="'remixpic-'+remix.id" :remix="remix" :key="remix.id"/>
            <!-- <remix-thumb :remix="remix" /> -->
          </nuxt-link>
          <div class="d-flex align-items-center " style="line-height:1em;">
            <div class="small mr-2" style="font-size: 0.5rem; word-break: break-all;">
              <font-awesome-icon :icon="['fab', 'creative-commons-sa']" />
              <!-- <nuxt-link :to="{name: remix.routeName, params: {view:'remix_min',id:remix.id}}">{{$globalvars.templates[remix.templateID].title}}</nuxt-link> by --> {{remix.creator.name}} <!--CC-BY-SA-->
            </div>

            <!-- <div class="">
              <a @click="removeRemix(remix.id)" class="" v-if="($store.getters.isAdmin || ($store.getters.isLoggedIn && ($store.getters.userID == remix.creator.id)))"><font-awesome-icon  size="xs" :icon="['fas', 'trash-alt']" /></a>
            </div> -->

            <div class="">
              <a href="#" @click.prevent="showDeleteModal(idx)"  class="link" v-if="($store.getters.isAdmin || ($store.getters.isLoggedIn && ($store.getters.userID == remix.creator.id)))"><font-awesome-icon  size="xs" :icon="['fas', 'trash-alt']" /></a>
            </div>

            <!-- <b-button @click="showDeleteModal(idx)">Launch</b-button> -->




          </div>
        </div>

        <b-modal  id="modal-delete"
                  centered
                  title="Delete this remix?"
                  body-bg-variant="dark"
                  header-bg-variant="dark"
                  footer-bg-variant="dark"
                  ok-title="DELETE"
                  cancel-title="NO"
                  ok-variant="danger"
                  @ok="removeRemix(remixes[selectedRemix].id,remixes[selectedRemix].galleryID)">
          Are you sure?
          <!-- <div style="text-align:center">
            <img style="max-height:250px;" class=""  :src="('updated' in remixes[selectedRemix]) ? remixes[selectedRemix].thumbnail + '&u=' + remixes[selectedRemix].updated.seconds : remixes[selectedRemix].thumbnail">
          </div> -->
        </b-modal>

        

      </div>



      <div v-if="!noPaginate" class="mt-2 mb-2 d-flex align-items-center justify-content-end">
        <div class=""><button @click="prevPage" class="btn btn-primary btn-sm" :disabled="!(this.currentPage > 0)"><font-awesome-icon  size="lg" :icon="['fas', 'arrow-circle-left']" /></button></div>
        <div class="ml-2 mr-2">Page {{(currentPage+1)}} ({{noFirstRemix}}-{{noLastRemix}})</div>
        <div class=""><button @click="nextPage" class="btn btn-primary btn-sm float-right" v-if="(this.remixes.length > 0 && !this.isFinalPage)" ><font-awesome-icon  size="lg" :icon="['fas', 'arrow-circle-right']" /></button></div>
      </div>
    </div>
    <div v-else-if="!dataReady" class="d-flex flex-wrap align-items-center justify-content-center m-2">
      <div><span class="text-muted">Fetching remixes...</span></div>
    </div>
    <div v-else-if="remixes.length == 0 && dataReady" class="d-flex flex-wrap align-items-center justify-content-center m-2">
      <div class="mr-2"><font-awesome-icon style="color:#555555" size="2x" :icon="['fas', 'sad-tear']" /></div>
      <div><span class="text-muted">There are no remixes of this just yet. Why not publish one?</span></div>
    </div>


</template>

<script>

// import firebase
// import { storageRef, modelCollectionRef, remixCollectionRef } from '~/plugins/firebase.js'
// import functions to remove item from DB cleanly
import { dbFunctions } from '~/components/mixins/dbFunctions.js'
// import functions and bindings for a remix array
import { firebaseDocPagination } from '~/components/mixins/firebaseDocPagination.js'

export default {
  name: 'RemixSnapshots',
  components: {

  },
  mixins: [dbFunctions,firebaseDocPagination],
  props: {
    limit: {
      type: Number,
      default: 10
    },
    filterProperty: {
      type: String,
      default: ""
    },
    filterOperator: {
      type: String,
      default: ""
    },
    filterValue: {
      type: String,
      default: ""
    },
    flexRow: {
      type: Boolean,
      default: false
    },
    noPaginate: {
      type:Boolean,
      default: false
    },
    galleryID: {
      type: String,
      default: "",
    },

  },

  data () {
    return {
      selectedRemix: 0,

    }
  },

  created () {
    this.qLimit = this.limit;
    this.qFilterProperty = this.filterProperty;
    this.qFilterOperator = this.filterOperator;
    this.qFilterValue = this.filterValue;
    this.qGalleryID = this.galleryID;
    this.bindData();
    
    
  },

  // async fetch() {
  //     this.qLimit = this.limit;
  //     this.qFilterProperty = this.filterProperty;
  //     this.qFilterOperator = this.filterOperator;
  //     this.qFilterValue = this.filterValue;
  //     this.qGalleryID = this.galleryID;
  //     await this.bindData();
  //   },


  methods: {
    showDeleteModal: function(deleteRemixID){
      console.log(deleteRemixID);
      this.selectedRemix = deleteRemixID;
      this.$root.$emit('bv::show::modal', 'modal-delete');
    },

    


  },

  watch: {
    filterValue: function(){
      console.log('filter prop changed')
      this.qLimit = this.limit;
      this.qFilterProperty = this.filterProperty;
      this.qFilterOperator = this.filterOperator;
      this.qFilterValue = this.filterValue;
      this.currentPage = 0;
      this.$unbind('remixes')
      this.bindData();
    }
  },

  computed: {
    noFirstRemix: function(){
      return (this.currentPage*this.limit) + 1;
    },
    noLastRemix: function(){
      return (this.currentPage*this.limit) + this.remixes.length;
    }
  }



}



</script>

<style>

.remix-grid {
  display: grid;
  box-sizing: border-box;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr)); */
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  align-items: center;

}

.item-landscape {
  grid-column: span 2;
}

.item-portrait {
  grid-row: span 2;
}

</style>
