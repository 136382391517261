<template>
  <g :transform="'translate(' + this.handles[0].x + ' ' + this.handles[0].y + ')'" 
      id="moveableText">
    <foreignObject style="overflow:visible" x=0 y=0 :width="handles[1].x" :height="handles[1].y">
      <div class="ResizeableTextObjInner" :style="{display:'flex', 'justify-content':'center', height:'inherit', 
      'background-color': (updatedTextObj.selected)? 'transparent':'transparent',
      'outline': (updatedTextObj.selected)? '5px dashed red':'none'
      }" >
        
        <div xmlns="http://www.w3.org/1999/xhtml" :style="updatedTextObj.style" @focusout="updateText" contenteditable ref="remixTextDiv"></div>
      </div>
    </foreignObject>
    <g v-on="updatedTextObj.selected ? { mousedown: (event) => startMove(event,0), touchstart:(event) => startMove(event,0) } : {}" v-show="updatedTextObj.selected" style="cursor: pointer;">
      <circle  cx="0" cy="0" r="30" stroke="white" fill="#16a8e2" stroke-width="3"/>
      <font-awesome-icon alt="Move" class="" width="40" height="40" x=-20 y=-20 :icon="['fas', 'arrows-alt']" />
    </g>
    <g v-on="updatedTextObj.selected ? { mousedown: (event) => startMove(event,1), touchstart:(event) => startMove(event,1) } : {}" v-show="updatedTextObj.selected" style="cursor: pointer;">
      <circle :cx="handles[1].x" :cy="handles[1].y" r="30" stroke="white" fill="#16a8e2" stroke-width="3"/>
      <g :transform="'rotate(90 ' + handles[1].x + ' ' + handles[1].y + ')'"><font-awesome-icon alt="Resize" class="" width="40" height="40" :x="handles[1].x-20" :y="handles[1].y-20" :icon="['fas', 'expand-alt']" /></g>
    </g>
    <g @mousedown="deleteTextObj" v-show="updatedTextObj.selected" style="cursor: pointer;">
      <circle :cx="handles[1].x" cy="0" r="30" stroke="white" fill="red" stroke-width="3"/>
      <font-awesome-icon alt="Delete" class="" width="40" height="40" :x="handles[1].x-20" y="-20" :icon="['fas', 'trash-alt']" />
    </g>
      
  </g>
</template>

<script>

export default {
  name: 'RemixResizeTextInSitu',
  components: {
  },
  props: {
    textObj: {
      type: Object
    },
  },
  data () {
    return {
      updatedTextObj:
        {
            x: 0,
            y: 0,
            width: 100,
            height: 100,
            text: "",
            style: {},
            selected:false,
          },

      handles: [
        {x:0,y:0},
        {x:0,y:0}
      ],

      // dragging variables
      moving: false,
      touch: false,
      transform: [],
      point: {},
      offsetX: 0,
      offsetY: 0,
      circlePos: {},
      elem: {},
      events: {},
      handleSelected:0,
    }
  },

  methods: {

    startMove(evt,handleIndex) {
      // prevent default scrolling behaviour
      evt.preventDefault();
      
      //console.log(handleIndex)
      this.handleSelected = handleIndex;

      this.touch = (evt.type === "touchstart")
      if (!this.touch && evt.button !== 0) return;

      this.events = this.touch ? {
        move: "touchmove",
        stop: "touchend"
      } : {
        move: "mousemove",
        stop: "mouseup"
      }

      this.$set(this, 'elem', evt.currentTarget.closest("svg"))
      //console.log('elem',elem)
      this.$set(this,'point', this.elem.createSVGPoint())


      this.transform = this.elem.getScreenCTM().inverse()
      //console.log('transform', transform)
      this.circlePos = evt.currentTarget
      //console.log('circlePos', circlePos)
      //const getPos = this.touch ? getTouchPos : getMousePos

      this.getPos(evt,this.point)

      this.moving = true
      var newPt = this.point.matrixTransform(this.transform)

      // calculate the offset between the click and the elements stored position
      this.offsetX = newPt.x - this.handles[this.handleSelected].x;
      this.offsetY = newPt.y - this.handles[this.handleSelected].y;

      requestAnimationFrame(this.updateFn)
      this.moveFn(evt)

      this.elem.addEventListener(this.events.move, this.moveFn)
      this.elem.addEventListener(this.events.stop, this.stopFn)
    },

    updateFn() {

      if (this.moving) requestAnimationFrame(this.updateFn)

      // Map the screen pixels back to svg coords
      var newPt = this.point.matrixTransform(this.transform)

      if (this.handleSelected ==0 ){
        // free movement for position
        this.handles[this.handleSelected].x= newPt.x - this.offsetX;
        this.handles[this.handleSelected].y= newPt.y - this.offsetY;
      }else{
        // no negative coordinates for width
        this.handles[this.handleSelected].x= Math.max((newPt.x - this.offsetX), 50) ;
        this.handles[this.handleSelected].y= Math.max((newPt.y - this.offsetY), 50) ;
      }

    },

    moveFn(evt) {
      //this.$emit('moveFn')
      this.getPos(evt, this.point)
    },

    stopFn(evt) {
      //this.$emit('stopFn')
      //console.log('stop')
      this.moving = false
      this.elem.removeEventListener(this.events.move, this.moveFn)
      this.elem.removeEventListener(this.events.stop, this.stopFn)

      // update Text object with the new handles positions
      this.updatedTextObj.x = this.handles[0].x;
      this.updatedTextObj.y = this.handles[0].y;
      this.updatedTextObj.width = this.handles[1].x;
      this.updatedTextObj.height = this.handles[1].y;
    },

    getPos(evt, point) {
      if (this.touch) {
        //getTouchPos
        point.x = (evt.touches[0].clientX)
        point.y = (evt.touches[0].clientY)

      }else{
        //getMousePos
        point.x = (evt.clientX)
        point.y = (evt.clientY)
      }
    },

    updateText: function (event) {
      //console.log('updateText')
      // deselect the textbox
      this.updatedTextObj.selected = false;
      //this.updatedTextObj.text = event.target.innerText;
      this.$set(this.updatedTextObj, 'text', event.target.innerText);
      //console.log("Object now looks like this:", this.updatedTextObj)
      //console.log(event);
      // Emit the number value through the input event
      this.$emit('update',this.updatedTextObj);
    },

    deleteTextObj() {
      console.log('deleteTextObj')
      this.$emit('deleteTextObj')
    },

    selectBox() {
      this.updatedTextObj.selected = true;
    }

  },

  watch: {
      'this.updatedTextObj': function (val) {
        this.$emit('update:textObj', this.updatedTextObj)
      },
      deep: true,
  },

  created: function() {

    // initialise Text Obj
    this.updatedTextObj = this.textObj;

    //initialise handles
    this.handles[0].x = this.textObj.x;
    this.handles[0].y = this.textObj.y;
    this.handles[1].x = this.textObj.width;
    this.handles[1].y = this.textObj.height;

    
  },

  mounted: function() {

    this.$refs.remixTextDiv.innerText = this.textObj.text;

  },


}
</script>
