
//import { db, oldCollectionRef, modelCollectionRef, remixCollectionRef, storageRef } from '~/plugins/firebase.js'
export const dbFunctions = {
  data () {
    return {

    }
  },
  methods: {

    removeRemix(remixID, galleryID){
      console.log("remove remix")

      var remixDocRef = {};
      var modelDocRef = {};

      if (galleryID){
        // get remix document
        remixDocRef = this.$fire.firestore.collection(this.$globalvars.galleryCollection).doc(galleryID).collection('remixes').doc(remixID);

        // get model document
        modelDocRef = this.$fire.firestore.collection(this.$globalvars.galleryCollection).doc(galleryID).collection('models').doc(remixID);
      } else {
        // get remix document
        remixDocRef = this.$fire.firestore.collection(this.$globalvars.remixCollection).doc(remixID);

        // get model document
        modelDocRef = this.$fire.firestore.collection(this.$globalvars.modelCollection).doc(remixID);
      }

      // var thumbnailRef = "";

      var vm = this;

      return this.$fire.firestore.runTransaction(function(transaction) {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(remixDocRef).then(function(remixDoc) {
              if (!remixDoc.exists) {
                  throw "Remix Document does not exist!";
              }

              /*
              // store the thumnail ref
              thumbnailRef = remixDoc.data().thumbnailRef;
              console.log("Thumbnail:", thumbnailRef);

              // Create a reference to the file to delete
              // var storageThumbnailRef = storageRef.child( thumbnailRef  );
              // TODO: does this work?
              var storageThumbnailRef = vm.$fire.storage.ref().child( thumbnailRef );



              // Delete the thumbnail from storage
              storageThumbnailRef.delete().then(function() {
                // File deleted successfully
                console.log("Thumbnail deleted!");
              }).catch(function(error) {
                // Uh-oh, an error occurred!
                console.log("Thumbnail not deleted: ", error);
              });
              */

              transaction.delete(remixDocRef);
              transaction.delete(modelDocRef);
          })

      }).then(function() {
          console.log("Transaction successfully committed!");
      }).catch(function(error) {
          console.log("Transaction failed: ", error);
      });



    },


  },

}
