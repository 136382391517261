<template>
  <div class="p-0 rounded hover-panel" v-if="!inline" >

    <div class="d-flex align-items-center p-0" style="font-size:1.2em;line-height:1em;">
      <!-- <div><input type="file" id="ImageURL"  name="ImageURL" class="" v-on:change="updateFile" value=""/></div> -->
      <label class="file-select m-0 d-flex align-items-center" :for="imageID">
        <div class="panel-link p-0"><font-awesome-icon title="Upload Image" style="font-size:1.2em;margin:5px;margin-left:10px;" :icon="['fas', 'file-upload']" /></div>
        <div class="panel-link"><img title="Upload Image" class="" style="max-width:100px;max-height:35px;margin:7px;" :src="updatedImageObj.base64" :alt="imageObj.name"/></div>
        <input type="file" class="custom-file-input" :id="imageID" v-on:change="this.updateFile" value="">
      </label>


      <div class="panel-link p-0" @click="rotateImage"><font-awesome-icon title="Rotate" class="" style="font-size:1.2em;margin:5px;margin-left:10px;" :icon="['fas', 'sync-alt']" /></div>
      <div class="panel-link p-0" @click="enlargeImage" v-if="!nozoom"><font-awesome-icon title="Zoom in" class=""  style="font-size:1.2em;margin:5px;" :icon="['fas', 'search-plus']" /></div>
      <div class="panel-link p-0" @click="reduceImage" v-if="!nozoom"><font-awesome-icon title="Zoom out" class="" style="font-size:1.2em;margin:5px;"  :icon="['fas', 'search-minus']" /></div>
      <div class="panel-link p-0" @click="centerImage"><font-awesome-icon title="Centre" class=""  style="font-size:1.2em;margin:5px;"  :icon="['far', 'dot-circle']" /></div>
      <!--<div class="pl-2 ml-auto" style="width:100%"><input type="range" id="ImageScale" name="ImageScale" class="form-control form-control-range" v-model="updatedImageObj.scale" min="0" max="4" step="0.1" v-if="updatedImageObj.scale" style="width:100%"/></div>-->
      <div class="ml-auto panel-link p-0" @click="closeOptions"><font-awesome-icon title="Close" class="" style="font-size:1.2em;margin:5px;margin-right:10px;"  :icon="['fas', 'window-close']" /></div>
    </div>

  </div>

  <div class="" v-else >

    <div class="d-flex align-items-center" style="font-size:1.5em;line-height:1em">
      <!-- <div><input type="file" id="ImageURL"  name="ImageURL" class="" v-on:change="updateFile" value=""/></div> -->
      <div>

          <label class="file-select m-0 d-flex align-items-center" :for="imageID">
            <div class="mr-2 panel-link p-1"><font-awesome-icon title="Upload" style="font-size:1.5em;" :icon="['fas', 'file-upload']" /></div>
            <div class="mr-2"><img class="" :style="'max-width:100px; max-height:30px; transform: rotate('+ updatedImageObj.rotation +'deg);'" :src="updatedImageObj.base64" :alt="imageObj.name"/></div>
            <input type="file" class="custom-file-input" :id="imageID" v-on:change="this.updateFile" value="">
          </label>

      </div>

      <div @click="rotateImage" class="panel-link p-1"><font-awesome-icon title="Rotate" class=""  style="font-size:1.5em;" :icon="['fas', 'sync-alt']" /></div>
      <div @click="enlargeImage" class="panel-link p-1" v-if="!nozoom"><font-awesome-icon title="Zoom in" class=""  style="font-size:1.5em;"  :icon="['fas', 'search-plus']" /></div>
      <div @click="reduceImage" class="panel-link p-1" v-if="!nozoom"><font-awesome-icon title="Zoom out" class=""  style="font-size:1.5em;"  :icon="['fas', 'search-minus']" /></div>
      <div @click="centerImage" class="panel-link p-1" ><font-awesome-icon title="Centre" class=""  style="font-size:1.5em;"  :icon="['far', 'dot-circle']" /></div>

    </div>

  </div>

</template>


<script>

export default {
  name: 'RemixBgImageInSituControls',
  components: {

  },
  props: {
    label: {
      type: String
    },
    urlStream: {
      type: Boolean,
      default: true
    },
    binaryStream: {
      type: Boolean,
      default: false
    },
    imageObj: {
      type: Object
    },
    idealWidth: {
      type: Number
    },
    ungroup: {
      type: Boolean,
      default: false
    },
    nozoom: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    imageID: {
      default:'imageURL' + Math.ceil(Math.random()*10000000)
    }
  },
  data () {
    return {
      updatedImageObj: {
        // initial settings
        base64: require("@/assets/knob.png"),
        scale: 1,
        rotation: 0,
        x:0,
        y:0,
        centreX:0,
        centreY:0,
        width:0,
        height:0,
        selected:false,
      },

    }
  },

  methods: {
    rotateImage: function() {
      this.updatedImageObj.rotation = this.updatedImageObj.rotation + 90;
    },
    enlargeImage: function() {
      this.updatedImageObj.scale = this.updatedImageObj.scale + 0.1;
    },
    reduceImage: function() {
      this.updatedImageObj.scale = this.updatedImageObj.scale - 0.1;
    },
    centerImage: function() {
      this.updatedImageObj.x = this.updatedImageObj.centreX - this.updatedImageObj.width/2;
      this.updatedImageObj.y = this.updatedImageObj.centreY - this.updatedImageObj.height/2;
    },
    closeOptions: function() {
      this.updatedImageObj.selected = false;
    },
    compressImage: function(source_img_obj, quality, maxWidth, output_format){
      //var mime_type = "image/jpeg";
      var mime_type = output_format;
      /*
      if(typeof output_format !== "undefined" && output_format=="png"){
          mime_type = "image/png";
      }
      */
      console.log(mime_type)

      maxWidth = maxWidth || 1000;
      console.log("maxWidth",maxWidth)

      var natW = source_img_obj.naturalWidth;
      var natH = source_img_obj.naturalHeight;
      var ratio = natH / natW;
      if (natW > maxWidth) {
          natW = maxWidth;
          natH = ratio * maxWidth;
      }

      var cvs = document.createElement('canvas');
      cvs.width = natW;
      cvs.height = natH;

      var ctx = cvs.getContext("2d").drawImage(source_img_obj, 0, 0, natW, natH);
      var newImageData = cvs.toDataURL(mime_type, quality/100);
      var result_image_obj = new Image();
      result_image_obj.src = newImageData;
      return result_image_obj;
    },

    updateFile: function(e){
      //console.log('hello',e);

      var vm = this;
      //console.log(event.target.files[0]);
      if (this.urlStream) {
        this.readUrl(e.target, function(dataUrl) {
          console.log("file URL read");
          // Emit the obj through the input event
          vm.$emit('update',vm.updatedImageObj);

        });
      }

      if (this.binaryStream) {
        this.readBinary(e.target, function(data) {
          //console.log("file Binary read");
          // Emit the obj through the input event
          vm.$emit('update',vm.updatedImageObj);

        });
      }

    },

    readUrl(input, callback) {

      var vm = this;
      //console.log('input',input.files[0]);
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          console.log('e',e)


          vm.imageObj.base64 = e.target.result;
          vm.imageObj.name = input.files[0].name;
          vm.imageObj.type = input.files[0].type;

          // reset the scale of the new image
          vm.imageObj.scale = 1;

          console.log('uploader says its:',input.files[0].size)


          //create an image so that we can get the width and height
          var image = new Image();

          // define the handler function
          image.onload = function() {

            // optimise the uploaded image

            console.log("pre-optimised image",Math.ceil(image.src.length/1024),"K")
            console.log('image size now:',this.width,this.height)

            // create a new optimised image
            var optImage = new Image();

            optImage.onload = function() {
              console.log("optimised image",Math.ceil(optImage.src.length/1024),"K")

              vm.$set(vm.imageObj,'base64',optImage.src);

              vm.$set(vm.imageObj,'width',this.width);
              vm.$set(vm.imageObj,'height',this.height);

              //console.log('image size now:',this.width,this.height)

              // reset the scale of the new image
              //vm.$set(vm.imageObj,'scale',1);

              // image co-ordinates are top left. Place the centre of the image on the coord (centreX,centreY)
              vm.$set(vm.imageObj,'x',vm.imageObj.centreX - this.width/2);
              vm.$set(vm.imageObj,'y',vm.imageObj.centreY - this.height/2);

              console.log('callback')
              callback(reader.result);
            }


            console.log('type of image:', vm.imageObj.type);
            console.log('size of base64 image(k):', vm.imageObj.base64.length/1024);
            console.log('current width:', vm.imageObj.width)
            var optWidth = vm.idealWidth;
            console.log('ideal width:',optWidth)

            // need to figure out if the uploaded image is a PNG. If it is, and it's bigger than 500k in storage then we may need to compress by reducing the dimensions further.
            if ((vm.imageObj.type == 'image/png') && (vm.imageObj.base64.length/1024 > 500)){
              // half the width
              optWidth = optWidth/2;

              // then x4 the scale
              vm.imageObj.scale = 2;
              console.log("width is now",optWidth)
            }else{
              console.log("Don't need to change width",optWidth)
            }


            // optimise the image based on the current width of the image in situ
            optImage.src = vm.compressImage(image, 50, optWidth, vm.imageObj.type).src;




          };

          // set the src of the new image to the uploaded one
          image.src = e.target.result;

          // update the source with a compressed version of the image
          //console.log("pre-optimised image",image)
          //image.src = vm.compressImage(image, 0.8, 500, 'image/jpeg').src;
          //console.log("optimised image",image)


          //input.setAttribute("data-title", imgName);
          //console.log('read the URL',e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
      }
      reader.onloadend = function() {
        //callback(reader.result);
      }
    },

    readBinary(input, callback) {
      var vm = this;
      //console.log(input.files[0]);
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          vm.imageObj.binary = e.target.result;
          vm.imageObj.name = input.files[0].name;

          //input.setAttribute("data-title", imgName);
          //console.log('read the Binary',e.target.result);
        }
        reader.readAsBinaryString(input.files[0]);
      }
      reader.onloadend = function() {
        callback(reader.result);
      }
    },


  },
  created: function(){
    //console.log(this.imageObj)
    //this.updatedImageObj = this.imageObj;
    //this.$set(this,'updatedImageObj', this.imageObj);
  },
  mounted: function(){
    this.updatedImageObj = this.imageObj;
  },
  watch: {
      'this.updatedImageObj': function (val) {
        this.$emit('update:imageObj', this.updatedImageObj)
      },
      deep: true,
  },
}
</script>

<style scoped>


.file-select > input[type="file"] {
  display: none;
}
</style>
