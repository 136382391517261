<template>

  <!-- I dont think this brannch of code is used anymore -->
  <div class="input-group" v-if="!ungroup">
    <div class="input-group-prepend">
      <span class="input-group-text small" id="basic-addon3">{{label}}:</span>
    </div>
    <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" v-model="updatedTextObj.text" v-on:input="updateText" v-if="lines==1">
    <textarea class="form-control" id="basic-url" aria-describedby="basic-addon3" v-model="updatedTextObj.text" v-on:input="updateText" :rows="lines" v-if="lines>1"></textarea>
    <swatch-picker class="input-group-text" label="" :color.sync="textObj.style.fill" v-if="!hideColor"/>

    <input type="range" id="TextScale" name="TextScale" class="form-control form-control-range" v-model="updatedTextObj.style.fontSize" :min="range.min" :max="range.max" :step="range.step" v-if="updatedTextObj.style.hasOwnProperty('fontSize')"/>

  </div>


  <div v-else class="d-flex align-items-center">
    <div class="d-flex">
      <div class="hello">{{label}}</div>
    </div>
    <div><swatch-picker class="mr-2" label="" :color.sync="textObj.style.fill" v-if="!hideColor"/></div>
    <div v-if="lines==1"><input type="text" class="form-control  " id="basic-url" aria-describedby="basic-addon3" v-model="updatedTextObj.text" v-on:input="updateText"></div>
    <div v-if="lines>1"><textarea class="form-control" id="basic-url" aria-describedby="basic-addon3" v-model="updatedTextObj.text" v-on:input="updateText" :rows="lines"></textarea></div>
    <div class="ml-2"><input type="range" id="TextScale" name="TextScale" class="form-control" v-model="updatedTextObj.style.fontSize" :min="range.min" :max="range.max" :step="range.step" v-if="updatedTextObj.style.hasOwnProperty('fontSize')"/></div>

  </div>
</template>

<script>
import SwatchPicker from './SwatchPicker.vue'
export default {
  name: 'RemixText',
  components: {
        swatchPicker: SwatchPicker
  },
  props: {
    label: {
      type: String
    },
    textObj: {
      type: Object
    },
    lines: {
      type: Number,
      default: 1
    },
    hideColor: {
      type: Boolean,
      default: false
    },
    minSize: {
      type: Number,
      default: 0,
    },
    maxSize: {
      type: Number,
      default: 0,
    },
    ungroup: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      updatedTextObj: {
        text: "",
        style: {
          fill:'#fff'
        },
      },
      range: {
        min: 0,
        max: 0,
        step: 1
      }
    }
  },
  mounted () {
    this.updatedTextObj = this.textObj;

    if (typeof this.textObj.style.fontSize !== 'undefined'){
      var intFontSize = parseInt(this.textObj.style.fontSize, 10);
      // if the range params are set, then update the range, otherwise set to sensible defaults.
      this.range.min = (this.minSize) ? this.minSize : intFontSize/4;
      this.range.max = (this.maxSize) ? this.maxSize : intFontSize*2;
    }
  },
  methods: {
    updateColor: function (value) {
      this.updatedTextObj.style.fill = value;
      //this.updatedTextObj.style.fontSize = this.updatedTextObj.style.intFontSize + 'px';
      // Emit the number value through the input event
      this.$emit('update',this.updatedTextObj);
    },
    updateText: function () {
      // Emit the number value through the input event
      this.$emit('update',this.updatedTextObj);
    },
    wordwrap: function (str, intWidth, strBreak, cut) {
      //  credits: http://locutus.io/php/wordwrap/

      intWidth = arguments.length >= 2 ? +intWidth : 75
      strBreak = arguments.length >= 3 ? '' + strBreak : '\n'
      cut = arguments.length >= 4 ? !!cut : false

      var i, j, line

      str += ''

      if (intWidth < 1) {
        return str
      }

      var reLineBreaks = /\r\n|\n|\r/
      var reBeginningUntilFirstWhitespace = /^\S*/
      var reLastCharsWithOptionalTrailingWhitespace = /\S*(\s)?$/

      var lines = str.split(reLineBreaks)

      var l = lines.length
      var match

      // for each line of text
      for (i = 0; i < l; lines[i++] += line) {
        line = lines[i]
        lines[i] = ''

        while (line.length > intWidth) {
          // get slice of length one char above limit
          var slice = line.slice(0, intWidth + 1)

          // remove leading whitespace from rest of line to parse
          var ltrim = 0
          // remove trailing whitespace from new line content
          var rtrim = 0

          match = slice.match(reLastCharsWithOptionalTrailingWhitespace)

          // if the slice ends with whitespace
          if (match[1]) {
            // then perfect moment to cut the line
            j = intWidth
            ltrim = 1
          } else {
            // otherwise cut at previous whitespace
            j = slice.length - match[0].length

            if (j) {
              rtrim = 1
            }

            // but if there is no previous whitespace
            // and cut is forced
            // cut just at the defined limit
            if (!j && cut && intWidth) {
              j = intWidth
            }

            // if cut wasn't forced
            // cut at next possible whitespace after the limit
            if (!j) {
              var charsUntilNextWhitespace = (line.slice(intWidth).match(reBeginningUntilFirstWhitespace) || [''])[0]

              j = slice.length + charsUntilNextWhitespace.length
            }
          }

          lines[i] += line.slice(0, j - rtrim)
          line = line.slice(j + ltrim)
          lines[i] += line.length ? strBreak : ''


        }
      }

      return lines.join(strBreak)
    },

    svgTextWrap: function (textTarget, paragraph, txtWidth, lineHeight, x){
      //var txtWidth = textTarget.getAttribute("data-width");
      //var lineHeight = textTarget.getAttribute("dy");
      //var x = textTarget.getAttribute("x");

      // remove all tspans from text
      var unformattedPara = paragraph.replace(/<[/]*tspan[^>]*>/g, " ");

      // define tspans with line height
      var tSpan = '<tspan x="' + x + '" dy="' + lineHeight + '">';
      var tSpanClose = '</tspan>';

      var formattedPara = tSpan + this.wordwrap(unformattedPara,txtWidth, tSpanClose + tSpan) + tSpanClose;

      textTarget.innerHTML = formattedPara;
    }

  },

}
</script>

<style scoped>


</style>
