<template>
<div class="remixer-background" >

  <div class="container-fluid p-0">


      <!-- REMIX -->
      <div class="pb-3" style="text-align:center;">
        <slot>Main App - nothing to show</slot>
      </div>



  </div>


  <div class="fixed-bottom navbar-light" style="z-index:1031">
    <div class="container-fluid p-0">



      <!-- Tools Panel -->
      <b-collapse id="toolsPanelCollapse" v-model="blnToolsVisible" class="rounded-top" style="background-color:#333333dd;">
      <div class="row m-0">
        <div class="col pl-2 pr-3 pt-1 pb-0">
          <slot name="panel2">No inline tools for this remixer.</slot>
          <save-and-share :filenameBase="this.appSettings.title"></save-and-share>
        </div>
      </div>
      </b-collapse>



      <div class="row align-items-center rounded" style="background-color:#333333dd;">


          <div class="col d-flex align-items-center" style="line-height:1em">

            <div class="mr-2">
              <nuxt-link to="/"><img width="22" src="/favicon.svg" alt="Remixer"></nuxt-link>
            </div>

            <div class="d-flex align-items-center" style="line-height:1em">
              <div class="small mr-2">
                <a :href="this.$route.path" target="_blank">{{this.appSettings.title}}</a>
              </div>
              <div class="">
                <font-awesome-icon  :icon="['fab', 'creative-commons']" />
              </div>
              <div class="mr-1">
                <font-awesome-icon  :icon="['fab', 'creative-commons-sa']" />
              </div>
              <div class="small" v-if="(appRemix != null) && ('creator' in appRemix)">
                {{appRemix.creator.name}}
              </div>
            </div>

            <div class="ml-auto d-none d-sm-inline" style="line-height:1em;width:30px">
              <button v-b-toggle.toolsPanelCollapse class="btn btn-link p-0 m-0 border-0"><font-awesome-icon class="mr-1" style="font-size:0.9em;" :transform="{ rotate: (blnToolsVisible)? -90 : 0 }" :icon="['fas', 'caret-right']" /><font-awesome-icon class="mr-1" style="font-size:0.9em;" :icon="['fas', 'tools']" /></button>
            </div>

          </div>



      </div>
    </div>
  </div>



</div>
</template>




<script>

/*
TODO


*/


export default {
  name: 'embedMinLayout',
  components: {

  },
  inject: ['appThings','appRemix','appSettings'],
  props: {

  },
  data () {
    return {
      blnToolsVisible:false,
    }
  },

  methods: {


  },

  beforeMount: function() {
    // tweak the class of the body tag
    document.body.className = 'embed';
  },


}

</script>

<style lang="css" scoped>
/* #outerSVG, #remix-this {
  max-height: 90vh;
} */


</style>
