<template>
  <div class="card bg-dark mb-3">
    <nuxt-link  :to="{name: 'a-app-id', params: {app:appInstanceID}}"><img class="card-img-top" :src="$globalvars.templates[appInstanceID].image" :alt="$globalvars.templates[appInstanceID].title"></nuxt-link>
    <div class="card-body">
      <h5 class="card-title"><nuxt-link :to="{name: 'a-app-id', params: {app:appInstanceID}}">{{$globalvars.templates[appInstanceID].title}}</nuxt-link></h5>
      <p class="card-text">{{$globalvars.templates[appInstanceID].description}}</p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'panel',
  components: {

  },
  props: {
    appInstanceID: {
      type: String,
      default: ""
    },
  },
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {
    // imageURL: function (){
    //   return '/thumbs/' + this.appObject.image;
    // }
  },
  created: function(){
    //console.log(this.appObject)
  },
  mounted: function(){
    //console.log('globalvars',this.appInstanceID, this.$globalvars.templates[this.appInstanceID])
  }
}

</script>

<style>

</style>
