<template>
  <div class="RemixPath d-flex">
      <span class="label p-2">{{label}}:</span>
      <div class="swatch mr-2" :style="'background-color: ' + updatedStyleObj.hex" @click="displayPicker = !displayPicker"><div class="position-absolute" style="z-index:50;margin-top:2rem" v-if="displayPicker"><color-picker width="30" :palette="palette" disable-alpha disable-fields v-model="updatedStyleObj" @input="updateParent"></color-picker></div></div>
  </div>
</template>

<script>
//import Compact from 'vue-color/src/components/Compact.vue';
import { Compact } from 'vue-color'
export default {
  name: 'RemixPath',
  components: {
    colorPicker: Compact
  },
  props: {
    label: {
      type: String
    },
    styleObj: {
      type: Object
    }
  },
  data () {
    return {
      palette: ['#2f495a', '#de4500', '#16a8e2'],
      updatedStyleObj: {
          hex: this.styleObj.fill
      },
      displayPicker: false
    }
  },
  methods: {
    updateParent: function () {
      // Emit the number value through the input event
      this.$emit('update',this.updatedStyleObj.hex);
    }
  }
}
</script>

<style scoped>
.vc-compact {
  width: 70px;
}

</style>
