export const galleryFunctions = {
  data () {
    return {
      galleryCollectionRef:"",
    }
  },
  methods: {

    initGallery(){
      const newGallery = {
        title: "",
        description: "",
        creator: {},
        ownerID: "",
        code: "",
        remixPromptID: "",
        remixPromptThumbURL: "",
        templateID: "",
        created: this.$fireModule.firestore.FieldValue.serverTimestamp(),
        numRemixes: 0,
        numContributors: 0,
        blnAllowAnonPosts: false,
        //Allow share? = true

      }

      return newGallery
    },

    makeid(length) {
      var result           = ''; 
      var characters       = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789'; // remove O
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    async createGallery(galleryObj){
      // create a new gallery
      console.log("createGallery",galleryObj)

      galleryObj.creator = this.$store.state.user;
      galleryObj.ownerID = this.$store.state.user.id;

      if (galleryObj.code == "") {
        galleryObj.code = this.makeid(6);
      }

      // update the MAXRemixesProperty of the Gallery
      galleryObj.maxRemixes = this.$store.getters.maxRemixes;

      // add the new record and return a reference to it
      return await this.galleryCollectionRef.add(galleryObj)
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });

    },

    getGallery(galleryID){
      // fill in the Remix Object from the ID
      // fill in the UserID from the Owner?
      console.log("getGallery",galleryID)
    },

    async getGalleries(userID){
      // Get all the Galleries owned by this user.
      // not sure if this is needed!
      console.log("getGalleries",userID)

      // construct the query
      return await this.galleryCollectionRef
        .where('ownerID','==',userID)
        .orderBy("created", "desc")
        .get();
    },

    getRemixesInGallery(galleryID){
      // Get all the remixes in a gallery
      console.log("getRemixesInGallery",galleryID)
    },

    updateGallery(galleryID, galleryObj){
      //Merge the fields with new data supplied by galleryObj
      console.log("updateGallery",galleryID,"with",galleryObj)

      // update the MAXRemixesProperty of the Gallery
      galleryObj.maxRemixes = this.$store.getters.maxRemixes;

      return this.$fire.firestore.collection(this.$globalvars.galleryCollection).doc(galleryID).update(galleryObj)
      .then(function() {
        console.log ('successfully updated a gallery')
      })
      .catch(function(error) {
        console.error("Error updating gallery: ", error);
      });
    },

    async deleteGallery(galleryID){
      //Delete the Gallery, and remove all remixes associated with that gallery. (what about thumbnails?)
      console.log("Deleting Gallery:",galleryID)

      // '{galleryCollection}/{galleryID}'
      const path = this.$globalvars.galleryCollection + '/' + galleryID; 
      var deleteFn = this.$fire.functions.httpsCallable('deleteGallery');
      deleteFn({ path: path })
        .then(function(result) {
          console.log('Delete success: ' + JSON.stringify(result));
        })
        .catch(function(err) {
          console.log('Delete failed, see console,');
          console.warn(err);
        });



      // finally delete gallery itself
      // return this.galleryCollectionRef.doc(galleryID).delete().then(function() {
      //     console.log("Document successfully deleted!");
      // }).catch(function(error) {
      //     console.error("Error removing document: ", error);
      // });

    },

  },

  created() {

    this.galleryCollectionRef = this.$fire.firestore.collection(this.$globalvars.galleryCollection)

  },

}
