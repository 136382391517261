<template>
  <div>
    <svg style="vertical-align:top;" id="remix-this" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + slideWidth + ' ' + slideHeight" ref="svgObject" :style="{backgroundColor:bgColor}">

      <g :transform="'translate('+ (slideWidth*0.065) + ' ' + (slideHeight*0.065) + ')'">
        <svg :height="(slideHeight*0.87)" :width="(slideWidth*0.87)"  id="inner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + (slideWidth) + ' ' + (slideHeight)" preserveAspectRatio="xMidYMid meet" ref="svgObject">
            <image width="100%" height="100%" :xlink:href="src"/>
        </svg>
      </g>


      <g :transform="'scale(' + scaleFactor + ')'">
        <!-- Title -->
        <foreignObject :x="70" :y="70" width="1550" height="460" style="line-height:1.2em; font-family: BrysoCourier-Regular, Bryso Courier; overflow: hidden;"><div xmlns="http://www.w3.org/1999/xhtml" style="color:#fff;background-color:#de4500;display:inline-block;padding:25px;border-radius: 5px;"><span style="font-size:55px">{{title}}</span></div></foreignObject>
      </g>
        <!-- Copyright Statement -->
        <foreignObject :x="70*scaleFactor" :y="slideHeight-100*scaleFactor" width="950" height="460" style="line-height:1.2em; font-family: BrysoCourier-Regular, Bryso Courier; overflow: hidden;"><span xmlns="http://www.w3.org/1999/xhtml" style="white-space: pre-wrap;padding:10px 15px 5px 15px;color:#aaa;"><span :style="'font-size:'+35*scaleFactor+'px'">{{blurb}}</span></span></foreignObject>


      <g :transform="'translate('+ (slideWidth-70*scaleFactor) + ' ' + (slideHeight-70*scaleFactor) + ')'">

        <vt-watermark :transform="'scale(' + 0.3*scaleFactor + ')'"></vt-watermark>

      </g>
    </svg>
    <div class="flex-row d-flex flex-wrap align-items-center">
      <div class="p-1 ml-auto">Download Slide:</div>
      <div class="p-1">
        <select v-model="resolution">
          <option value="16:9">Widescreen (16:9)</option>
          <option value="16:10">Widescreen (16:10)</option>
          <option value="4:3">Standard (4:3)</option>
        </select>
      </div>
      <div class="p-1"><a ref="conPNGModal" @click="createPNG"><button id="exportPNGModal" type="button" class="btn-sm btn-primary btn"><span class="oi oi-data-transfer-download"></span> Download</button></a></div>
    </div>
  </div>
</template>

<script>

/*
TODO
Call it like this:

<slide
  title="The main thing is this."
  blurb="Copyright - Visual Thinkery Limited"
  :src="require('@/assets/myVisualCV1.png')"
  slideWidth="1920" slideHeight="1080" bgColor="#398274" />


*/

export default {
  name: 'Slide',
  components: {

  },
  props: {
    title: {type: String},
    src: {type: String},
    blurb: {type: String},
    bgColor: {type: String, default:"#fff"},
    slideWidth: {type:Number, default:1280},
    slideHeight: {type:Number, default:800},
    callToAction: {type: String, default:"Download Slide:"},
  },
  data () {
    return {
      resolution:"16:10",
    }
  },
  methods: {

  },
  watch: {
    resolution: function(res){
        if (res == "16:9"){
          this.slideWidth = 1920
          this.slideHeight = 1080
        } else if (res == "16:10"){
          this.slideWidth = 1920
          this.slideHeight = 1200
        } else {
          this.slideWidth = 1600
          this.slideHeight = 1200
        }
    }
  },
  computed: {
    ccLink: function () {
      return 'https://creativecommons.org/licenses/' + this.licence + '/4.0/'
    },
    scaleFactor: function() {
      return this.slideHeight/1080
    }
  },
  created: function(){

  },
  mounted: function(){

  }
}

</script>

<style>

</style>
