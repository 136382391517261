
// import firebase
//import { storageRef, modelCollectionRef, remixCollectionRef } from '~/plugins/firebase.js'


export const firebaseDocPagination =  {

  data () {
    return {

      // query properties
      qLimit: 10,
      qFilterProperty: "",
      qFilterOperator: "",
      qFilterValue: "",

      remixes: [], // bound array of remixes
      dataReady: false, // has the query been executed?
      arrLastDocInPage: [], // array of the last docs (creation field)
      currentPage:0, // the current page
      isFinalPage:false, // is this the final page?
    }
  },

  methods: {

    bindData: function (){

      // construct the query
      var query = {};

      if (this.qGalleryID){
        //console.log('Gallery!',this.$globalvars.galleryCollection)
        query = this.$fire.firestore.collection(this.$globalvars.galleryCollection).doc(this.qGalleryID).collection('remixes');
      } else {
        //console.log('no gallery')
        query = this.$fire.firestore.collection(this.$globalvars.remixCollection);
      }

      
      
      if (this.qFilterProperty != ""){
        // there's a filter - so add it.
         query = query.where(this.qFilterProperty, this.qFilterOperator, this.qFilterValue)
      }


      // query = query.where('templateID', 'in', ["hexquilt", "flipbook", "xrayspecs", "openbus", "cesi", ])
      // console.log()

      // add order
      query = query.orderBy("created", "desc")

      // where to start - find out from the currentPage
      if (this.currentPage > 0){
        //console.log('starting after this doc',this.arrLastDocInPage[this.currentPage-1])
        query = query.startAfter(this.arrLastDocInPage[this.currentPage-1])
      }

      // add limit
      query = query.limit(this.qLimit)
      //console.log('query',query)

      // bind the query to the remixes array
      this.$bind('remixes', query)
        .then((docs) => {
          //console.log(docs)

          // keep track of the last document for the current page
          if (docs){
            this.arrLastDocInPage[this.currentPage] = docs[docs.length-1].created;

            // is there more to get after this?
            var nextquery = query
              .startAfter(this.arrLastDocInPage[this.currentPage])
              .limit(1)
              .get()
              .then((nextdoc) => {
                //console.log('nextdoc',nextdoc)
                this.isFinalPage = nextdoc.empty;
              })
          }
          //console.log("last", this.lastVisible);
          //console.log(docs.length-1);
          this.dataReady = true
        })
        .catch((error) => {
          console.log('error in loading: ', error)
        })
        
    },

    nextPage: function(){
      // TODO: this could always end in a page with no remixes.
      if (this.remixes.length != 0){
        this.currentPage++;
        this.$unbind('remixes')
        this.bindData();
      }
    },

    prevPage: function(){
      if (this.currentPage > 0){
        this.currentPage--;
        this.$unbind('remixes')
        this.bindData();
      }
    },

  },


}
