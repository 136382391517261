<template>

        <svg width="100%" id="Equaliser" class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 76.8 600" preserveAspectRatio='xMinYMin'>
          <defs>
            <svg id="equaliser_bar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.8 758.3">
                <path id="bottom" d="M32.1,387.6c1.2-1.8,2.9-3,3.4-5.2h7.6c4.9,2.6,6.9,6.5,6.4,12.2-.3,3.5.1,7.1.1,10.6s.3,4.7-.9,6.8a5.5,5.5,0,0,0-.1,3.1,99.4,99.4,0,0,1-.8,32.2,4.5,4.5,0,0,0-.3,1.6c1.5,11.6,0,23.2-.4,34.8s.1,22-1.3,32.9a37.7,37.7,0,0,0,.2,4.7c-.6,12.5-1.1,25.1-1.7,37.6,0,1.9,0,3.9.1,5.8.3,10.2-1.1,20.2-1.1,30.3,0,3.6.2,7.1.3,10.6.3,5.6-1.2,11.3.5,16.9.7,2-.7,4.4-.7,6.8.1,8.9.5,18-.1,26.9-.4,5.8.6,11.6-.8,17.4-.4,1.7.3,3.5.6,5.2.8,5.9,2.2,12,1.8,17.8s1.5,10.1.8,15.3a16.3,16.3,0,0,0,1.6,9.2c2.9,6,1.7,12.1.2,18a37.4,37.4,0,0,0-1.3,9.4c-.1,8-2.2,10.2-10.3,9.8-1.6-2.6-4.9-3.8-6.3-7a8.6,8.6,0,0,1-1.3-3.9c-.1-12.2-.4-24.4-.3-36.7a159.9,159.9,0,0,0-1.3-19.6c-1-9.2-.5-18.4-.5-27.6,0-4.2-.1-8.5-.1-12.7,0-12.4-.1-24.8-.1-37.2a22.8,22.8,0,0,1,.6-6.4,8.2,8.2,0,0,0,.3-3.1c-1.2-12.8.2-25.5.3-38.3.1-8.1.3-16.3,1-24.4s.3-18.2,1.1-27.4c.4-5.2.7-10.5.8-15.8,0-13.1.6-26.2,1.2-39.3s.5-28.4.8-42.5c.1-5.7.3-11.3-1.2-16.9a10.5,10.5,0,0,1,.4-6.3A22.3,22.3,0,0,0,32.1,387.6Z" style="fill: #244857"/>
                <path id="middle" d="M2.2,318.8c6-1.1,11.3-.5,16.7-.4,11.9.4,23.8,1.2,35.7,1.2a66.3,66.3,0,0,0,10-.6,26.2,26.2,0,0,1,7.9,0c5.3,5.2,5.6,9.3,1.4,15.4-5.2,2.8-10.8,2-16.1,1.3-12.3-1.5-24.6-1-36.9-1.4-4-.2-8-.4-12-.3s-6.4-1.9-7.7-5.1S-.9,321.8,2.2,318.8Z" style="fill: #de4500"/>
                <path id="top" d="M42.3.1c3,2.3,4.8,4.5,5.3,8.5s.9,7.8,1.4,11.7c1.9,14.1,0,28.2.4,42.3.3,9.6.7,19.2.4,28.8-.3,13.3-.4,26.6-.8,39.8-.2,4.3-1.3,8.6-1.2,12.9a70.8,70.8,0,0,1-.4,11.3,76.3,76.3,0,0,0-.5,20.8,28.8,28.8,0,0,1-.9,10.9c-.8,2.9.3,5.9.4,8.9.5,12.6.8,25.2.4,37.9a40.1,40.1,0,0,1-.9,5.9c-1.3,6.2-.6,12.5-.8,18.7a5.3,5.3,0,0,0,.4,2.5c3,5.3,1.3,11.1,1.2,16.6s-5.3,7.9-10.2,6.4-8.1-4.2-7.9-10.8-.8-11.3-1.1-16.9a81.2,81.2,0,0,1,.9-14.8,154.1,154.1,0,0,0,2-25.3c-.2-7.7-.4-15.5-.8-23.3a144.9,144.9,0,0,1,.6-25.5c.7-6.1.4-12.3,1-18.4,1.3-14.1,1.1-28.3,2-42.4.8-12.3.6-24.5.4-36.8,0-5.6.7-11.3.3-16.8-.5-7.5.1-15-.8-22.4-.5-4.9-.1-9.7-.3-14.5a79.3,79.3,0,0,0-.7-8.4C31.3,2.6,33.5.1,38.6,0Z" style="fill: #323232"/>
            </svg>
          </defs>

          <g transform="translate(0 0)">
            <g v-for="(index,key) in order" :key="key">
              <use :transform="'translate(' + key*140 + ' ' + index*-4.1 +') scale(1.8)'" xlink:href="#equaliser_bar"></use>
            </g>
          </g>

        </svg>


</template>

<script>

/*
TODO
:width="stripLength"
transform="translate(0 0)"
<use transform="translate(0 0) scale(1.7)" xlink:href="#equaliser_bar"></use>
<use transform="translate(140 -120) scale(1.7)" xlink:href="#equaliser_bar"></use>
<use transform="translate(280 -420) scale(1.7)" xlink:href="#equaliser_bar"></use>

<transition-group name="flip-list" tag="g">
  <g v-for="(index,key) in order" v-bind:key="index">
    <g :transform="'translate(' + (key%5)*200 + ' ' + (Math.floor(key/5))*200 +')'">

      <g v-if="key!=12"  v-bind:class="{'square': !items[index].selected,'square-selected': items[index].selected, 'current-spin': index == currentSpin}" >

      </g>
    </g>
  </g>
</transition-group>

*/

export default {
  name: 'Equaliser',
  components: {

  },
  props: {
    //indicator: {type: Number},
  },
  data () {
    return {
      order: [0,20,30,40,50,60,100,90,80,70,40,30,40,20,40,50,55,65,70,90,80,70,50,45,35,25,15,40,50,60,50, 40, 30, 30, 40,60,80,90,90,80,70,40,30,40,20,40,50,55,65,70,90,80,70,50,45,35],
    }
  },
  methods: {

  },
  computed: {

  },
  created: function(){

  },
  mounted: function(){

  }
}

</script>

<style>

</style>
