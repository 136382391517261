<template>
  <div>
      <b-button v-b-toggle.help-video class="m-1"><font-awesome-icon :icon="['fab', 'youtube']" /> <span class="when-open">Close</span><span class="when-closed">Open</span> {{title}} </b-button>
      <b-collapse id="help-video">
        <div class="mt-2">
          <b-embed
            type="iframe"
            :aspect="aspect"
            :src="src"
            allowfullscreen
          ></b-embed>
        </div>
      </b-collapse>
    </div>
</template>

<script>
  export default {
    name: "EmbedVideo",
    props: {
      src: {
        type: String,
        default: ""
      },
      aspect: {
        type: String,
        default: "16by9"
      },
      title: {
        type: String,
        default: "Quick Tutorial"
      },
    },
    data() {
      return {

      }
    },
  }
</script>

<style scoped>

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

</style>