<template>
  <div>
    <b-overlay :show="!imgLoaded" rounded="sm" variant="dark">
      <picture v-if="!imgError">
        <source :srcset="getSrcSetURL(remixObj.id, remixUpdated, 'webp')" type="image/webp"> 
        <source :srcset="getSrcSetURL(remixObj.id, remixUpdated, 'jpeg')" type="image/jpeg" >
        <img style="width:100%" class="hanging-pic" tabindex="0"
          :id="'remix-'+ remixObj.id"
          :title="$globalvars.templates[remixObj.templateID].title + ' by ' + remixObj.creator.name + ' CC-BY-SA'" 
          :src="getImageURL(remixObj.id, remixUpdated, 'webp', '250x250')"
          @error="setDefaultImg" @load="setLoaded"></img>
      </picture>
      <picture v-else>
        <img style="width:100%" class="hanging-pic" tabindex="0"
          :id="'PNGremix-'+ remixObj.id"
          :title="'PNG - ' + $globalvars.templates[remixObj.templateID].title + ' by ' + remixObj.creator.name + ' CC-BY-SA'" 
          :src="remixObj.thumbnail + '&u=' + remixUpdated" @load="setLoaded"></img>
      </picture>
    </b-overlay>
    
    
  </div>
</template>


<script>
  import {imageFunctions} from '@/components/mixins/imageFunctions'
  export default {
    name: 'remixThumb',
    props: {
      remix: {
        type: Object,
        default: {}
      },

    },
    mixins: [imageFunctions],
    data() {
      return {
        showSRCSet: true,
        waiting: null,
        imgError: false,
        imgLoaded: false,
        remixUpdated: (this.remix.updated)? this.remix.created.seconds : 0, //created always exists
        remixObj:this.remix,
      }
    },
    
    methods: {
      
      setDefaultImg (event){
        this.imgError = true;
      },

      setLoaded (event){
        this.imgLoaded = true;
      },

      getImageURL: function(remixID, updated, type, size){
        // this.getImageURL(id, '0', 'WEBP','500x500')
        // type = PNG or WEBP
        // size = 'src' or 'srcset' <<<<<<<<<<<<<<
        let baseurl = this.$globalvars.rootStorageURL + this.$globalvars.thumbCollection;
        return baseurl + '%2F' + remixID + '_' + size + '.' + type + '?alt=media&u=' + updated
      },

      getSrcSetURL: function(remixID, updated, type){
        if (this.$globalvars.templates[this.remixObj.templateID].highRes){
          return this.getImageURL(remixID,updated,type, '500x500') + ' 125w,' 
            + this.getImageURL(remixID,updated,type, '1000x1000') + ' 250w'
        }else{
          return this.getImageURL(remixID,updated,type, '250x250') + ' 125w,' 
            + this.getImageURL(remixID,updated,type, '500x500') + ' 250w'
        } 
      },

      
    },
      
    watch: {
      'remix.updated': {
        deep: true,
        handler(newValue, oldValue) {
          // console.log('remix updated', newValue , oldValue)
          if (newValue != null){
            // need to delay the update - as it takes a few seconds for the image to be resized to webp etc
            setTimeout(
              ()=>{ this.remixUpdated = newValue.seconds }
            , 5000) ;
          }
        }
      }
    },
  }
</script>

<style scoped>

</style>