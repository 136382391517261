<template>

  <!-- this is the draggable group @mousedown.prevent="startMove" @touchstart.prevent="startMove" -->
  <g
    v-on="updatedImageObj.selected ? { mousedown: startMove, touchstart:startMove } : {}"
    id="bgImage">

    <g :filter="imageFilter">

      <!-- the image placeholder -->
      <g :transform="transformationStatement()">
        <image :width="updatedImageObj.width" :height="updatedImageObj.height"  :xlink:href="updatedImageObj.base64" :href="updatedImageObj.base64"></image>

        <!-- Crosshair
        <svg x=0 y=0 :width="updatedImageObj.width" :height="updatedImageObj.height"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 506.9 487.4"  v-if="updatedImageObj.selected">
          <g :fill="crosshairColour" style="">
            <path d="M300.6,126.8l-13-11.7L260.1,90.4c-5.7-5.1-14.2,3.3-8.5,8.5l23.1,20.8,10.2,9.1,5.7,5.2.8.7h-.2q-67.6,2.9-135.5,3c-.1-23.2-.4-46.3-.8-69.5-.3-14.9-.7-29.7-1.2-44.6,11.1,10.9,21.6,22.4,31.6,34.3,4.9,5.9,13.4-2.6,8.4-8.5-7.1-8.6-14.6-17-22.4-25-3.9-4.1-7.8-8-11.8-11.9S152,4.4,147.6,1.6c-9.8-6.1-15.9,6.8-20.2,13.4-6.2,9.4-11.8,19.1-17.1,29.1-3.6,6.8,6.8,12.9,10.4,6s8.8-15.9,13.6-23.7c2.4-3.7,4.8-7.5,7.4-11.1q2,61.2,2.2,122.4-36,0-72-.8c-18.7-.5-37.3-1.1-55.9-1.8,1.5-1.6,3.2-3.1,4.4-4.4l7.6-7.8c5.4-5.4,11.1-10.4,16.9-15.3s-2.6-13.4-8.4-8.5A268.2,268.2,0,0,0,15,118.9c-3.4,3.5-6.7,7.1-9.8,10.8S0,135.7,0,139.9c0,7.7,8,15.8,12.7,21.2a117,117,0,0,0,20,17.9c6.3,4.6,12.3-5.8,6.1-10.3a98.6,98.6,0,0,1-15.3-13.5c-2.3-2.6-4.6-5.2-6.6-7.9v-.2q63.5,2.6,127.1,2.6c0,21.9-.1,43.8-.5,65.7-.2,14.9-.5,29.7-.9,44.6l-16.9-17.7c-5.3-5.6-13.8,2.9-8.5,8.5,11.2,11.6,22.3,23.3,33.4,34.9,2,2.1,6.5,2.5,8.5,0l27.8-35.1c4.8-6-3.7-14.5-8.5-8.5l-24,30.2-.3-.4q1.6-61,1.7-122.2,36.9,0,73.7-.9c18.8-.5,37.6-1.1,56.3-1.9l-.7.8-8.8,9.3c-6.9,6.9-14,13.6-21.2,20.1s2.7,13.6,8.4,8.5c8.5-7.5,16.6-15.3,24.4-23.4,3.8-4,7.6-8,11.2-12.1s7.6-7.3,7.9-12.3S303.8,129.7,300.6,126.8Z"/>
          </g>
        </svg>
        -->
      </g>

    </g>


  </g>

</template>


<script>

//import {drag} from '@/components/mixins/drag'
export default {
  name: 'RemixBgImageInSituPlaceholder',
  components: {

  },
  //mixins: [drag],
  props: {

    imageObj: {
      type: Object
    },
    crosshairColour: {
      type: String,
      default: "#888888cc"
    },
    imageFilter: {
      type: String,
      default: ""
    }

  },
  data () {
    return {
      updatedImageObj: {
        // initial settings
        base64: require("@/assets/knob.png"),
        scale: 1,
        rotation: 0,
        x:0,
        y:0,
        centreX:0,
        centreY:0,
        width:0,
        height:0,
        selected:false,
      },

      // dragging variables
      moving: false,
      touch: false,
      transform: [],
      point: {},
      offsetX: 0,
      offsetY: 0,
      circlePos: {},
      elem: {},
      events: {},
    }
  },
  mounted () {

  },
  methods: {

    transformationStatement: function(){
      // This allows a thing to be scaled up centrally and then moved according to its location properties

      var rotateStatement = 'translate(' + this.updatedImageObj.width/2 + ' ' + this.updatedImageObj.height/2 + ') rotate(' + this.updatedImageObj.rotation + ') translate(' + this.updatedImageObj.width/-2 + ' ' + this.updatedImageObj.height/-2 + ')';
      var scaleStatement = 'translate(' + this.updatedImageObj.width/2 + ' ' + this.updatedImageObj.height/2 + ') scale(' + this.updatedImageObj.scale + ') translate(' + this.updatedImageObj.width/-2 + ' ' + this.updatedImageObj.height/-2 + ')';
      var moveStatement = 'translate(' + this.updatedImageObj.x + ' ' + this.updatedImageObj.y + ')';

      return moveStatement + ' ' + scaleStatement + ' ' + rotateStatement;
    },

    startMove(evt) {
      // prevent default scrolling behaviour
      evt.preventDefault();

      this.touch = (evt.type === "touchstart")
      if (!this.touch && evt.button !== 0) return;

      this.events = this.touch ? {
        move: "touchmove",
        stop: "touchend"
      } : {
        move: "mousemove",
        stop: "mouseup"
      }

      this.$set(this, 'elem', evt.currentTarget.closest("svg"))
      //console.log('elem',elem)
      this.$set(this,'point', this.elem.createSVGPoint())


      this.transform = this.elem.getScreenCTM().inverse()
      //console.log('transform', transform)
      this.circlePos = evt.currentTarget
      //console.log('circlePos', circlePos)
      //const getPos = this.touch ? getTouchPos : getMousePos

      this.getPos(evt,this.point)

      this.moving = true
      var newPt = this.point.matrixTransform(this.transform)

      // calculate the offset between the click and the elements stored position
      this.offsetX = newPt.x - this.updatedImageObj.x;
      this.offsetY = newPt.y - this.updatedImageObj.y;

      requestAnimationFrame(this.updateFn)
      this.moveFn(evt)

      this.elem.addEventListener(this.events.move, this.moveFn)
      this.elem.addEventListener(this.events.stop, this.stopFn)
    },

    updateFn() {

      //this.$emit('updateFn')

      if (this.moving) requestAnimationFrame(this.updateFn)

      // Map the screen pixels back to svg coords
      var newPt = this.point.matrixTransform(this.transform)

      const differenceX = newPt.x - this.updatedImageObj.x;
      const differenceY = newPt.y - this.updatedImageObj.y;

      this.$set(this.updatedImageObj,'x', newPt.x - this.offsetX);
      this.$set(this.updatedImageObj,'y', newPt.y - this.offsetY);

    },

    moveFn(evt) {
      //this.$emit('moveFn')
      this.getPos(evt, this.point)
    },

    stopFn(evt) {
      //this.$emit('stopFn')
      this.moving = false
      this.elem.removeEventListener(this.events.move, this.moveFn)
      this.elem.removeEventListener(this.events.stop, this.stopFn)
    },

    getPos(evt, point) {
      if (this.touch) {
        //getTouchPos
        point.x = (evt.touches[0].clientX)
        point.y = (evt.touches[0].clientY)

      }else{
        //getMousePos
        point.x = (evt.clientX)
        point.y = (evt.clientY)
      }
    }

  },

  created: function(){
    //console.log(this.imageObj)
    //this.updatedImageObj = this.imageObj;
  },

  mounted: function(){
    this.updatedImageObj = this.imageObj;
  },

  watch: {
      'this.updatedImageObj': function (val) {
        this.$emit('update:imageObj', this.updatedImageObj)
      },
      deep: true,
  },




}
</script>

<style scoped>


</style>
