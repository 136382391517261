<template>

    <div class="d-flex flex-row m-0 p-0" style="line-height:1em;">
      <div class="flex-grow-1" style="">
        <svg id="lhs" style="display:block" class="" width="100%" height="100%" viewBox="0 0 400 25" preserveAspectRatio="none">
          <defs>
            <pattern :id="'lgallery-'+id" x="100%" y="0" width="400px" height="25px" patternUnits="userSpaceOnUse">
              <!-- <svg x="0px" y="0px" width="400px" height="25px" viewBox="0 0 400 25"  preserveAspectRatio="xMinYMax slice"> -->

              <g class="background" :fill="backwall">
                <rect x="242" y="4.3" width="97.7" height="15.7"/>
              </g>
              
              <g class="rightwall" :fill="rightwall">
                <polygon points="47.2,4.3 47.2,20 175,25 175,0 		"/>
		            <polygon  points="339.8,4.3 339.8,20 400,25 400,0 		"/>
              </g>
              <g class="leftwall" :fill="leftwall">
                <polygon  points="242.1,4.3 242.1,20 175,25 175,0 		"/>
		            <polygon points="47.3,4.2 47.3,20 0,25 0,0 		"/>
              </g>
            
              <!-- </svg> -->
            </pattern>

          </defs>
          <rect x="0" y="0" width="100%" height="25px" :fill="'url(#lgallery-' + id + ')'"/>

          
        </svg>
      </div>
      <div class="pl-3 pr-3 pt-0 pb-0 small align-items-center d-flex" :style="{'background-color':backwall}" v-if="!!$slots.default"><div><slot></slot></div></div>
      <div class="flex-grow-1">
        <svg id="rhs" style="display:block" class=""  width="100%" height="100%" viewBox="0 0 400 25" preserveAspectRatio="none">
          <defs>
            <pattern :id="'rgallery-'+id" x="0" y="0" width="400px" height="100%" patternUnits="userSpaceOnUse">
              <!-- <svg x="0px" y="0px" width="400px" height="25px" viewBox="0 0 400 25"  preserveAspectRatio="none"> -->

              <g class="background" :fill="backwall">
                <rect x="242" y="4.3" width="97.7" height="15.7"/>
              </g>
              
              <g class="rightwall" :fill="rightwall">
                <polygon points="47.2,4.3 47.2,20 175,25 175,0 		"/>
		            <polygon  points="339.8,4.3 339.8,20 400,25 400,0 		"/>
              </g>
              <g class="leftwall" :fill="leftwall">
                <polygon  points="242.1,4.3 242.1,20 175,25 175,0 		"/>
		            <polygon points="47.3,4.2 47.3,20 0,25 0,0 		"/>
              </g>
            
              <!-- </svg> -->
            </pattern>




          </defs>
          <rect x="0" y="0" width="100%" height="100%" :fill="'url(#rgallery-' + id + ')'"/>

          
        </svg>
      </div>
    </div>

<!-- <div class="divider-grid" style="line-height:1.2em">
      <div class="">
        <svg id="lhs" class=""  preserveAspectRatio="none">
          <defs>
            <pattern :id="'lgallery-'+id" x="100%" y="0" width="400px" height="25px" patternUnits="userSpaceOnUse">
              <svg x="0px" y="0px" width="400px" height="25px" viewBox="0 0 400 25"  >

              <g class="background" :fill="backwall">
                <rect x="242" y="4.3" width="97.7" height="15.7"/>
              </g>
              
              <g class="rightwall" :fill="rightwall">
                <polygon points="47.2,4.3 47.2,20 175,25 175,0 		"/>
		            <polygon  points="339.8,4.3 339.8,20 400,25 400,0 		"/>
              </g>
              <g class="leftwall" :fill="leftwall">
                <polygon  points="242.1,4.3 242.1,20 175,25 175,0 		"/>
		            <polygon points="47.3,4.2 47.3,20 0,25 0,0 		"/>
              </g>
            
              </svg>
            </pattern>

          </defs>
          <rect x="0" y="0" width="100%" height="25px" :fill="'url(#lgallery-' + id + ')'"/>

          
        </svg>
      </div>
      <div class="pl-3 pr-3 pt-0 pb-0 small align-items-center d-flex" :style="{'background-color':backwall}" v-if="!!$slots.default"><div><slot></slot></div></div>
      
    </div> -->



</template>

<script>
import tinyColor from 'tinycolor2'
  export default {
    name: "gallerydivider",
    props: {
      color: {
        default: "#16a8e2",
        type: String
      },
      id: {
        default: "divider",
        type: String
      },
    },
    components: {
      tinyColor
    },
    data () {
      return {
        backwall: tinyColor(this.color).toHexString(),
        rightwall: tinyColor(this.color).lighten(10).toHexString(),
        leftwall: tinyColor(this.color).darken(10).toHexString(),
      }
    },
  }
</script>

<style scoped>

  .divider-grid{
    grid-template-columns: auto auto auto;
    /* grid-template-rows: fit-content(50px) fit-content(50px) fit-content(50px); */
    display: grid;
  }
</style>

