<template>
  <div>

    <div class="input-group" v-if="!ungroup">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon3">{{label}}:</span>
      </div>
      <input type="file" id="ImageURL"  name="ImageURL" class="form-control form-control-file" v-on:change="updateFile" value=""/>
    </div>

    <div v-else>
      <div class="d-flex">
        <div class="">{{label}}</div>
      </div>
      <input type="file" id="ImageURL"  name="ImageURL" class="form-control form-control-file" v-on:change="updateFile" value=""/>
    </div>

    <div class="d-flex">
      <div class="pt-2">Scale:</div>
      <div class="p-2 mr-auto"><input type="range" id="ImageScale" name="ImageScale" class="form-control form-control-range" v-model="updatedImageObj.scale" min="0" max="4" step="0.1" v-if="updatedImageObj.scale"/></div>
      <div class="pt-2 pb-2" style="text-align:right"><a ref="rotate" @click="rotateImage"><button id='rotateImage' class="btn btn-primary btn-sm"><span class="oi oi-loop-circular"></span><br/>Rotate</button></a></div>
    </div>


  </div>

</template>


<script>

export default {
  name: 'RemixBgImage',
  components: {

  },
  props: {
    label: {
      type: String
    },
    urlStream: {
      type: Boolean,
      default: true
    },
    binaryStream: {
      type: Boolean,
      default: false
    },
    imageObj: {
      type: Object
    },
    ungroup: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      updatedImageObj: {
        scale:0, // set default scale otherwise it complains...
      },
    }
  },
  mounted () {

  },
  methods: {
    rotateImage: function() {
      this.updatedImageObj.rotation = this.updatedImageObj.rotation + 90;
    },
    updateFile: function(e){
      //console.log('hello',e);

      var vm = this;
      //console.log(event.target.files[0]);
      if (this.urlStream) {
        this.readUrl(e.target, function(dataUrl) {
          console.log("file URL read");
          // Emit the number value through the input event
          vm.$emit('update',vm.imageObj);

        });
      }

      if (this.binaryStream) {
        this.readBinary(e.target, function(data) {
          console.log("file Binary read");
          // Emit the number value through the input event
          vm.$emit('update',vm.imageObj);

        });
      }

    },

    readUrl(input, callback) {
      var vm = this;
      console.log('input',input.files[0]);
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          console.log('e',e)
          vm.imageObj.base64 = e.target.result;
          vm.imageObj.name = input.files[0].name;



          //create an image so that we can get the width and height
          var image = new Image();

          image.src = e.target.result;

          image.onload = function() {
            vm.$set(vm.imageObj,'width',this.width);
            vm.$set(vm.imageObj,'height',this.height);

            // reset the scale of the new image
            vm.$set(vm.imageObj,'scale',1);

            // image co-ordinates are top left. Place the centre of the image on the coord (centreX,centreY)
            vm.$set(vm.imageObj,'x',vm.imageObj.centreX - this.width/2);
            vm.$set(vm.imageObj,'y',vm.imageObj.centreY - this.height/2);

          };


          //input.setAttribute("data-title", imgName);
          //console.log('read the URL',e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
      }
      reader.onloadend = function() {
        callback(reader.result);
      }
    },

    readBinary(input, callback) {
      var vm = this;
      //console.log(input.files[0]);
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          vm.imageObj.binary = e.target.result;
          vm.imageObj.name = input.files[0].name;

          //input.setAttribute("data-title", imgName);
          //console.log('read the Binary',e.target.result);
        }
        reader.readAsBinaryString(input.files[0]);
      }
      reader.onloadend = function() {
        callback(reader.result);
      }
    },


  },
  created: function(){
    //console.log(this.imageObj)
    this.updatedImageObj = this.imageObj;
  }

}
</script>

<style scoped>


</style>
