export const imageFunctions = {
    
  methods: {
    
    
    getImageURL: function(remixID, updated, type, size){
      // this.getImageURL(id, '0', 'WEBP','500x500')
      // type = PNG or WEBP
      // size = 'src' or 'srcset' <<<<<<<<<<<<<<
      let baseurl = this.$globalvars.rootStorageURL + this.$globalvars.thumbCollection;
      return baseurl + '%2F' + remixID + '_' + size + '.' + type + '?alt=media&u=' + updated
    },
    
  },
    
}

