//import Vuex from 'vuex'

//import * as firebase from 'firebase/app'
//import { authRef } from '~/plugins/firebase.js'

export const strict = false;

// STATE
export const state = () => ({
  user: null,
  isAdmin: false,
  userTier: 0,
  pGalleries: [],
  loading: false,
  error: null
})

// MUTATIONS
export const mutations = {
  // ON_AUTH_STATE_CHANGED_MUTATION: (state, { authUser, claims }) => {
  //   console.log('onAuthStateChanged Mutation')
  //   console.log('authUser:',authUser)
  //   if (!authUser) {
  //     // claims = null
  //     // perform logout operations
  //     //this.$fire.auth.signOut()
  //
  //   } else {
  //     // Do something with the authUser and the claims object...
  //
  //     console.log('Adding Custom Claims...')
  //     console.log(this);
  //     //var fullUser = this.$fireModule.functions.httpsCallable('addCustomClaims');
  //
  //     //console.log('fullUser:',fullUser);
  //
  //
  //
  //     const newUser = {
  //       id: authUser.uid,
  //       name: authUser.displayName,
  //       email: authUser.email,
  //       photoUrl: authUser.photoURL,
  //       providerId: authUser.providerData[0].providerId,
  //       //isAdmin: claims.custom_claim,
  //       //username: user.providerData.username,
  //     }
  //     state.user = newUser;
  //   }
  // },
  setUser (state, payload) {
    //console.log ('just set user with payload:',payload)
    state.user = payload
  },
  setAdmin (state, payload) {
    state.isAdmin = payload
  },
  setPGalleries (state, payload) {
    state.pGalleries = payload
  },
  setTier (state, payload) {
    state.userTier = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

// ACTIONS
export const actions = {
  async nuxtServerInit({ dispatch, commit }, { res }) {
    //console.log('locals',res.locals)
    if (res && res.locals && res.locals.user) {
      const { allClaims: claims, idToken: token, ...authUser } = res.locals.user

      
  
      await dispatch('onAuthStateChangedAction', {
        authUser,
        claims,
        token
      })
  
      // or
  
      // commit('ON_AUTH_STATE_CHANGED_MUTATION', { authUser, claims, token })
    }
  },

  async onAuthStateChangedAction({ commit, dispatch }, { authUser, claims }) {
    //console.log('onAuthStateChanged Action')
    if (!authUser) {
      //console.log('should I logout here?')
      claims = null
      // Perform logout operations
      // await dispatch('logout')
      // return
    } else {

      // Do something with the authUser and the claims object...
      //console.log('Set AuthUser:',authUser)
      //console.log('Claims:',claims)
      //console.log('state:',state)
      let prov = ''
      if (authUser.providerData[0]){
        if (authUser.providerData[0].providerId == 'twitter.com'){
          prov = 'X/Twitter'
        }else if (authUser.providerData[0].providerId == 'google.com'){
          prov = 'Google'
        }
      } else {
        prov = 'Anonymous'
      }


      // need to do this so that the state is properly set if the user opens a new tab
      const newUser = {
        id: authUser.uid,
        name: authUser.displayName,
        email: authUser.email,
        photoUrl: authUser.photoURL,
        providerId: prov,
        isAnonymous: authUser.isAnonymous,
      }

      commit('setUser', newUser);

      // Test for the existance of claims
      //if (!claims.hasOwnProperty('admin')){
        //custom claims don't exist for this user yet, so add them
        console.log('Adding Custom Claims...')
        var addCustomClaims = this.$fire.functions.httpsCallable('addCustomClaims');
        addCustomClaims()
          .then((result) => {
            // Read result of the Cloud Function.
            //console.log('result of function:',result)

            this.$fire.auth.currentUser.getIdTokenResult(true) // 1 
              .then((authUserClaims) => {
              commit('setAdmin', authUserClaims.claims.admin);
              commit('setTier', authUserClaims.claims.userTier);
              commit('setPGalleries', authUserClaims.claims.pGalleries);
              //console.log(authUserClaims)
            })
            .catch((error) => {
              console.log(error);
            });

          })
          .catch((error) => {
            // Getting the Error details.
            console.log('function error:',error)
          });
      //}else{
      //  commit('setAdmin', claims.admin);
      //  commit('setTier', claims.userTier);
      //}


    }
  },

  // Store action called nuxtServerInit:
  // async nuxtServerInit({ dispatch, commit }, { res }) {
  //   console.log('nuxtServerInit Action')
  //   if (res && res.locals && res.locals.user) {
  //     const { allClaims: claims, idToken: token, ...authUser } = res.locals.user
  //
  //     await dispatch('onAuthStateChangedAction', {
  //       authUser,
  //       claims,
  //       token
  //     })
  //
  //   }
  // }





  signUserUp ({commit}, payload) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.createUserWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
            providerId: user.providerId,
            isAnonymous: false,
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserIn ({commit}, payload) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
            providerId: 'Email',
            isAnonymous: false,
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInGoogle ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithPopup(new this.$fireModule.auth.GoogleAuthProvider())
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.user.uid,
            name: user.user.displayName,
            email: user.user.email,
            photoUrl: user.user.photoURL,
            providerId: 'Google',
            isAnonymous: false,
          }
          commit('setUser', newUser)
          //console.log('user dump',user)
          // fetch the photoURL as we'll need to display it later
          fetch(user.user.photoURL, { mode: 'no-cors'});
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInFacebook ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithPopup(new this.$fireModule.auth.FacebookAuthProvider())
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
            providerId: 'Facebook',
            isAnonymous: false,
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInGithub ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    this.$fire.auth.signInWithPopup(new this.$fireModule.auth.GithubAuthProvider())
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
            providerId: 'Github',
            isAnonymous: false,
          }
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },




  signUserInTwitter ({commit}) {
    commit('setLoading', true)
    commit('clearError')
    return new Promise((resolve, reject) => {

      this.$fire.auth.signInWithPopup(new this.$fireModule.auth.TwitterAuthProvider())
        .then(
          user => {
            commit('setLoading', false)

            const newUser = {
              id: user.user.uid,
              name: user.user.displayName,
              email: user.user.email,
              photoUrl: user.user.photoURL,
              username: user.additionalUserInfo.username,
              providerId: 'X/Twitter',
              isAnonymous: false,
            }
            commit('setUser', newUser)
            //console.log('set new user',newUser)
          }
        )
        .catch(
          error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
      resolve()
    })
  },

  signUserInAnonymous ({commit},payload) {
    commit('setLoading', true)
    commit('clearError')
    return new Promise((resolve, reject) => {

      this.$fire.auth.signInAnonymously()
        .then(
          user => {
            commit('setLoading', false)
            //console.log('fire:',this.$fire)
            // update the display name 
            this.$fire.auth.currentUser.updateProfile({displayName:payload.displayName})
            // .then(() => {
            //   return this.$fire.auth.currentUser.updateEmail(payload.email)
            // })
            // .catch(error => {
            //   console.log(error)
            // })
            .then(() => {
              const newUser = {
                id: user.user.uid,
                name: payload.displayName,
                email: payload.email,
                photoUrl: user.user.photoURL,
                providerId: 'Anonymous',
                isAnonymous: true,
                //providerId: user.additionalUserInfo.providerId,
              }
              commit('setUser', newUser)
              //console.log('user dump', user)
            })
          }
        )
        .catch(
          error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
      resolve()
    })
  },


  autoSignIn ({commit}, payload) {
    commit('setUser', {
      id: payload.uid,
      name: payload.displayName,
      email: payload.email,
      photoUrl: payload.photoURL
    })
  },

  resetPasswordWithEmail ({ commit }, payload) {
    const { email } = payload
    commit('setLoading', true)
    this.$fire.auth.sendPasswordResetEmail(email)
    .then(
      () => {
        commit('setLoading', false)
        console.log('Email Sent')
      }
    )
    .catch(
      error => {
        commit('setLoading', false)
        commit('setError', error)
        console.log(error)
      }
    )
  },
  
  logout ({commit}) {
    this.$fire.auth.signOut()
    commit('setUser', null)
    commit('setAdmin', false)
    commit('setTier', 0)
    commit('setPGalleries', [])
  },
  clearError ({commit}) {
    commit('clearError')
  },
  setError ({commit}, payload) {
    commit('setError', payload)
  }
}

export const getters = {
  user (state) {
    return state.user
  },
  userID (state) {
    if (state.user) {
      return state.user.id
    }else{
      return ""
    }
  },
  userName (state) {
    if (state.user) {
      return state.user.name
    }else{
      return ""
    }
  },
  provider (state) {
    if (state.user) {
      return state.user.providerId
    }else{
      return ""
    }
  },
  isLoggedIn (state) {
    return state.user != null
  },
  isAnonymousLoggedIn (state) {
    if (state.user) {
      return state.user.isAnonymous
    }else{
      return false
    }
  },
  isAdmin (state) {
    return state.isAdmin
  },
  isMember (state) {
    return (state.userTier > 0)
  },
  pGalleries (state) {
    return (state.pGalleries)
  },
  maxGalleries (state) {
    // - Max Private Galleries
    if (state.userTier == 0 && state.user != null){
      return 0
    } else if (state.isAdmin){
      return 10
    } else if (state.userTier == 1){
      return 3
    } else if (state.userTier == 2){
      return 10
    } else if (state.userTier == 3){
      return 50
    } else {
      return 0
    }
  },
  maxContributors (state) {
    // - Max Contributors
    if (state.userTier > 0){
      return 100
    }else{
      return 0
    }
  },
  maxRemixes (state) {
    // - Max Remixes
    if (state.userTier == 0 && state.user != null){
      return 10
    } else if (state.userTier == 1){
      return 100
    } else if (state.userTier == 2){
      return 1000
    } else if (state.userTier == 3){
      return 10000
    } else {
      return 0
    }
  },
  allowCustomCode (state) {
    // - Allowed to set custom code
    if (state.isAdmin){
      return true
    }else{
      return false
    }
  },
  allowCustomPrompt (state) {
    // - Allowed to set custom prompt
    if (state.userTier >= 2 || state.isAdmin){
      return true
    }else{
      return true // turned on for everyone to begin with
    }
  },
  allowEditRemix (state) {
    // - Allowed editing remix
    if (state.user != null){
      return true
    }else{
      return false
    }
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// - Max No Contributors
// - Max No Remixes
// - Custom PG Code
// - Custom Prompt
// - Edit Remix
