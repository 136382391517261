<template>
  <div class="" style="background-color:#000000">
    <gallery-divider  id="bottom" color="#333333" style="transform: translateY(-50%) scale(1,1);transform-origin: top;max-height:20px"></gallery-divider>
    <!-- compact footer -->
    <div v-if="compact" class="container-fluid d-flex" id="compactfooter">
      <nuxt-link to="/"><img width="150" src="@/assets/remixer_long_logo.svg" alt="Remixer"></nuxt-link>
      <p class="text-muted ml-3">The Fabulous Remixer Machine is brought to you by <a href="https://visualthinkery.com">Visual Thinkery</a>.</p>
    </div>
    <!-- normal footer -->
    <div v-if="!compact" class="container-fluid" >
      <div id="footer" class="container" style="margin-top:50px">
        <div class="row pb-5">
          <div class="col-md">
            <a href="https://www.patreon.com/remixer"><img src="/become_a_member.png" width="100%" class="mb-4" alt="Become a member"/></a>
          </div>
          <div class="col-md">
            <h4>Become a Member</h4>
            <p class="text-muted">Support the Remixer Machine on <a href="https://www.patreon.com/remixer">Patreon</a> 
              in order to create your own private remix galleries. Get support, inside remixer news and first access to new remixer apps!</p>
            <a href="https://visualthinkery.com"><img width="130px" class="float-right" src="@/assets/VT logo final_reverse.svg" alt="Visual Thinkery"></a>
          </div>

           
            
            
            
            
            

        </div>
        <!-- <div class="d-flex flex-row pb-4 justify-content-end">
          <p>The Fabulous Remixer Machine is brought to you by <a href="https://visualthinkery.com">Visual Thinkery</a></p>.
          <a href="https://visualthinkery.com"><img width="130px" class="float-right" src="@/assets/VT logo final_reverse.svg" alt="Visual Thinkery"></a>
        </div> -->
      </div>
    </div>
  </div>
</template>


<script>

/*
TODO


*/
import equaliser from '@/components/layout/equaliser.vue'
export default {
  name: 'AppFooter',
  components: {
    Equaliser: equaliser,
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  methods: {

  },
  created: function(){

  },
  mounted: function(){
    //console.log(this.$route);
  }
}

</script>


<style>


</style>
