<template>
<div id="appHeader" style="background-color:#000" class="">
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col p-0">

          <b-navbar toggleable="md" type="dark" class="pb-0">

            <b-navbar-brand><nuxt-link to="/"><img width="200" src="@/assets/remixer_long_logo.svg" alt="Remixer"></nuxt-link></b-navbar-brand>

            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

            <b-collapse is-nav id="nav_collapse">

              <b-navbar-nav>
                <b-nav-item :active="$route.name == 'index'" to="/">Home</b-nav-item>
                <b-nav-item :active="$route.name == 'galleries'" to="/galleries">Galleries</b-nav-item>
                <b-nav-item :active="$route.name == 'remixes'" to="/remixes">Remixes</b-nav-item>
                <b-nav-item :active="$route.name == 'members'" to="/members">Members</b-nav-item>
                <b-nav-item :active="$route.name == 'info'" to="/info">About</b-nav-item>
              </b-navbar-nav>


              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">

                <b-nav-item-dropdown right>
                  <template slot="button-content">
                    <span v-if="$store.getters.isLoggedIn && !$store.getters.isAnonymousLoggedIn"><img :src="$store.state.user.photoUrl" width="25" class="border border-primary rounded" ref="profilePic"></span>
                    <span v-else><img src="/icons/profile.svg" width="25" class="border border-primary rounded" ref="profilePic"></span>
                  </template>

                  <b-dropdown-header>
                    <client-only>
                      <div v-if="$store.getters.isLoggedIn">
                        <div>
                          {{$store.state.user.name}}
                          <font-awesome-icon v-if="$store.getters.provider=='X/Twitter'" size="1x" :icon="['fab', 'x-twitter']" />
                          <font-awesome-icon v-if="$store.getters.provider=='Google'" size="1x" :icon="['fab', 'google']" />
                          <font-awesome-icon v-if="$store.getters.provider=='Anonymous'" size="1x" :icon="['fa', 'user']" />
                        </div>
                        <div>
                          <nuxt-link :to="{name: 'profile'}">My profile</nuxt-link>
                        </div>
                      </div>
                      <div v-else>Sign in:</div>
                    </client-only>
                  </b-dropdown-header>



                  <!-- <b-dropdown-header v-if="$store.getters.isLoggedIn">
                    {{$store.state.user.name}}
                    <span v-if="$store.state.user.providerId=='twitter.com'"><font-awesome-icon size="1x" :icon="['fab', 'x-twitter']" /></span>
                    <span v-if="$store.state.user.providerId=='google.com'"><font-awesome-icon size="1x" :icon="['fab', 'google']" /></span>

                  </b-dropdown-header>
                  <b-dropdown-header v-else>Sign in:</b-dropdown-header> -->


                  <b-dropdown-item @click="$store.dispatch('signUserInTwitter')" v-show="!$store.getters.isLoggedIn"><font-awesome-icon size="1x" :icon="['fab', 'x-twitter']" /> Twitter</b-dropdown-item>
                  <b-dropdown-item @click="$store.dispatch('signUserInGoogle')" v-show="!$store.getters.isLoggedIn"><font-awesome-icon size="1x" :icon="['fab', 'google']" /> Google</b-dropdown-item>

                  <b-dropdown-item @click="$store.dispatch('logout')" v-show="$store.getters.isLoggedIn">Signout</b-dropdown-item>
                </b-nav-item-dropdown>

              </b-navbar-nav>

            </b-collapse>
          </b-navbar>

        </div>
      </div>
    </div>
  </div>
  <gallery-divider  id="top" color="#333333" style="transform: translateY(50%) scale(1,1);transform-origin: top;max-height:20px; z-index:1; position:relative"></gallery-divider>
</div>
</template>

<script>

/*
TODO


*/
//import { auth,twitterProvider } from '~/plugins/firebase.js'
export default {
  name: 'AppHeader',
  props: {
    title: String,
  },
  components: {

  },
  data () {
    return {
      //profileImage:null,
    }
  },
  methods: {
    // async fetchProfileImage() {
    //   const response = await fetch('https://lh5.googleusercontent.com/-33V1nwxeghQ/AAAAAAAAAAI/AAAAAAAAAWA/pwf_mMNte8g/photo.jpg');
    //   this.profileImage = await response;
    // },

  },


  created: function(){

  },
  mounted: function(){
    //this.fetchProfileImage()
  }
}

</script>


<style>



</style>
