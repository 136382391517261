<template>
<div class="remixer-background">

  <app-header></app-header>

  <div class="container-fluid">
    <div class="container">
      <div class="row align-items-top" style="margin-top:0px;">

        <!-- REMIX -->
        <div class="col-xs col-lg mt-4 mb-4" style="text-align:left">

          <b-card
            class="p-2"
            bg-variant="dark"
            border-variant="transparent"
            no-body
          >
            <slot name="panel2"></slot>
          </b-card>

          <div class="mt-3"><slot>Main App - nothing to show</slot></div>



          <div class="row m-2">
            <!-- CC LIcence -->
            <div class="col"><cc-licence licence="by-sa" :name="this.appSettings.title" :remix="((appRemix != null) && ('creator' in appRemix)) ? appRemix.creator.name : ''"></cc-licence></div>
          </div>


        </div>

        <!-- SIDEBAR -->
        <div class="col-xs col-lg-4 mt-4 mb-4 d-flex flex-column-reverse flex-lg-column">


          <b-card
            class="mb-2"
            bg-variant="dark"
            border-variant="transparent"
          >

            <h2>{{this.appSettings.title}} <span style="font-size:0.5em" class="badge badge-info" v-if="this.appSettings.status=='dev'">BETA</span></h2>
            {{this.appSettings.description}}</br></br>

            <!-- Back this on Patreon -->
            <div class="mt-2 mb-2 d-flex align-items-center"  v-if="!this.appSettings.patronage">
              <div class="pr-2"><a href="https://patreon.com/remixer" target="_blank"><font-awesome-icon   :icon="['fab', 'patreon']" /></a></div>
              <div class="small"><a href="https://patreon.com/remixer" target="_blank">Sponsor this Remixer</a></div>
            </div>



            <!-- Description SLOT-->
            <slot name="panel1">panel 1 - nothing to show</slot>

          </b-card>

          
          

            <save-and-share :filenameBase="this.appSettings.title"></save-and-share>




        </div>

      </div>
    </div>
  </div>    

  <div class="" style="background-color: #111111;">
    <gallery-divider id="middle" color="#de4500" style="transform: translateY(-50%) scale(1,1);transform-origin: top;max-height:40px; position: relative; z-index:1"><span style="font-size:120%;font-weight:800;line-height:1.2em">REMIX&nbsp;GALLERY</span></gallery-divider>
  </div>

  <div class="container-fluid" style="background-color: #111111;">

    <!-- OTHER REMIXES -->
    <div class="row" style="padding-bottom:70px;background-color: #111111;" >
      <div class="col">

        <!-- <b-form-group>
          <b-form-radio-group id="btnradios1"
                              buttons
                              button-variant="outline-primary"
                              v-model="remixView"
                              :options="remixViewOptions"
                              name="remixViewSelect" />
        </b-form-group>

        <div v-if="remixView=='list'">
          <remix-snapshots class="half-width" filterProperty="templateID" filterOperator="==" :filterValue="appSettings.templateID" :limit="12"></remix-snapshots>
        </div>
        <div v-else-if="remixView=='graph'">
          <remix-snapshots-visual class="half-width" filterProperty="templateID" filterOperator="==" :filterValue="appSettings.templateID" :limit="50"></remix-snapshots-visual>
        </div> -->
        <div class="d-flex align-items-center mt-2 mb-2" >
          <div class="mr-2" style="font-weight:600">Click on a remix to see more details.</div>
          
          <div class="ml-auto" ><b-form-select v-model="remixesPerPage" :options="remixesPerPageOptions" style="min-width:75px" class="primary"></b-form-select></div>
          <div class="ml-2">per&nbsp;page</div>
        </div>
        
          
          
        <remix-grid filterProperty="templateID" filterOperator="==" :filterValue="appSettings.templateID" :limit="remixesPerPage"></remix-grid>

      </div>
    </div>

  </div>


<!-- Desktop footer -->
  <app-footer class=""></app-footer>
  <!-- <div class="navbar-dark d-none d-sm-block" style="background-color:#000000;z-index:1031">
    <div class="container">
      <div class="row align-items-center pt-2 pb-2">

          <div class="col-auto mr-auto">
            <a href="https://visualthinkery.com"><img height="30" src="@/assets/VT logo final_reverse.svg" alt="Visual Thinkery"></a>
          </div>

      </div>
    </div>
  </div> -->

  <!-- Mobile footer -->
  <!-- <div class="fixed-bottom navbar-dark  d-sm-none" style="background-color:#000000;z-index:1031">
    <div class="container">
      <div class="row align-items-center pt-2 pb-2">

          <div class="col-auto mr-auto">
            <a href="https://visualthinkery.com"><img height="30" src="@/assets/VT logo final_reverse.svg" alt="Visual Thinkery"></a>
          </div>


          <div class="col-auto">
            <button @click="showSaveShareModal" class="btn btn-primary">Publish</button>
          </div>

      </div>
    </div>
  </div> -->


</div>
</template>




<script>

/*
TODO


*/

export default {
  name: 'reminMinLayout',
  components: {
  },
  inject: ['appThings','appRemix','appSettings'],
  props: {

  },
  data () {
    return {
      blnShowModalSettings: true,
      blnEmbed: (this.$route.query.embed) ? true : false,
      remixView: 'list',
      remixViewOptions: [
        { text: 'List', value: 'list' },
        { text: 'Graph', value: 'graph' },
      ],
      remixesPerPageOptions : [12,24,48,96],
      remixesPerPage : 12,
    }
  },

  methods: {
    showSaveShareModal () {
      this.$root.$emit('showSaveAndShareModal')
    }

  },

}

</script>

<style lang="css" scoped>

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 999;
    color: #fff;
}

.icon {
  /*width:100%;*/
}
.icon path {
  fill:#fff;
}

@media screen and (max-width: 768px) {
    .display-4 {
        font-size:2.5rem;
    }
}

/* BrysoLetterGothicStd */
@font-face {
  font-family: BrysoRemixer-Block;
  src: url('/BrysoRemixer-Block.woff');
}

.remixer {
  font-family: BrysoRemixer-Block;
}




.fa-beat {
  animation:fa-beat 5s ease 5s 2;
}
@keyframes fa-beat {
  0% {
    transform:scale(1);
    color:inherit;
  }
  5% {
    transform:scale(1.25);
    color:#fff;
  }
  20% {
    transform:scale(1);
    color:inherit;
  }
  30% {
    transform:scale(1);
    color:inherit;
  }
  35% {
    transform:scale(1.25);
    color:#fff;
  }
  50% {
    transform:scale(1);
    color:inherit;
  }
  55% {
    transform:scale(1.25);
    color:#fff;
  }
  70% {
    transform:scale(1);
    color:inherit;
  }
}





</style>
