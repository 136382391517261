<template>
  <foreignObject><div style="width:100%;height:100%;display:flex;"><div xmlns="http://www.w3.org/1999/xhtml" :style="updatedDivStyle" :class="divClass" contenteditable @blur="updateText" ref="remixTextDiv"></div></div></foreignObject>

</template>

<script>
export default {
  name: 'RemixTextInSitu',
  components: {
  },
  props: {
    textObj: {
      type: Object
    },
    divStyle: {
    },
    divClass: {
    }
  },
  data () {
    return {
      updatedTextObj: this.textObj,
      updatedDivStyle: this.divStyle,
    }
  },

  methods: {

    updateText: function (event) {

      // Copy the edited innerText into the TextObj
      this.$set(this.updatedTextObj, 'text', event.target.innerText);

      // update the div - this will remove any html tags that have been pasted
      this.$refs.remixTextDiv.innerHTML = event.target.innerText;

      // Emit update event
      this.$emit('update',this.updatedTextObj);
    },

  },
  
  watch: {
  	divStyle: function(newVal, oldVal) { // watch it
      this.updatedDivStyle = newVal
    }
  },
  mounted: function() {
    this.$refs.remixTextDiv.innerText = this.textObj.text;
  },


}
</script>
