<template>

    <div v-if="remixes.length > 0">


      <div class="remix-grid" style="align-self:center">
        <!-- style="text-align:center" -->
        <!-- <div  :style="{ 'grid-column': 'span ' + $globalvars.templates[remix.templateID].colSpan, 'grid-row': 'span ' + $globalvars.templates[remix.templateID].rowSpan }" v-for="(remix, idx) in remixes" :key="idx" v-if="$globalvars.templates[remix.templateID].status =='live'"> -->
        <div class="remix-item"  v-for="(remix, idx) in remixes" :key="remix.id" v-if="$globalvars.templates[remix.templateID].status =='live'">
            <!-- <img :id="'remix-'+idx" tabindex="0" style="width:100%" class="hanging-pic" :title="$globalvars.templates[remix.templateID].title + ' by ' + remix.creator.name + ' CC-BY-SA'" :src="remix.thumbnail">
            <br/> -->
            <!-- <b-img-lazy :id="'remix-'+idx" tabindex="0" style="width:100%" class="hanging-pic" :title="$globalvars.templates[remix.templateID].title + ' by ' + remix.creator.name + ' CC-BY-SA'" :src="(remix.updated) ? remix.thumbnail + '&u=' + remix.updated.seconds : remix.thumbnail"></b-img-lazy> -->
            <remix-thumb :id="'remixpic-'+remix.id" :remix="remix" :key="remix.id"/>
            <!-- <div id="" class="d-flex align-items-center " style="line-height:1em;">

            </div> -->

            <!-- :show.sync="popoverShow"
                @show="onShow"
                @shown="onShown"
                @hidden="onHidden" -->

            <b-popover
                :target="'remixpic-'+remix.id"
                triggers="click blur"
                placement="auto"
                container="my-container"
                ref="popover"
                custom-class="remix-popover"

            >
                <template #title>
                    <span class="text-dark"><img width="22" src="/favicon.svg" alt="Remixer"> by {{remix.creator.name}}</span>
                </template>

                <p class="text-dark" v-if="'comment' in remix">{{remix.comment}}</p>



                <div class="text-dark small">
                    <font-awesome-icon :icon="['fab', 'creative-commons-sa']" />
                    {{$globalvars.templates[remix.templateID].title}} by
                    {{remix.creator.name}} is licenced <span style="white-space:nowrap">CC-BY-SA 4.0</span><br/>
                    
                    <!-- Created: {{new Date(remix.created.seconds*1000).toLocaleDateString("en-GB", { year: 'numeric', month: 'long', day: 'numeric' })}} -->

                </div>

                <!-- Allow delete if Admin, Curator or Remix Owner -->
                <div>
                    <hr class="mt-2 mb-2">
                    <div>
                        <b-button v-if="($store.getters.isAdmin || ($store.getters.userID == remix.creator.id) || ($store.getters.userID == appGallery.ownerID))"  @click.prevent="showDeleteModal(idx)" size="sm" variant="danger"><font-awesome-icon  size="xs" :icon="['fas', 'trash-alt']" /></b-button>
                        <b-button  :to="{name: 'r-rid-gid', params: { rid:remix.id, gid:remix.galleryID }}" size="sm" variant="primary">View</b-button>
                    </div>
                </div>

                <!-- <button @click.prevent="showDeleteModal(idx)"  class="btn btn-primary" v-if="($store.getters.isAdmin || ($store.getters.isLoggedIn && ($store.getters.userID == remix.creator.id)))"><font-awesome-icon  size="xs" :icon="['fas', 'trash-alt']" /></button> -->


            </b-popover>

        </div>

        <b-modal  id="modal-delete"
                  centered
                  title="Delete Remix"
                  body-bg-variant="dark"
                  header-bg-variant="dark"
                  footer-bg-variant="dark"
                  :ok-disabled="isGalleryPrompt"
                  ok-title="DELETE"
                  cancel-title="CANCEL"
                  ok-variant="danger"
                  @ok="removeRemix(remixes[selectedRemix].id, remixes[selectedRemix].galleryID)">
          <!-- <div style="text-align:center">
            <img style="max-height:250px;" class=""  :src="(remixes[selectedRemix].updated) ? remixes[selectedRemix].thumbnail + '&u=' + remixes[selectedRemix].updated.seconds : remixes[selectedRemix].thumbnail">
          </div> -->
          <p v-if="isGalleryPrompt">
            This remix is currently pinned as the prompt for the Gallery. Please unpin it before deleting.</p>
          <p v-else>
            Are you sure you want delete this remix?
          </p>
        </b-modal>

      </div>



      <div v-if="!noPaginate" class="mt-2 mb-2 d-flex align-items-center justify-content-end">
        <div class=""><button @click="prevPage" class="btn btn-primary btn-sm" :disabled="!(this.currentPage > 0)"><font-awesome-icon  size="lg" :icon="['fas', 'arrow-circle-left']" /></button></div>
        <div class="ml-2 mr-2">Page {{(currentPage+1)}} ({{noFirstRemix}}-{{noLastRemix}})</div>
        <div class=""><button @click="nextPage" class="btn btn-primary btn-sm float-right" v-if="(this.remixes.length > 0 && !this.isFinalPage)" ><font-awesome-icon  size="lg" :icon="['fas', 'arrow-circle-right']" /></button></div>
      </div>
    </div>
    <div v-else-if="!dataReady" class="d-flex flex-wrap align-items-center justify-content-center m-2">
      <div><span class="text-muted">Fetching remixes...</span></div>
    </div>
    <div v-else-if="remixes.length == 0 && dataReady" class="d-flex flex-wrap align-items-center justify-content-center m-2">
      <div class="mr-2"><font-awesome-icon style="color:#555555" size="2x" :icon="['fas', 'sad-tear']" /></div>
      <div><span class="text-muted">There are no remixes of this just yet. Why not publish one?</span></div>
    </div>


</template>

<script>


import { dbFunctions } from '~/components/mixins/dbFunctions.js'
import { firebaseDocPagination } from '~/components/mixins/firebaseDocPagination.js'

export default {
  name: 'RemixGrid',
  components: {

  },
  mixins: [dbFunctions,firebaseDocPagination],
  inject: ['appGallery'],
  props: {
    limit: {
      type: Number,
      default: 10
    },
    filterProperty: {
      type: String,
      default: ""
    },
    filterOperator: {
      type: String,
      default: ""
    },
    filterValue: {
      type: String,
      default: ""
    },
    flexRow: {
      type: Boolean,
      default: false
    },
    noPaginate: {
      type:Boolean,
      default: false
    },
    galleryID: {
      type: String,
      default: "",
    }

  },

  data () {
    return {
      selectedRemix: 0,
      blnGallery: (this.appGallery.id)? true : false, // does the injected Gallery Object exist?
    }
  },

  created () {
    this.qLimit = this.limit;
    this.qFilterProperty = this.filterProperty;
    this.qFilterOperator = this.filterOperator;
    this.qFilterValue = this.filterValue;
    this.qGalleryID = this.galleryID;
    this.bindData();

  },


  methods: {
    showDeleteModal: function(deleteRemixID){
      console.log(deleteRemixID);
      this.selectedRemix = deleteRemixID;
      this.$root.$emit('bv::show::modal', 'modal-delete');
    }


  },

  watch: {
    filterValue: function(){
      //console.log('filter prop changed')
      this.qLimit = this.limit;
      this.qFilterProperty = this.filterProperty;
      this.qFilterOperator = this.filterOperator;
      this.qFilterValue = this.filterValue;
      this.currentPage = 0;
      this.$unbind('remixes')
      this.bindData();
    },
    limit: function(){
      //console.log('limit prop changed')
      this.qLimit = this.limit;
      this.qFilterProperty = this.filterProperty;
      this.qFilterOperator = this.filterOperator;
      this.qFilterValue = this.filterValue;
      this.currentPage = 0;
      this.$unbind('remixes')
      this.bindData();
    }
  },

  computed: {
    noFirstRemix: function(){
      return (this.currentPage*this.limit) + 1;
    },
    noLastRemix: function(){
      return (this.currentPage*this.limit) + this.remixes.length;
    },
    isGalleryPrompt: function(){
      if (this.blnGallery && (this.selectedRemix != 0) && (typeof this.selectedRemix !== 'undefined')){
        return (this.remixes[this.selectedRemix].id == this.appGallery.remixPromptID)
      }else{
        return false
      }
      
      
    }
  }



}



</script>

<style>

.remix-grid {
  display: grid;
  box-sizing: border-box;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr)); */
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  align-items: center;

}

.remix-item:hover {
  cursor: pointer;
}

/* .item-landscape {
  grid-column: span 2;
}

.item-portrait {
  grid-row: span 2;
} */

.remix-popover {
    filter: drop-shadow(5px 5px 20px #000)
}

</style>
