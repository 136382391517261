<template>

    <b-tabs active-tab-class="tab-main-on" title-link-class="tab-nav-off">
      <b-tab disabled><template v-slot:title ><span style="font-size:1.5rem">Remix:</span></template></b-tab>
      <slot>Empty</slot>
    </b-tabs>

</template>

<script>

/*
TODO


*/

export default {
  name: 'toolpanelOuter',
  components: {

  },
  props: {

  },
  data () {
    return {
    }
  },
  methods: {

  },
  computed: {

  },
  created: function(){

  },
  mounted: function(){

  },
}

</script>

<style>

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	color: #ffffff;
	background-color: #000000;
	border-color: #333333;
  border-bottom: 0px solid #333333;
}

.nav-tabs {
	border-bottom: 0px solid #333333;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
	border-color: #333333;
}

.nav-tabs .nav-link.disabled {
	color: #FFFFFF;
}

.tab-nav-on {
  background-color: #000000;
  color: #ffffff;
  border-color:#333333;
}

.tab-nav-off {
  background-color: #00000033;

  border-color:#333333;
}

.tab-main-on {
  background-color: #000000;
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  color: #ffffff;
}

.tab-main-off {
  background-color: #ff0000;
}

</style>
