<template>
<div class="remixer-background">
  <div v-if="!blnGalleryOnly">
    <app-header></app-header>

    <div class="container-fluid" >
      <div class="container pt-2 pb-3" >
        <div class="row align-items-top" style="margin-top:0px;">

          <!-- REMIX -->
          <div class="col-xs col-lg mt-4 mb-4" style="text-align:left">

            <b-card
              class="p-2"
              bg-variant="dark"
              border-variant="transparent"
              no-body
            >
              <slot name="panel2"></slot>
            </b-card>

            <div class="mt-3"><slot>Main App - nothing to show</slot></div>

            <div class="row m-2">
              <!-- CC LIcence -->
              <div class="col-xs-12 col-md-5 mb-3 mb-md-0"><cc-licence licence="by-sa" :name="this.appSettings.title" :remix="((appRemix != null) && ('creator' in appRemix)) ? appRemix.creator.name : ''"></cc-licence></div>
              <div class="col" v-show="remixComment != ''">

                <!-- the comment is always editable -->
                <p class="small text-muted mb-0">Remixer's notes:</p>
                <p>{{remixComment}}</p>
                
              </div>
            </div>
          </div>

          <!-- SIDEBAR -->
          <div class="col-xs col-lg-4 mt-4 mb-4 d-flex flex-column-reverse flex-lg-column">

            <b-card
              class="mb-2"
              bg-variant="dark"
              border-variant="transparent">

              <h2>{{this.appGallery.title}} <span style="font-size:0.5em" class="badge badge-info" v-if="this.appSettings.status=='dev'">BETA</span></h2>
              <p>
              <b-badge class="mb-1" variant="success"><span style="font-size:1.2em;font-weight:600">GALLERY</span></b-badge>
              <b-badge class="mr-1 mb-1" variant="info"><span style="font-size:1.2em;font-weight:600">Curator:</span> <span style="font-size:1.2em;font-weight:600">{{appGallery.creator.name}}</span></b-badge><br/>
              <b-badge class="mr-1" v-for="(hashtag , idx) in appSettings.socialHashtags.split(',')" :key="idx" variant="info"><span style="font-size:1.2em;font-weight:600">#{{hashtag}}</span></b-badge>
              </p>
              <p v-html="this.appGallery.description" v-linkified></p>

              <!-- Description SLOT-->
              <slot name="panel1">panel 1 - nothing to show</slot>

            </b-card>
            
            <save-and-share :filenameBase="this.appSettings.title" v-bind:comment.sync="remixComment"></save-and-share>

          </div>
        </div>
      </div>

    </div>
  
  
    <div class="" style="background-color: #111111;">
      <gallery-divider id="middle" color="#de4500" style="transform: translateY(-50%) scale(1,1);transform-origin: top;max-height:40px; position: relative; z-index:1"><span style="font-size:120%;font-weight:800;line-height:1.2em">REMIX&nbsp;GALLERY</span></gallery-divider>
    </div>

  </div>



  <div class="container-fluid" style="background-color: #111111;">

    

    <!-- OTHER REMIXES -->
    <div class="row" style="padding-bottom:70px;background-color: #111111;" >
      <div class="col">

        <!-- minimal header if Gallery Only -->
        <div v-if="blnGalleryOnly" class="mt-2" style="font-weight:800;">
          {{this.appGallery.title}}
        </div>

        <!-- pagination controls -->
        <div class="d-flex align-items-center mt-2 mb-2" v-if="!blnEmbed">
          <div class="mr-2" style="font-weight:600">
            Click on a remix to see more details. 
            <b-form-checkbox v-model="blnGalleryOnly" name="check-button" switch>
              Display Mode
            </b-form-checkbox>
          </div>
          
          <div class="ml-auto" ><b-form-select v-model="remixesPerPage" :options="remixesPerPageOptions" style="min-width:75px" class="primary"></b-form-select></div>
          <div class="ml-2">per&nbsp;page</div>
        </div>

        <remix-grid :galleryID="appGallery.id" :limit="remixesPerPage"></remix-grid>

      </div>
    </div>

  </div>


  <!-- Desktop footer -->
  <app-footer class="" :compact="blnGalleryOnly"></app-footer>




</div>
</template>




<script>

/*
TODO


*/
import GalleryDivider from '@/components/layout/galleryDivider.vue'

export default {
  name: 'galleryMinLayout',
  components: {
    GalleryDivider
  },
  inject: ['appThings','appRemix','appSettings','appGallery'],
  props: {

  },
  data () {
    return {
      blnShowModalSettings: true,
      blnEmbed: false,
      blnGalleryOnly: false,
      remixView: 'list',
      remixViewOptions: [
        { text: 'List', value: 'list' },
        { text: 'Graph', value: 'graph' },
      ],
      remixComment:(this.appRemix.comment) ? this.appRemix.comment : "",
      remixesPerPageOptions : [12,24,48,96],
      remixesPerPage : 24,
    }
  },

  methods: {
    showSaveShareModal () {
      this.$root.$emit('showSaveAndShareModal')
    }

  },

  created() {
    if (this.$route.query.galleryonly){ this.blnGalleryOnly = true;}
    //if (this.$route.query.remixes){ this.remixesPerPage = this.$route.query.remixes;}
    if (this.$route.query.embed){ this.blnEmbed = true;}
  }

}

</script>

<style lang="css" scoped>

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 999;
    color: #fff;
}

.icon {
  /*width:100%;*/
}
.icon path {
  fill:#fff;
}

@media screen and (max-width: 768px) {
    .display-4 {
        font-size:2.5rem;
    }
}

/* BrysoLetterGothicStd */
@font-face {
  font-family: BrysoRemixer-Block;
  src: url('/BrysoRemixer-Block.woff');
}

.remixer {
  font-family: BrysoRemixer-Block;
}





</style>
