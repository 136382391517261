<template>
  <div class="mt-1 mb-1">
    <div class="small pl-1 ">{{title}} <font-awesome-icon :id="popoverID" v-if=" info != '' "  :icon="['fas', 'info-circle']" />
    
    
    
    <b-popover v-if=" info != '' " :target="popoverID" triggers="click blur" placement="top">
      <span class="text-dark">{{info}}</span>
    </b-popover>
    
    
    </div>
    <div class="d-flex align-items-center rounded inner-panel" :style="this.panelStyle">
      <slot>Empty</slot>
    </div>
  </div>
</template>

<script>

/*
TODO
text-dark

*/

export default {
  name: 'toolpanel',
  components: {

  },
  props: {
    title: {
      default: "",
      type: String
    },
    info: {
      default: "",
      type: String
    },
    panelStyle: {
      default: () => {},
      type: Object
    }

  },
  data () {
    return {
      popoverID: this.title.replace(/ +/g, '-').toLowerCase()
    }
  },
  methods: {

  },
  computed: {

  },
  created: function(){

  },
  mounted: function(){

  }
}

</script>

<style>

</style>
