<template>
  <div class="SwatchPicker d-flex align-items-center">
      <div class="label p-1"  v-if="onoff"><input type="checkbox"></div>
      <div class="label p-1"  v-if="label != ''">{{label}}:</div>

      <client-only>
        <popper trigger="click" :options="{placement: 'auto'}" :disabled="disabled">
          <div class="popper m-1" style="z-index: 1050;">
            <sketch-picker :presetColors="palette"   :value="updatedColor" @input="updateParent"></sketch-picker>
          </div>

          <!-- <div slot="reference" :style="swatchStyle" class="swatch" title="Pick a Colour">f</div> -->
          <!-- <div title="Pick a Colour" aria-label="Current color is rgba(222,69,0,0.47)" class="vc-sketch-active-color" style="background: rgba(222, 69, 0, 0.47);"></div>
           -->
          <div slot="reference" class="swatch">
            <div class="vt-checkerboard" style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC&quot;);"></div>
            <div :aria-label="'Current color is ' + updatedColor" class="vt-sketch-active-color" :style="{'background': updatedColor}"></div>
          </div>

        </popper>
      </client-only>


  </div>
</template>


<script>

//import Compact from 'vue-color/src/components/Compact.vue';
//import Chrome from 'vue-color/src/components/Chrome.vue';
//import Sketch from 'vue-color/src/components/Sketch.vue';
import { Sketch } from 'vue-color'
import { Chrome } from 'vue-color'
import { Compact } from 'vue-color'
export default {
  name: 'SwatchPicker',
  components: {
    swatchPicker: Compact,
    rgbPicker: Chrome,
    sketchPicker: Sketch,
  },
  props: {
    label: {
      type: String
    },
    onoff: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    palette: {
      default: function () {return ['#ffffff00', '#2f495a', '#4E8085', '#16a8e2', '#EE002B', '#de4500', '#F8B000',  '#6CB900', '#FFBFD0',   '#000000', '#3F484A', '#C0D7E1', '#E3E3E2', '#ffffff']}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      updatedColor:"rgba(0,0,0,0)",
    }
  },
  methods: {
    updateParent: function (value) {
      // Emit the number value through the input event
      // this.$emit('update',value.hex8);
      let rgbaColor = 'rgba(' + value.rgba.r + ',' + value.rgba.g + ',' + value.rgba.b + ',' + value.rgba.a + ')';
      //console.log(rgbaColor);
      this.updatedColor = rgbaColor;
      this.$emit('update:color', rgbaColor);
    },
    documentClick(e) {

			var el = this.$refs.colorpicker,
				target = e.target;
        //console.log("colorpicker", el)
        //console.log("target", target)
        //console.log("e", e)
			if(el !== target && !el.contains(target)) {
        //console.log('satisfied that its an outside click/touch')
				this.hidePicker()
			}
		},
  },
  computed: {
    // swatchStyle: function(){
    //   if (this.color == ""){
    //     return 'background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzEnLz4KPC9zdmc+Cg==")'
    //   }else{
    //     return 'background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzEnLz4KPC9zdmc+Cg=="); background-color: ' + this.updatedColor
    //   }
    // }
  },
  mounted: function(){
    this.updatedColor = this.color;
  },
  watch: {
    'color': function (val) {
      //
      //console.log('hello')
      // tinycolour(hColor).mix()
      //console.log('hello')
      this.updatedColor = this.color;
    },
  }

}
</script>

<style scoped>
.swatch {
  width:22px;
  height: 22px;
  border-radius:3px;
  border:1px solid #fff;
  cursor: pointer;
  position: relative;
}

.vt-checkerboard {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-size: contain;
}
.vt-sketch-active-color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 15%), inset 0 0 4px rgb(0 0 0 / 25%);
    z-index: 2;
}

</style>
