<template>
  <b-tab title-link-class="tab-nav-off">
    <template v-slot:title v-if="icon==='colour'"><font-awesome-icon class="" style="font-size:2em;" :icon="['fas', 'eye-dropper']" /></template>
    <template v-slot:title v-else-if="icon==='text'"><font-awesome-icon class="" style="font-size:2em;" :icon="['fas', 'font']" /></template>
    <template v-slot:title v-else-if="icon==='image'"><font-awesome-icon class="" style="font-size:2em;" :icon="['fas', 'camera']" /></template>
    <slot>Empty</slot>
  </b-tab>
</template>

<script>

/*
TODO


*/

export default {
  name: 'toolPanelTab',
  components: {

  },
  props: {
    icon: {
      default: "colour",
      type: String
    },
  },
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {

  },
  created: function(){

  },
  mounted: function(){

  },
}

</script>

<style>



</style>
