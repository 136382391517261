import Vue from 'vue'

import '@/scss/custom_bootstrap.scss';

const apps = {
  // these are the list of apps and their defaults
  ometer: { app: 'ometer', svgWidth: 1920, svgHeight: 1080, thumbWidth: 1000, rowSpan: 1, colSpan: 2, highRes: true },
  playingcard: { app: 'playingcard', svgWidth: 1500, svgHeight: 2000, thumbWidth: 1000, rowSpan: 2, colSpan: 1, highRes: true },
  heroprofile: { app: 'heroprofile', svgWidth: 1281, svgHeight: 834, thumbWidth: 1000, rowSpan: 1, colSpan: 3, highRes: true },
  supersimplebadge: { app: 'supersimplebadge', svgWidth: 1000, svgHeight: 1000, thumbWidth: 1000, rowSpan: 2, colSpan: 2, highRes: true },
  hattip: { app: 'hattip', svgWidth: 1000, svgHeight: 1000, thumbWidth: 1000, rowSpan: 2, colSpan: 2, highRes: true },
  ntutorr: { app: 'ntutorr', svgWidth: 1000, svgHeight: 1000, thumbWidth: 1000, rowSpan: 2, colSpan: 2, highRes: true },
  cartoon: { app: 'cartoon', svgWidth: 1920, svgHeight: 1080, thumbWidth: 1000, rowSpan: 1, colSpan: 2, highRes: true },
  morpho: { app: 'morpho', svgWidth: 1500, svgHeight: 1500, thumbWidth: 1000, rowSpan: 2, colSpan: 2, highRes: true },
  idcard: { app: 'idcard', svgWidth: 1500, svgHeight: 1000, thumbWidth: 1000, rowSpan: 1, colSpan: 2, highRes: true },
  postcard: { app: 'postcard', svgWidth: 1500, svgHeight: 1000, thumbWidth: 1000, rowSpan: 1, colSpan: 2, highRes: true },
  postcard2: { app: 'postcard2', svgWidth: 1500, svgHeight: 1000, thumbWidth: 1000, rowSpan: 1, colSpan: 3, highRes: true },
  fieldnotes: { app: 'fieldnotes', svgWidth: 1500, svgHeight: 1000, thumbWidth: 1000, rowSpan: 1, colSpan: 2, highRes: true },
  altbadgemaker: { app: 'altbadgemaker', svgWidth: 1000, svgHeight: 1000, thumbWidth: 1000, rowSpan: 1, colSpan: 1, highRes: true },
  amplifyfebadgemaker: { app: 'amplifyfebadgemaker', svgWidth: 750, svgHeight: 750, thumbWidth: 750, rowSpan: 1, colSpan: 1, highRes: false },
  penguins: { app: 'penguins', svgWidth: 1500, svgHeight: 843, thumbWidth: 1000, rowSpan: 1, colSpan: 2, highRes: true },
  
  mediapass: { app: 'mediapass', svgWidth: 1150, svgHeight: 1500, thumbWidth: 1000, rowSpan: 2, colSpan: 2, highRes: true },
  hexquilt: { app: 'hexquilt', svgWidth: 433, svgHeight: 500, thumbWidth: 433, rowSpan: 1, colSpan: 1, highRes: false },
  maptile: { app: 'maptile', svgWidth: 555, svgHeight: 555, thumbWidth: 555, rowSpan: 1, colSpan: 1, highRes: false},

  stripedtext: { app: 'stripedtext', svgWidth: 1500, svgHeight: 500, thumbWidth: 1000, rowSpan: 1, colSpan: 3, highRes: true},
  xrayspecs: { app: 'xrayspecs', svgWidth: 1000, svgHeight: 1348, thumbWidth: 500, rowSpan: 1, colSpan: 1, highRes: false},
  openbus: { app: 'openbus', svgWidth: 1920, svgHeight: 1080, thumbWidth: 500, rowSpan: 1, colSpan: 2, highRes: false},
  flipbook: { app: 'flipbook', svgWidth: 1500, svgHeight: 1100, thumbWidth: 750, rowSpan: 1, colSpan: 2, highRes: false},
  barcode: { app: 'barcode', svgWidth: 1600, svgHeight: 1600, thumbWidth: 500, rowSpan: 1, colSpan: 1, highRes: false},
  storyline: { app: 'storyline', svgWidth: 1920, svgHeight: 1080, thumbWidth: 750, rowSpan: 1, colSpan: 2, highRes: false},
  stamp: { app: 'stamp', svgWidth: 1550, svgHeight: 1350, thumbWidth: 500, rowSpan: 1, colSpan: 1, highRes: false},
  pimpmydeck: { app: 'pimpmydeck', svgWidth: 500, svgHeight: 1620, thumbWidth: 250, rowSpan: 3, colSpan: 1, highRes: false},
  vhs: { app: 'vhs', svgWidth: 2056, svgHeight: 457, thumbWidth: 750, rowSpan: 1, colSpan: 3, highRes: false},
  voronoi: { app: 'voronoi', svgWidth: 1024, svgHeight: 1024, thumbWidth: 512, rowSpan: 1, colSpan: 1, highRes: false},
  pixelpic: { app: 'pixelpic', svgWidth: 1024, svgHeight: 1024, thumbWidth: 512, rowSpan: 1, colSpan: 1, highRes: false},
  pixel8: { app: 'pixel8', svgWidth: 1024, svgHeight: 1024, thumbWidth: 512, rowSpan: 1, colSpan: 1, highRes: false},
  puffin: { app: 'puffin', svgWidth: 1920, svgHeight: 1080, thumbWidth: 500, rowSpan: 1, colSpan: 2, highRes: false},
  bloom: { app: 'bloom', svgWidth: 2338.8, svgHeight: 2338.8, thumbWidth: 500, rowSpan: 1, colSpan: 1, highRes: false},
  oer: { app: 'oer', svgWidth: 2034, svgHeight: 1144, thumbWidth: 500, rowSpan: 1, colSpan: 2, highRes: false},
  world: { app: 'world', svgWidth: 960, svgHeight: 760, thumbWidth: 500, rowSpan: 2, colSpan: 3, highRes: true},
  elemental: { app: 'elemental', svgWidth: 1230, svgHeight: 1230, thumbWidth: 500, rowSpan: 1, colSpan: 1, highRes: false},
  binary: { app: 'binary', svgWidth: 1920, svgHeight: 694, thumbWidth: 500, rowSpan: 1, colSpan: 2, highRes: false},
  toptrump: { app: 'toptrump', svgWidth: 636.6, svgHeight: 993.1, thumbWidth: 500, rowSpan: 2, colSpan: 1, highRes: false},
}

// define global variables
// this.$globalvars.
export default function ({ app }, inject) {
  inject('globalvars', {
    modelCollection: (process.env.NODE_ENV === 'production') ? 'models_live' : 'models_dev',
    remixCollection: (process.env.NODE_ENV === 'production') ? 'remixes_live' : 'remixes_dev',
    galleryCollection: (process.env.NODE_ENV === 'production') ? 'galleries_live' : 'galleries_dev',
    thumbCollection: (process.env.NODE_ENV === 'production') ? 'thumbnails_live' : 'thumbnails_dev',
    rootStorageURL: 'https://firebasestorage.googleapis.com/v0/b/remixer-db.appspot.com/o/',
    templates: {
      'ntutorr': {
        ...apps.ntutorr,
        title: 'N-TUTORR badge',
        description: 'Remix the N-TUTORR micro credential.',
        image: process.env.baseUrl + '/thumbs/N-TUTORR.png',
        patronage: true,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: false,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'hattip': {
        ...apps.hattip,
        title: 'Hat tip',
        description: 'Give someone a little recognition by tipping your hat and sending them the remix.',
        image: process.env.baseUrl + '/thumbs/hat-tip.png',
        patronage: true,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'fieldnotes': {
        ...apps.fieldnotes,
        title: 'Field Notes',
        description: 'A thought, a photo and a location.',
        image: process.env.baseUrl + '/thumbs/fieldnotes.png',
        patronage: true,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {strapline: '',},
      },

      'supersimplebadge': {
        ...apps.supersimplebadge,
        title: 'Super-simple badge',
        description: 'Is awarding a badge ever simple? You tell me...',
        image: process.env.baseUrl + '/thumbs/supersimplebadge.png',
        patronage: false,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'heroprofile': {
        ...apps.heroprofile,
        title: 'Supervillain Profile',
        description: 'The classifed files of the supers...',
        image: process.env.baseUrl + '/thumbs/supervillain.png',
        patronage: true,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: false,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },

      'DCU-postcard': {
        ...apps.postcard2,
        title: 'Postcard to my former self',
        description: 'Send a message to a previous version of you.',
        image: process.env.baseUrl + '/thumbs/postcard2.png',
        patronage: true,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: false,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },

      'cartoon': {
        ...apps.cartoon,
        title: 'Cartoon',
        description: 'Free speech for cartoons!',
        image: process.env.baseUrl + '/thumbs/benny.png',
        patronage: true,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine,cartoon',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },

      'playingcard': {
        ...apps.playingcard,
        title: 'Playing Card',
        description: 'Aces are high...',
        image: process.env.baseUrl + '/thumbs/PlayingCard.png',
        patronage: false,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },

      'ometer': {
        ...apps.ometer,
        title: 'O\'meter',
        description: 'Are your levels critical?',
        image: process.env.baseUrl + '/thumbs/ometer.png',
        patronage: false,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'morpho': {
        ...apps.morpho,
        title: 'Morpho',
        description: 'Have a tinker with this little beauty...',
        image: process.env.baseUrl + '/thumbs/morpho.png',
        patronage: false,
        hashtags: ['new'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

     

      'scribble': {
        ...apps.stripedtext,
        title: 'Scribble Stitch',
        description: 'How\'s your stitching?',
        image: process.env.baseUrl + '/thumbs/DS106.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,ds106',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'ls-fieldnotes': {
        ...apps.fieldnotes,
        title: 'LiveSciences3 Summer Campus Field Notes',
        description: 'A thought, a photo and a location.',
        image: process.env.baseUrl + '/thumbs/fieldnotes.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: false,
        gallery: false,
        downloads: ['PNG'],
        options: {strapline: 'LIVESCIENCES3 SUMMER CAMPUS',},
      },

      

      'cgc-idcard': {
        ...apps.idcard,
        title: 'Let\'s Get Phygital',
        description: 'Create your own Let\'s Get Phygital conference badge',
        image: process.env.baseUrl + '/thumbs/idcard.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'phygital,RemixerMachine',
        status: 'live',
        public: false,
        gallery: false,
        downloads: ['PNG'],
        options: {},
      },

      

      'OEG-postcard': {
        ...apps.postcard,
        title: 'OEGlobal Postcard',
        description: 'Send OEGlobal an Open postcard.',
        image: process.env.baseUrl + '/thumbs/PostcardOEG.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,OEGlobal20',
        status: 'live',
        public: true,
        gallery: false,
        downloads: ['PNG'],
        options: {
          postmark: 'OEG',
          photo: require("@/assets/OEG-postcard/clouds.jpg"),
          stamp: require('@/assets/OEG-postcard/cat.jpg'),
          strapline: 'OEGLOBAL CONFERENCE',
          toline: 'OE GLOBAL \n CONFERENCE',
          fromline: 'All of us at \n conference@oeglobal.org',
          location: 'TAIPEI - DELFT - TORONTO',
          message: 'I wandered lonely as a cloud - and then bumped into a bunch of other clouds!',
        },
      },

      'GOGN-postcard': {
        ...apps.postcard,
        title: 'GO-GN Postcard',
        description: 'Send GO-GN 10th Anniversary greetings.',
        image: process.env.baseUrl + '/thumbs/GO-GN Postcard Thumb.png',
        patronage: true,
        hashtags: [],
        socialHashtags: 'RemixerMachine,GOGN',
        status: 'live',
        public: false,
        gallery: true,
        downloads: ['PNG'],
        options: {
          postmark: 'GOGN',
          photo: require("@/assets/GOGN-postcard/pensive-penguin.png"),
          stamp: require("@/assets/GOGN-postcard/gogn stamp.png"),
          strapline: 'GO-GN 10th Anniversary',
          toline: 'GO-GN Community',
          fromline: 'Me',
          location: 'EDMONTON',
          message: 'Has it been ten years already?',
        },
      },

      'OER21-mediapass': {
        ...apps.mediapass,
        title: 'OERxDomains21 Media Pass',
        description: 'Have you got yours?',
        image: process.env.baseUrl + '/thumbs/mediapass.png',
        patronage: true,
        hashtags: ['stickers'],
        socialHashtags: 'RemixerMachine,OERxDomains21',
        status: 'live',
        public: true,
        gallery: false,
        downloads: ['PNG'],
        options: {
          logo: 'OERDomains.svg',
        },
      },

      'altbadgemaker': {
        ...apps.altbadgemaker,
        title: 'ALT Badge Press',
        description: 'Have you got yours?',
        image: process.env.baseUrl + '/thumbs/altbadgemaker.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,BadgePress',
        status: 'live',
        public: false,
        gallery: false,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'amplifyfebadgemaker': {
        ...apps.amplifyfebadgemaker,
        title: 'AmplifyFE Badge Press',
        description: 'Have you got yours?',
        image: process.env.baseUrl + '/thumbs/amplifyfebadgemaker.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,BadgePress',
        status: 'live',
        public: false,
        gallery: false,
        downloads: ['PNG','SVG'],
        options: {},
      },
      

      'postcard': {
        ...apps.postcard,
        title: 'Postcard',
        description: 'Send me an Open postcard.',
        image: process.env.baseUrl + '/thumbs/PostcardOEG.png',
        patronage: false,
        hashtags: [],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: false,
        gallery: true,
        downloads: ['PNG'],
        options: {
          postmark: 'Remixer',
          photo: require("@/assets/OEG-postcard/clouds.jpg"),
          stamp: require('@/assets/OEG-postcard/cat.jpg'),
          strapline: '',
          toline: 'TOP CAT \n (YOU KNOW IT!)',
          fromline: 'FLUFF T. BUCKET',
          location: 'OPEN MAIL',
          message: 'Saying hello from the other side of the internet!',
        },
      },
    
      'AmplifyFE-map': {
        ...apps.maptile,
        title: '#AmplifyFE Community Map',
        description: 'Add your hashtag to the #AmplifyFE community map.',
        image: process.env.baseUrl + '/thumbs/amplifyFE-map.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,AmplifyFE',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      
      
      'hexquilt': {
        ...apps.hexquilt,
        title: "Digital Quilt",
        description: 'Add your hex patch to the community quilt.',
        image: process.env.baseUrl + '/thumbs/hexquilt.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,oer20',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },
    
      'xrayspecs': {
        ...apps.xrayspecs,
        title: "X-Ray Specs",
        description: 'Careful, it\'s hypnotic...',
        image: process.env.baseUrl + '/thumbs/xrayspecs.jpg',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,25YearsofEdTech',
        status: 'live',
        public: true,
        gallery: false,
        downloads: ['PNG','SVG'],
        options: {},
      },
    
      'openbus': {
        ...apps.openbus,
        title: "Openbus",
        description: "You ain't said it, 'til you said it with a bus...",
        image: process.env.baseUrl + '/thumbs/openbus.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'flipbook': {
        ...apps.flipbook,
        title: "Remix 'n' Match",
        description: 'A remixable twist on the hilarious mix \'n\' match game.',
        image: process.env.baseUrl + '/thumbs/flipbook.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },

      'penguins': {
        ...apps.penguins,
        title: 'Open your work',
        description: 'With a little help from some GO-GN penguins',
        image: process.env.baseUrl + '/thumbs/Openyourwork.png',
        patronage: true,
        hashtags: ['tools'],
        socialHashtags: 'RemixerMachine,GO_GN',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'barcode': {
        ...apps.barcode,
        title: 'Waterfall Barcode',
        description: 'A barcode that flows.',
        image: process.env.baseUrl + '/thumbs/waterfall.png',
        patronage: false,
        hashtags: ['tools'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: false,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },
    
      'storyline': {
        ...apps.storyline,
        title: 'Storyline',
        description: 'Tell a polaroid story.',
        image: process.env.baseUrl + '/thumbs/storyline.png',
        patronage: false,
        hashtags: ['tools'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'ccstamp': {
        ...apps.stamp,
        title: 'Nice to CC you',
        description: 'Make your own CC postage stamp.',
        image: process.env.baseUrl + '/thumbs/ccstamp.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'pimpmydeck': {
        ...apps.pimpmydeck,
        title: 'Soup up my deck',
        description: 'Old Skool or Back to the Future. You choose...',
        image: process.env.baseUrl + '/thumbs/domains19.png',
        patronage: true,
        hashtags: ['stickers'],
        socialHashtags: 'RemixerMachine,Domains19',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'vhs': {
        ...apps.vhs,
        title: 'Reclaim your VHS',
        description: 'Tape it and watch it later.',
        image: process.env.baseUrl + '/thumbs/vhs.png',
        patronage: true,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,Domains19,DoOO',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'voronoi': {
        ...apps.voronoi,
        title: 'Voronoi',
        description: 'Triangles, Polygons and Circles, oh my!',
        image: process.env.baseUrl + '/thumbs/voronoi.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'pixelpic': {
        ...apps.pixelpic,
        title: 'Pixel Pic',
        description: 'Create your own pixels, then pixelate!',
        image: process.env.baseUrl + '/thumbs/pixel-pic.jpg',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

    
      'pixel8': {
        ...apps.pixel8,
        title: 'Pixel-8',
        description: 'Pixelate your images.',
        image: process.env.baseUrl + '/thumbs/pixel8.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },
    
      'puffin': {
        ...apps.puffin,
        title: 'Puffin',
        description: 'Plastic pollution is killing me.',
        image: process.env.baseUrl + '/thumbs/puffin.jpg',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,breakfreefromplastic',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'bloom': {
        ...apps.bloom,
        title: 'Badge Bloom',
        description: 'Use this simple tool to work through the design of your own badge.',
        image: process.env.baseUrl + '/thumbs/bloom.png',
        patronage: false,
        hashtags: ['tools'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },
    
      'oer18': {
        ...apps.oer,
        title: 'OER',
        description: 'Tzzz. Plug in. Switch on to OER. Kraaaaang...',
        image: process.env.baseUrl + '/thumbs/oer.png',
        patronage: false,
        hashtags: ['stickers'],
        socialHashtags: 'RemixerMachine,oer18',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },
      'world': {
        ...apps.world,
        title: 'Project World',
        description: 'Remix the world.',
        image: process.env.baseUrl + '/thumbs/project_world.png',
        patronage: false,
        hashtags: ['tools'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },
      'elemental': {
        ...apps.elemental,
        title: 'Elemental',
        description: 'Craft your own periodic table. It\'s alchemy, dude...',
        image: process.env.baseUrl + '/thumbs/elemental.png',
        patronage: false,
        hashtags: ['tools'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },
    
      'binary': {
        ...apps.binary,
        title: 'Binary',
        description: 'Yes? No? A little bit?',
        image: process.env.baseUrl + '/thumbs/binary.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG','SVG'],
        options: {},
      },

      'toptrump': {
        ...apps.toptrump,
        title: 'Trading Card',
        description: 'There\'s nothing like a Trading Card to elevate something or someone to the leagues of greatness.',
        image: process.env.baseUrl + '/thumbs/toptrumpPF.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine',
        status: 'live',
        public: true,
        gallery: true,
        downloads: ['PNG'],
        options: {},
      },

      // DEAD - keep these for now until the remix data has been deleted.

      'SocMedHE': {
        title: 'Inclusive Revolution',
        description: 'What\'s on your placard?',
        image: process.env.baseUrl + '/thumbs/inclusive_revolution.png',
        patronage: false,
        hashtags: ['stickers'],
        socialHashtags: 'RemixerMachine',
        status: 'dead',
        public: false,
        gallery: false,
        downloads: ['PNG'],
        options: {},
        svgWidth: 1500,
        svgHeight: 1500,
        thumbWidth: 500,
        rowSpan: 1,
        colSpan: 1,
      },

      'cesi': {
        title: "CESI 2020",
        description: 'Remix the CESI 2020 sticker.',
        image: process.env.baseUrl + '/thumbs/cesi.png',
        patronage: false,
        hashtags: ['stickers'],
        socialHashtags: 'RemixerMachine,cesi2020',
        status: 'dead',
        public: false,
        gallery: false,
        downloads: ['PNG','SVG'],
        options: {},
        svgWidth: 1045 ,
        svgHeight: 1057,
        thumbWidth: 500,
        rowSpan: 1,
        colSpan: 1,
      },

      'alt25': {
        title: 'ALT @25',
        description: 'Send someone a visual postcard for ALT\'s 25th Birthday.',
        image: process.env.baseUrl + '/thumbs/alt25.png',
        patronage: false,
        hashtags: ['artwork'],
        socialHashtags: 'RemixerMachine,altc',
        status: 'dead',
        public: false,
        gallery: false,
        downloads: ['PNG'],
        options: {},
        svgWidth: 2400,
        svgHeight: 1606,
        thumbWidth: 750,
        rowSpan: 1,
        colSpan: 2,
      },

      'bloggersInk': {
        title: 'Bloggers\' Ink',
        description: 'Are you one of them?',
        image: process.env.baseUrl + '/thumbs/bloggers_ink.png',
        patronage: false,
        hashtags: ['stickers'],
        socialHashtags: 'RemixerMachine',
        status: 'dead',
        public: false,
        gallery: false,
        downloads: ['PNG'],
        options: {},
        svgWidth: 1505,
        svgHeight: 1613,
        thumbWidth: 500,
        rowSpan: 1,
        colSpan: 1,
      },

      'oer19': {
        title: 'OER19',
        description: 'Recentre, refocus, realign...',
        image: process.env.baseUrl + '/thumbs/oer19.png',
        patronage: false,
        hashtags: ['stickers'],
        socialHashtags: 'RemixerMachine,oer19',
        status: 'dead',
        public: false,
        gallery: false,
        downloads: ['PNG','SVG'],
        options: {},
        svgWidth: 1920,
        svgHeight: 1080,
        thumbWidth: 500,
        rowSpan: 1,
        colSpan: 2,
      },
    },
    featured: [
      {
        title:'Cartoons',
        description:'Remix the speech bubbles in these fab cartoons:',
        remixes: [
          {
            remix:{
              "thumbnail":"https://firebasestorage.googleapis.com/v0/b/remixer-db.appspot.com/o/thumbnails_live%2FBz75K9anazawW1ApjwIw_500x500.webp?alt=media",
              "creator":{"name":"Bryan Mathers"},
              "templateID":"cartoon",
              "id":"Bz75K9anazawW1ApjwIw"
            },
            title:'On the Couch',
            comment:'So how did it make you feel?'
          },
          {
            remix:{
              "thumbnail":"https://firebasestorage.googleapis.com/v0/b/remixer-db.appspot.com/o/thumbnails_live%2FwcDUrQorEosuOeckjztM_500x500.webp?alt=media",
              "creator":{"name":"Bryan Mathers"},
              "templateID":"cartoon",
              "id":"wcDUrQorEosuOeckjztM"
            },
            title:'But Jeff...',
            comment:'Hat tip to Roy Lichtenstein for this one.'
          },
          {
            remix:{
              "thumbnail":"https://firebasestorage.googleapis.com/v0/b/remixer-db.appspot.com/o/thumbnails_live%2FfwI67hGWGiQ9NJ2EL5qK_500x500.webp?alt=media",
              "creator":{"name":"Bryan Mathers"},
              "templateID":"cartoon",
              "id":"fwI67hGWGiQ9NJ2EL5qK"
            },
            title:'My cat',
            comment:'Who can really know what it going on in the mind of a cat...'
          },
          {
            remix:{
              "thumbnail":"https://firebasestorage.googleapis.com/v0/b/remixer-db.appspot.com/o/thumbnails_live%2Fx1XHbHgOJY72cbEvl10B_500x500.webp?alt=media",
              "creator":{"name":"Bryan Mathers"},
              "templateID":"cartoon",
              "id":"x1XHbHgOJY72cbEvl10B"
            },
            title:'Venn insight',
            comment:'It\'s harder than it looks.'
          },
          {
            remix:{
              "thumbnail":"https://firebasestorage.googleapis.com/v0/b/remixer-db.appspot.com/o/thumbnails_live%2F9WJse7KbDhgqBGDg5WFb_500x500.webp?alt=media",
              "creator":{"name":"Bryan Mathers"},
              "templateID":"cartoon",
              "id":"9WJse7KbDhgqBGDg5WFb"
            },
            title:'Lego',
            comment:'Honey - have you seen my paaaaaants?'
          },
          
        ] 
      },
    ]
    
  })
}




// globally register components


import Panel from '@/components/layout/panel.vue'
import ToolPanel from '@/components/layout/toolPanel.vue'
import ToolPanelOuter from '@/components/layout/toolPanelOuter.vue'
import ToolPanelTab from '@/components/layout/toolPanelTab.vue'

import Popper from 'vue-popperjs'
import RemixText from '@/components/remix/RemixText.vue'

import RemixTextInSitu from '@/components/remix/RemixTextInSitu.vue'
import RemixResizeTextInSitu from '@/components/remix/RemixResizeTextInSitu.vue'
import RemixPath from '@/components/remix/RemixPath.vue'
import RemixBgImage from '@/components/remix/RemixBgImage.vue'
import RemixBgImageInSituControls from '@/components/remix/RemixBgImageInSituControls.vue'
import RemixBgImageInSituPlaceholder from '@/components/remix/RemixBgImageInSituPlaceholder.vue'
import SwatchPicker from '@/components/remix/SwatchPicker.vue'

import SaveAndShare from '@/components/SaveAndShare.vue'
import Smiley from '@/components/smiley.vue'
import CcLicence from '@/components/layout/ccLicence.vue'
//import BrysoFont from '@/components/remix/brysoFont.vue';
import VtWatermark from '@/components/remix/vtWatermark.vue';
import Slide from '@/components/layout/slide.vue';

import RemixGrid from '@/components/RemixGrid.vue';

import RemixSnapshots from '@/components/RemixSnapshots.vue';
import RemixSnapshotsVisual from '@/components/RemixSnapshotsVisual.vue';









Vue.component('panel', Panel)
Vue.component('toolPanel', ToolPanel)
Vue.component('toolPanelOuter', ToolPanelOuter)
Vue.component('toolPanelTab', ToolPanelTab)


Vue.component('popper', Popper)

Vue.component('remixText', RemixText)

Vue.component('remixTextInSitu', RemixTextInSitu)
Vue.component('remixResizeTextInSitu', RemixResizeTextInSitu)
Vue.component('remixPath', RemixPath)
Vue.component('remixBgImage', RemixBgImage)
Vue.component('remixBgImageInSituControls', RemixBgImageInSituControls)
Vue.component('remixBgImageInSituPlaceholder', RemixBgImageInSituPlaceholder)
Vue.component('swatchPicker', SwatchPicker)

Vue.component('saveAndShare', SaveAndShare)
Vue.component('smiley', Smiley)
Vue.component('ccLicence', CcLicence)
//Vue.component('brysoFont', BrysoFont)
Vue.component('vtWatermark', VtWatermark)
Vue.component('slide', Slide)

Vue.component('remixGrid', RemixGrid)

Vue.component('remixSnapshots', RemixSnapshots) // needs depricated?
Vue.component('remixSnapshotsVisual', RemixSnapshotsVisual) 


import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)





// -------- APPS ----------
for (const app in apps){
  //import app.app from '@/components/apps/' + app.app
  //Vue.component(app.app, app.app)
  Vue.component(app, 
    () => import('@/components/apps/' + app)
  )
};


// --------- LAYOUTS -------
import RemixMinLayout from '@/components/layout/remix_min.vue'
import EmbedMinLayout from '@/components/layout/embed_min.vue'
import GalleryMinLayout from '@/components/layout/gallery_min.vue'
import AppHeader from '@/components/layout/appHeader.vue'
import AppFooter from '@/components/layout/appFooter.vue'
import GalleryDivider from '@/components/layout/galleryDivider.vue'
import remixThumb from '@/components/layout/remixThumb.vue'
import HelpVideo from '@/components/layout/help-video.vue'
Vue.component('remix_min', RemixMinLayout)
Vue.component('embed_min', EmbedMinLayout)
Vue.component('gallery_min', GalleryMinLayout)
Vue.component('appFooter', AppFooter)
Vue.component('appHeader', AppHeader)
Vue.component('galleryDivider', GalleryDivider)
Vue.component('remixThumb', remixThumb)
Vue.component('helpVideo', HelpVideo)

